import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss'],
})
export class ConfirmationPopupComponent implements OnInit {

  @Input() heading: any;
  @Input() subHeading: any;
  @Input() imageUrl: any;

  constructor(public modalCtrl: PopoverController) { }

  ngOnInit() { }

  async continue() {
    await this.modalCtrl.dismiss(true);
  }
}
