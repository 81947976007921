import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RudderStack } from 'src/app/services';


@Component({
  selector: 'app-app-installer-mobile',
  templateUrl: './app-installer-mobile.component.html',
  styleUrls: ['./app-installer-mobile.component.scss'],
})
export class AppInstallerMobileComponent implements OnInit {

  @Output() closePopover = new EventEmitter<any>();

  constructor(private rudderStack:RudderStack) { }

  ngOnInit() {}


  close() {
    console.log('closing');
    this.closePopover.emit(true);
  }

  openLink(link) {
		this.rudderStack.log({ type: 'click_install_app' });
		window.open(link, '_blank');
	}
}
