import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-upload',
	templateUrl: './upload.component.html',
	styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
	@Input() public iconOnly = false;
	@Input() public name;
	public isLoading = false;
	@Input() public text = 'Upload';
	@Input() public loadingText = 'Uploading';
	@Input() public path;
	@Input() public acceptType = '.pdf, .doc, .docx';
	@Input() public classes;

	@Output() public success = new EventEmitter<any>();

	constructor(private http: HttpClient, private authService: AuthService) {}

	public ngOnInit(): void {
		// block
	}

	public handleFileInput(event) {
		const selectedFile = event.target.files[0] as File;
		this.isLoading = true;
		if (!!selectedFile) {
			if (selectedFile.size > 2097152) {
				this.authService.addToastMsg('Please update maximum file size of 2MB');
				this.isLoading = false;
				return false;
			}

			const formData: FormData = new FormData();
			formData.append('file', selectedFile);

			this.http
				.post<any>(`${environment.url.profile}/upload/${this.path}`, formData)
				.pipe(
					catchError(err => {
						this.isLoading = false;
						throw throwError(err);
					}),
				)
				.subscribe(({ url }) => {
					this.success.emit(url);
					this.isLoading = false;
				});
		}
	}
}
