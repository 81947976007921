import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter, first, map, tap } from 'rxjs/operators';
import { DashboardService } from 'src/app/pages/app/learning/service/dashboard.service';
import { ProfileService } from 'src/app/pages/app/profile/services/profile.service';
import { Location } from '@angular/common';

@Component({
	selector: 'app-not-started',
	templateUrl: './not-started.component.html',
	styleUrls: [
		'./not-started.component.scss',
		'../../pages/app/learning/cohort/computer-needed.scss',
	],
})
export class NotStartedComponent implements OnInit {
	@Input() startDate: any;
	@Input() isMobile: any;
	profile$: Observable<any> = this.profileService.loadProfileData();
	b2bFlag: any;
	companyInfo$: Observable<any>;
	tabMenu: any;
	router$: Observable<any> = this.router.events.pipe(
		filter(event => event instanceof NavigationEnd),
		tap((data: any) => {
			data = data.url.split('/');
			this.tabMenu = data[4];
		}),
	);
	constructor(
		private profileService: ProfileService,
		private dashboardService: DashboardService,
		private location: Location,
		private router: Router,
	) {}

	ngOnInit() {
		this.tabMenu = this.location.path().split('/');
		this.tabMenu = this.tabMenu[4];
		this.profileService.loadProfileInit();
		this.profileService.loadProfileApiStatus().subscribe();
		this.profileService
			.loadProfileData()
			.pipe(
				filter(({ isSkeleton }) => !isSkeleton),
				map(({ data }: any) => data),
				filter(({ id }) => !!id),
				map(({ b2b_flag }: any) => {
					if (b2b_flag) {
						this.b2bFlag = true;
					}
					return b2b_flag;
				}),
				first(),
			)
			.subscribe(shortCode => {
				// Company Info
				console.log('here2', shortCode);
				this.dashboardService.loadCompanyInfoInit(shortCode);
				this.dashboardService.loadCompanyInfoAPI(shortCode).subscribe();
				this.companyInfo$ = this.dashboardService.loadCompanyInfoData(
					shortCode,
				);
			});
	}
}
