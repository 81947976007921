import { Pipe, PipeTransform } from '@angular/core';

interface memberModel {
	selected: Boolean;
}

@Pipe({
	name: 'selectedMembers',
	pure: false,
})
export class selectedMembersPipe implements PipeTransform {
	public transform(allHeroes: memberModel[]): any {
		return allHeroes.filter(({ selected }) => selected);
	}
}
