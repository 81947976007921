import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController, ModalController } from '@ionic/angular';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isWithinInterval from 'date-fns/isWithinInterval';
import subDays from 'date-fns/subDays';
import subMinutes from 'date-fns/subMinutes';
import { first } from 'rxjs/operators';

import { AllAssignmentsService } from 'src/app/pages/app/learning/cohort/assignments/services/all-assignments.service';
import { LearningService } from 'src/app/pages/app/learning/service/learning.service';
import { RudderStack } from 'src/app/services';
import { RatingModalComponent } from './rating-modal/rating-modal.component';

@Component({
	selector: 'app-cards',
	templateUrl: './cards.component.html',
	styleUrls: ['./cards.component.scss'],
})
export class CardsComponent implements OnInit, OnChanges {
	@Input() data: any;
	@Input() index: number;
	@Input() type: 'class' | 'events' | 'assignments' | 'announcement' = 'class';
	@Input() view: 'edit' | 'design' = 'design'; 
	@Input() isDemandPage :boolean = false;
	@Input() currentEnrollStatus;
	isLock = false;
	unLockStr: string = '';
	expandSubStr = '';
	@Input() optionCard = false;
	status: 'Locked' | 'Unlocked' | 'In progress' | 'Completed';
	colorClass = 'background-Greens-Light_Green_2';
	icon: any;
	iconToast: string;
	announcementType = 'cal';
	toolTipText: string;
	isCurrent: boolean;
	rate: any;
	isMobile = false;
	isOverDue: boolean;
	arrowIconName = 'chevron-down-outline';
	constructor(
		private popoverController: PopoverController,
		private learningService: LearningService,
		private rudderStack: RudderStack,
		private allAssignmentsService: AllAssignmentsService,
		private router: Router,
		private modalcontroller: ModalController,
	) {}

	ngOnChanges(changes: SimpleChanges) {
		const data = changes?.data?.currentValue || this.data;
		const type = changes?.type?.currentValue || this.type;

		if (type === 'class' || type === 'assignments') {
			this.isLock = isAfter(new Date(data.startDateTime), new Date());
		} else if (type === 'events') {
			this.isLock = isAfter(
				subDays(new Date(data.startDateTime), 1),
				new Date(),
			);
		}
		if (this.isLock) {
			this.unLockStr =
				'Unlocks on ' + format(new Date(data.startDateTime), 'dd MMM');

			this.status = 'Locked';
			if (type === 'class') {
				this.expandSubStr =
					'Unlocks on ' +
					format(new Date(data.startDateTime), 'dd MMM yyyy • hh:mm a');
			} else if (type === 'assignments') {
				this.expandSubStr =
					'Unlocks on ' +
					format(new Date(data.startDateTime), 'dd MMM yyyy • hh:mm a');
			} else if (type === 'events') {
				this.unLockStr =
					'Live on ' + format(new Date(data.startDateTime), 'dd MMM');
				this.expandSubStr =
					'Live on ' +
					format(new Date(data.startDateTime), 'dd MMM yyyy • hh:mm a');
			}
		} else {
			if (type === 'class') {
				if (!data.rate) {
					let avg = 0;
					this.rate = 0;
					const modulesLength = data.modules?.filter(obj => obj.mandatory)
						?.length;
					let isInProgress = data.modules.filter(
						obj => obj.rate > 0 && obj.rate < 100,
					);
					// const completedArray = data.modules.filter(obj => obj.rate === 100);
					// if (completedArray.length !== data.modules.length) {
					// 	isInProgress.push({ rate: 1 });
					// }
					let rate = 0;
					if (modulesLength > 0) {
						rate = data.modules
							?.filter(obj => obj.mandatory)
							.reduce((acc, obj) => {
								return acc + obj.rate;
							}, 0);

						avg = Math.floor(
							rate / data.modules?.filter(obj => obj.mandatory)?.length,
						);
						if (avg !== 100 && avg === 0) {
							let optionalList = data.modules?.filter(obj => !obj.mandatory);
							optionalList = optionalList.find(obj => obj.rate === 100);
							if (optionalList) {
								avg = 10;
							}
						}

						if (avg !== 100 && isInProgress.length > 0) {
							avg = isInProgress[0].rate;
						}
					} else {
						data.modules.map(obj => {
							rate = rate + obj?.rate ? obj.rate : 0;
						});
						avg = Math.floor(rate / data.modules?.length);
					}
					data['rate'] = avg;

					if (avg === 0 || isNaN(avg)) {
						data['rate'] = 0;
					}
				}

				if (data.rate === 0) {
					this.status = 'Unlocked';
					this.expandSubStr =
						'Unlocked on ' +
						format(new Date(data.startDateTime), 'dd MMM yyyy • hh:mm a');
					this.unLockStr = 'Unlocked';
				} else if (data.rate < 100) {
					this.status = 'In progress';
					this.expandSubStr =
						'Unlocked on ' +
						format(new Date(data.startDateTime), 'dd MMM yyyy • hh:mm a');
					this.unLockStr = 'Unlocked';
				} else if (data.rate === 100) {
					this.status = 'Completed';
					if (!data.feedbackAdded) {
						this.unLockStr = 'Rate this class';
					} else {
						this.unLockStr = null;
					}

					this.expandSubStr =
						'Unlocked on ' +
						format(new Date(data.startDateTime), 'dd MMM yyyy • hh:mm a');
				}

				if (this.unLockStr === 'Rate this class') {
					if (!data.feedbackAdded) {
						this.unLockStr = 'Rate this class';
					} else {
						this.unLockStr = null;
					}
				}
			} else if (type === 'events') {
				
				this.expandSubStr =
					format(new Date(data.startDateTime), 'dd MMM yyyy • hh:mm a') +
					' - ' +
					format(new Date(data.endDateTime), 'hh:mm a');

				const isFuture = isAfter(new Date(), new Date(data.endDateTime));
				this.unLockStr = `Live on ${format(
					new Date(data.startDateTime),
					'dd MMMM',
				)}`;
				const isOver = isAfter(new Date(), new Date(data.endDateTime));
				
				this.isCurrent = isWithinInterval(new Date(), {
					start: subMinutes(new Date(this.data.startDateTime), 15),
					end: new Date(this.data.endDateTime),
				});

				if ((data.attended || data.viewed) && isFuture) {

					this.status = 'Completed';
					this.unLockStr = 'Rate this event';
				} else {
			
					const MeetBefore15Min = isWithinInterval(new Date(), {
						start: subMinutes(new Date(data.startDateTime), 15),
						end: new Date(data.endDateTime),
					});
					
					if (MeetBefore15Min) {
						this.status = 'In progress';
					} else {
						this.status = 'Unlocked';
					}
					if (isOver) {
						this.unLockStr = 'Event over';
						this.expandSubStr =
							'Concluded on ' +
							format(new Date(data.startDateTime), 'dd MMM yyyy • hh:mm a') +
							' - ' +
							format(new Date(data.endDateTime), 'hh:mm a');
					}
				}
				if (this.unLockStr === 'Rate this event') {
					if (!data.feedbackAdded) {
						this.unLockStr = 'Rate this event';
					} else {
						this.unLockStr = null;
					}
				}
			} else if (type === 'assignments') {
	
					this.isOverDue = isAfter(new Date(), new Date(data.endDateTime));

					this.status = data.assignmentStatus;
					this.icon = './assets/app-card/pencil-draw.svg';
					if (this.status === 'Completed') {
						this.isOverDue = false;
						this.unLockStr = 'Rate this assignment';
					}
					this.unLockStr = data.endDateTime
						? `Due on ${format(new Date(data.endDateTime), 'dd MMMM')}`
						: '';
	
					if (
						!!data.submissionId &&
						data.submissionDate &&
						data.assignmentStatus === 'Completed'
					) {
						this.unLockStr = 'Rate this assignment';
						this.expandSubStr =
							'Submitted on ' +
							format(new Date(data.submissionDate), 'dd MMM yyyy • hh:mm a');
					} else if (!!data.groupPadId) {
						this.expandSubStr =
							'Unlocked on ' +
							format(new Date(data.startDateTime), 'dd MMM yyyy • hh:mm a');
					} else {
						this.expandSubStr =
							'Due on ' +
							format(new Date(data.endDateTime), 'dd MMM yyyy • hh:mm a');
					}
	
					if (this.unLockStr === 'Rate this assignment') {
						if (!data.feedbackAdded) {
							this.unLockStr = 'Rate this assignment';
						} else {
							this.unLockStr = null;
						}
					}
				

			}
		}
		// Unlocked on 13 May 2022 • 10:30 PM IST
		// this.expandSubStr =
		// 	'Unlocks on ' +
		// 	format(new Date(data.startDateTime), 'dd MMM yyyy • hh:mm a');

		this.reloadIcon();
	}

	ngOnInit(): void {
		this.checkScreenSize();
	}

	reloadIcon() {
		if (this.status === 'Locked') {
			this.icon = './assets/app-card/lock.svg';
			this.iconToast = 'Locked 🔒';
		} else {
			if (this.type === 'class') {
				this.icon = './assets/app-card/book.svg';
			} else if (this.type === 'events') {
				this.icon = './assets/app-card/calendar.svg';
			} else if (this.type === 'assignments') {
				this.icon = './assets/app-card/pencil-draw.svg';
			}

			if (this.status === 'Unlocked') {
				this.iconToast = 'Unlocked 🔑';
			} else if (this.status === 'In progress') {
				this.iconToast = 'In progress ⏳';
			} else if (this.status === 'Completed') {
				this.icon = './assets/app-card/check.svg';
				this.iconToast = 'Completed 🎉';
			}
		}
		if (this.type === 'announcement') {
			this.icon = './assets/app-card/megaphone.svg';
			if (this.data.announcementType === 'survey') {
				this.toolTipText = 'Go to link';
			}
			if (this.data.announcementType === 'general') {
				this.toolTipText = 'Copy';
			} else if (this.data.announcementType === 'calendar') {
				this.toolTipText = 'Add to Calendar';
			}
		}
	}

	async openRatingModal(event, type?) {
		let css = type === 'cal' ? 'addCalendar-modal' : 'rating-modal';
		const modal = await this.modalcontroller.create({
			component: RatingModalComponent,
			cssClass: css,
			componentProps: {
				type,
				eventData: this.data,
				cohortId: this.data.cohortId,
				shared: this.data.feedbackAdded,
			},
		});
		if (type === 'cal') {
			modal.style.cssText = `--offset-x: 300px;`;
		}
		return await modal.present();
	}
	onDismiss(event, id?) {
		event.stopPropagation();
		this.learningService.deleteAnnouncementApi(id).subscribe((data: any) => {
			if (data.message) {
				this.learningService
					.loadAnnouncementData(this.data.cohortId)
					.pipe(first())
					.subscribe(({ data }) => {
						this.learningService.loadAnnouncement(
							this.data.cohortId,
							data.filter(({ eventId }) => eventId !== id),
						);
					});
			}
		});
		this.rudderStack.log({
			type: 'announcement_dismiss',
			announcementText: this.data.name,
		});
		// delete the announcement from backend
	}
	onRateEvent(event) {
		if (this.status === 'Completed') {
			event.stopPropagation();
			this.openRatingModal(event, 'rating');
		}
		return;
	}
	expand(event) {
		if (this.type === 'announcement') {
			if (this.data.announcementType === 'survey') {
				window.open(this.data.announcementAction, '_blank');
			}
			if (this.data.announcementType === 'referral') {
				navigator.clipboard.writeText(this.data.announcementAction);
			} else if (this.data.announcementType === 'calendar') {
				this.openRatingModal(event, 'cal');
			} else {
				window.open(this.data.announcementAction, '_blank');
			}
			this.rudderStack.log({
				type: 'announcement_click',
				announcementText: this.data.name,
				cohortId: this.data.cohortId,
			});
			if (this.index + 1) {
				this.rudderStack.log({
					type: 'todo_list_click',
					cohortId: this.data.cohortId,
					eventType: this.data.type,
					name: this.data.name,
					order: this.index + 1,
				});
			}
			return;
		}
		this.optionCard = !this.optionCard;
		this.arrowIconName = this.optionCard
			? 'chevron-up-outline'
			: 'chevron-down-outline';

		if (this.optionCard === true) {
			if (this.type === 'class') {
				this.rudderStack.log({
					type: 'class_open_card',
					cohortId: this.data.cohortId,
					classId: this.data.classId,
				});
			} else if (this.type === 'assignments') {
				this.rudderStack.log({
					type: 'assignment_open_card',
					cohortId: this.data.cohortId,
					assignmentId: this.data.id,
					name: this.data.name,
				});
			} else if (this.type === 'events') {
				this.rudderStack.log({
					type: 'event_open_card',
					cohortId: this.data.cohortId,
					eventId: this.data.eventId,
					name: this.data.name,
				});
			}
			if (this.index + 1) {
				this.rudderStack.log({
					type: 'todo_list_click',
					cohortId: this.data.cohortId,
					eventType: this.data.type,
					name: this.data.name,
					order: this.index + 1,
				});
			}
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		this.checkScreenSize();
	}

	checkScreenSize() {
		this.isMobile = window.innerWidth <= 992; // Adjust the breakpoint as per your needs
	}
	openWindow(url) {
		if (url) {
			window.open(url, '_blank');
		}
	}

	// Used in Assignment edit mode
	getInspired() {
		this.rudderStack.log({
			type: 'assignment_get_inspired',
			cohortId: this.data.cohortId,
			assignmentId: this.data.id,
			name: this.data.name,
		});
		this.allAssignmentsService.loadMoreOtherAssignmentsSetFilter(
			this.data.cohortId,
			{
				assignment: this.data.id,
			},
		);

		this.router.navigate([
			`/app/learning/${this.data.cohortId}/assignments/other`,
		]);
	}

	readPrompt(url) {
		window.open(url, '_blank');

		this.rudderStack.log({
			type: 'assignment_read_prompt',
			cohortId: this.data.cohortId,
			assignmentId: this.data.id,
			name: this.data.name,
			url,
		});
	}

	// join event logic
	redirectTo(type, data) {
		if (type === 'course-event') {
			this.rudderStack.log({
				type: 'event_joinevent_click',
				cohortId: data.cohortId,
				eventId: data.eventId,
				name: data.name,
			});
		} else {
			this.rudderStack.log({
				type: 'event_recording_click',
				cohortId: data.cohortId,
				eventId: data.eventId,
				name: data.name,
			});
		}
		window.open('/join/' + type + '/' + data.eventId, '_blank');
	}
}
