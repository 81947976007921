import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInput, PopoverController } from '@ionic/angular';

@Component({
	selector: 'app-select-option',
	templateUrl: './select-option.component.html',
	styleUrls: ['./select-option.component.scss'],
})
export class SelectOptionComponent implements OnInit {
	@Input() placeholder: boolean;
	@Input() items = [];
	@Input() bindLabel: string;
	@Input() bindValue: string;
	@Input() defaultValue: any;
	@Input() search: any;
	@ViewChild('searchInput', { static: false }) searchInput: IonInput;
	items1: any = [];
	focus = true;
	searchSkip = true;
	constructor(public popoverController: PopoverController) {}

	ngOnInit(): void {

		setTimeout(() => {
			if (this.searchInput) {
			  this.searchInput.setFocus();
			}
		  }, 100);
		  
		if (this.defaultValue) {
			this.search = this.defaultValue[this.bindLabel];

			const focusedList = this.items.map((item: any) => {
				const newObj: any = { ...item };

				newObj.default = newObj[this.bindLabel].replace(
					new RegExp(this.search, 'i'),
					str => `<b>${str}</b>`,
				);

				return newObj;
			});

			this.items1 = [...focusedList];
		} else {
			this.items1 = this.items;
		}
	}

	ionViewWillEnter() {
		setTimeout(() => {
			this.searchSkip = false;
		});
	}

	learnClick(item) {
		this.popoverController.dismiss(item);
	}

	onEnter(ev) {
		ev = ev?.target?.value;

		if (ev) {
			let value = {};

			value[this.bindLabel] = ev;
			value[this.bindValue] = ev;
			this.popoverController.dismiss(value);
		} else {
			this.popoverController.dismiss(false);
		}
	}

	textChange(search) {
		if (this.focus && !this.searchSkip) {
			const focusedList = this.items
				.filter(item =>
					String(item[this.bindLabel])
						?.toLowerCase()
						?.includes(search?.toLowerCase()),
				)
				.map((item: any) => {
					const newObj: any = { ...item };

					newObj.default = newObj[this.bindLabel].replace(
						new RegExp(search, 'i'),
						str => `<b>${str}</b>`,
					);

					return newObj;
				});

			this.items1 = [...focusedList];
			// this.items1 = [
			// 	...new Map(this.items1.map(item => [item['id'], item])).values(),
			// ];
		}
	}
}
