import { createReducer, on } from '@ngrx/store';
import {
	InterfaceAPI,
	interfaceApiDefaultValue,
	InterfaceAPIStatus,
} from 'src/app/model/loader';

import * as SidebarActions from './sidebar.actions';

export const sidebarFeatureKey = 'sidebar';

export enum SideMenus {
	highLights = 'highlights',
	mySchedule = 'my-schedule',
	assignments = 'assignments',
	courseInfo = 'course-info',
	resources = 'resources',
	myNotes = 'my-notes',
}

export interface State extends InterfaceAPI {
	activeCohort?: number;
}

export const initialState: State = {
	...interfaceApiDefaultValue,
};

export const reducer = createReducer(
	initialState,

	on(SidebarActions.loadSidebars, (state): State => state),
	on(
		SidebarActions.loadSidebarsSuccess,
		(state, action): State => {
			if (location.pathname.indexOf('course') > -1) {
				return {
					...state,
					data: action.data,
					apiStatus: InterfaceAPIStatus.end,
					activeCohort: action.data[0]?.cohortId,
				};
			} else if (state.data.length === 0) {
				return {
					...state,
					data: action.data,
					apiStatus: InterfaceAPIStatus.end,
					activeCohort: state.activeCohort || action.data[0]?.cohortId,
				};
			} else if (!state.activeCohort) {
				return {
					...state,
					data: action.data,
					activeCohort: action.data[0].cohortId,
					apiStatus: InterfaceAPIStatus.end,
				};
			} else {
				return {
					...state,
					data: action.data,
					apiStatus: InterfaceAPIStatus.end,
				};
			}
		},
	),
	on(
		SidebarActions.loadSidebarsApiStatus,
		(state, action): State => {
			if (action.status === InterfaceAPIStatus.start) {
				return {
					...state,
					data: [],
					apiStatus: action.status,
				};
			} else {
				return {
					...state,
					apiStatus: action.status,
				};
			}
		},
	),
	on(
		SidebarActions.sidebarMenuChange,
		(state, action): State => {
			const index = state.data.findIndex(
				h => h.cohortId === state.activeCohort,
			);
			if (index >= 0) {
				const data = [
					...state.data.slice(0, index),
					{
						...state.data[index],
						defaultMenu: action.status,
					},
					...state.data.slice(index + 1),
				];
				return {
					...state,
					data,
				};
			}

			return state;
		},
	),
	on(
		SidebarActions.menuChange,
		(state, action): State => ({ ...state, activeCohort: action.cohortId }),
	),
	on(
		SidebarActions.sidebarReset,
		(state, action): State => ({ ...interfaceApiDefaultValue }),
	),
);
