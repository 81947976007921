import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RudderStack } from '../rudderstack';

@Injectable({
	providedIn: 'root',
})
export class PublicPageService {
	isAppPage = location.pathname.includes('app');

	constructor(private router: Router, private rudderStack: RudderStack) {}

	loginPageRedirectOnPage(url = window.location.href) {
		if (!location.pathname.includes('app')) {
			if (url === window.location.href) {
				this.loggedWithOnboarding(encodeURIComponent(url) + '&s=true');
			} else {
				this.loggedWithOnboarding(
					encodeURIComponent(window.location.origin + '/' + url) + '&s=true',
				);
			}
		}
	}

	loginPageRedirectWithOnboarding(url = window.location.href) {
		this.loggedWithOnboarding(url);
	}

	// withLoggedInUser
	loggedWithOnboarding(url = window.location.href) {
		const pageViewEvent: any = {};
		pageViewEvent.type = 'redirection';
		pageViewEvent.event = -1;
		pageViewEvent.currentURL = url;
		pageViewEvent.redirectURL =
			environment.url.onboarding + '/on_boarding_done' + '/?r=' + url;
		this.rudderStack.log(pageViewEvent, () => {
			window.location.href =
				environment.url.onboarding + '/on_boarding_done' + '/?r=' + url;
		});
	}

	reloadIsAppPage() {
		this.isAppPage = location.pathname.includes('app');
	}
}
