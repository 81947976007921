import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, PopoverController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileService } from 'src/app/pages/app/profile/services/profile.service';
import { AuthService, FormService, RudderStack } from 'src/app/services';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';

@Component({
	selector: 'app-password',
	templateUrl: './password.component.html',
	styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
	show = true;
	keyword = false;
	isNotB2B$: Observable<boolean> = this.authService.isB2B$.pipe(map(d => !d));

	isSave = true;
	showPassword = false;
	showPasswordcreate = false;
	newVairable: any = `Please use at least 8 characters \n 
  Include at least 1 number \nInclude at least 1 special character\nInclude at least 1 lower case alphabet and \n1 upper case alphabet`;
	iconToastShow = 'Show password';
	iconToastHide = 'Hide password';

	profile$: Observable<any> = this.profileService.loadProfileData();

	public form: FormGroup;
	public isLoading = false;
	public forgotPasswordForm = new FormGroup({
		password: new FormControl('', [Validators.required]),
		newPassword: new FormControl('', [
			Validators.required,
			Validators.pattern(
				'^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[!-/:-@[-`{-~]).*$',
			),
		]),
	});

	isUser: string | null | undefined;
	invalidCurrentPassword: boolean;

	constructor(
		public formService: FormService,
		public authService: AuthService,
		public modalController: ModalController,
		public authstore: AngularFireAuth,
		public profileService: ProfileService,
		public popoverController: PopoverController,
		private rudderStack: RudderStack,
	) {}

	public onSubmit(email) {
		if (!this.isLoading) {
			this.isLoading = true;
			this.formService.markFormGroupTouched(this.forgotPasswordForm);
			if (this.forgotPasswordForm.status !== 'VALID') {
				this.isLoading = false;
				if (
					!this.forgotPasswordForm.value.newPassword ||
					!this.forgotPasswordForm.value.password
				) {
					this.authService.addToastMsg('Please fill missing detail.');
				}

				return;
			}
			const currentPassword = this.forgotPasswordForm.value.password;
			const newPassword = this.forgotPasswordForm.value.newPassword;

			this.authService
				.changePassword(currentPassword, newPassword)
				.then((bool: any) => {
					// success
					this.isLoading = false;
					if (!bool.status && bool.message === 'auth/wrong-password') {
						this.rudderStack.log({
							type: 'change_password_error_impression',
							message: bool.message,
						});

						this.invalidCurrentPassword = true;
					}

					if (bool.status) {
						this.rudderStack.log({
							type: 'change_password_successful',
						});

						this.reloadProfile();
					}
				})
				.catch(e => {
					this.isLoading = false;
				});
		}
	}
	ngOnInit() {}

	async showModal(email) {
		this.show = false;
		this.dismissAfterSave();

		this.authService.resetPassword(email, true);

		const modal = await this.modalController.create({
			component: ResetPasswordComponent,
			cssClass: 'password-modal-class',
		});
		modal.style.cssText = `--height:auto`;
		return await modal.present();
	}

	reloadProfile() {
		// this.modalController.dismiss();
		this.isSave = false;
	}

	dismissAfterSave() {
		this.modalController.dismiss();
	}
	validFunc() {
		this.keyword = true;
	}
	hideInvalidCurrentPassword() {
		this.invalidCurrentPassword = false;
	}
}
