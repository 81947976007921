import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { NotificationDropdownComponent } from './notification-dropdown.component';

@NgModule({
	declarations: [NotificationDropdownComponent],
	imports: [CommonModule, IonicModule],
	exports: [NotificationDropdownComponent],
})
export class NotificationDropdownComponentModule {}
