import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UploadComponent } from './upload.component';

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule],
	declarations: [UploadComponent],
	exports: [UploadComponent],
})
export class UploadComponentModule {}
