import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImagePreloadDirectiveModule } from 'src/app/directives';
import { IonicModule } from '@ionic/angular';
import { AddToCalendarComponent } from './add-to-calendar.component';
import { AddToCalendarService } from '../add-to-calendar.service';

@NgModule({
	declarations: [AddToCalendarComponent],
	imports: [CommonModule, ImagePreloadDirectiveModule, IonicModule],
	exports: [AddToCalendarComponent],
	providers: [AddToCalendarService],
})
export class AddToCalendarModule {}
