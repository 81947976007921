import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SearchPipe } from './selection-box/pipe/search.pipe';
import { SelectionBoxComponent } from './selection-box/selection-box.component';
import { SelectionBoxService } from './selection-box/service/selection-box.service';
import { SelectionDirective } from './selection.directive';

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule],
	declarations: [SelectionDirective, SelectionBoxComponent, SearchPipe],
	exports: [SelectionDirective],
	providers: [SelectionBoxService],
})
export class SelectionServerDirectiveModule {}
