import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { AppInstallerComponent } from './app-installer.component';

@NgModule({
	declarations: [AppInstallerComponent],
	imports: [CommonModule, IonicModule],
	exports: [AppInstallerComponent],
	providers: [],
})
export class AppInstallerComponentModule {}
