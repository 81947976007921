import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeMenuModalComponent } from './change-menu-modal.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
	declarations: [ChangeMenuModalComponent],
	imports: [CommonModule, IonicModule],
	exports: [ChangeMenuModalComponent],
})
export class ChangeMenuModalModule {}
