import {
	HttpContextToken,
	HttpErrorResponse,
	HttpEvent,
	HttpEventType,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { Observable, from, throwError } from 'rxjs';
import { catchError, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services';
import { environment } from 'src/environments/environment';

export const BYPASS_LOG = new HttpContextToken(() => false);

@Injectable()
export class InterceptorService implements HttpInterceptor {
	constructor(private router: Router, private authService: AuthService) {}

	public intercept(
		httpRequest: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		if (httpRequest.url?.includes('https://api.cloudinary.com/v1_1')) {
			return next.handle(httpRequest);
		} else if (httpRequest.url?.includes('upload/assignment')) {
			return next.handle(httpRequest);
		} else if (this.router.url.search('/app') === 0) {
			return from(firebase.auth()?.currentUser?.getIdToken()).pipe(
				switchMap(token => {
					const modifiedReq = httpRequest.clone({
						setHeaders: {
							// eslint-disable-next-line @typescript-eslint/naming-convention
							Authorization: 'Bearer ' + token,
						},
					});
					return next.handle(modifiedReq).pipe(
						filter((event: HttpEvent<any>) => event.type !== 0),
						tap((event: any) => {
							if (modifiedReq.method !== 'GET') {
								if (event.url?.includes('profile/view')) {
									return;
								}
								if (event.url?.includes('events/feedback')) {
									return;
								}
								if (httpRequest.context.get(BYPASS_LOG) === true) {
								} else if (event.body.message) {
									this.authService.addToastMsg(event.body.message);
								}
							}
						}),
						catchError((errors: HttpErrorResponse) => {
							const { status, url, message, error } = errors;
							let errorMsg = '';
							switch (status) {
								case 401:
									this.router.navigate([`/logout`]);
									break;
								case 400:
								case 404:
									errorMsg = `Error Code: ${status},  Message: ${message}`;
									break;
								case 405:
									errorMsg = `${url}, Error Code: ${status},  Message: ${message}`;
									break;
								default:
									break;
							}
							if (errors.statusText === 'Unknown Error') {
								errorMsg = `${url}, Unknown Error`;
							}
							if (errorMsg) {
								this.authService.addToastMsg(errorMsg);
							}
							return throwError(errorMsg);
						}),
					);
				}),
			);
		} else if (this.router.url.search('/join') === 0) {
			return from(firebase.auth()?.currentUser?.getIdToken()).pipe(
				switchMap(token => {
					const modifiedReq = httpRequest.clone({
						setHeaders: {
							// eslint-disable-next-line @typescript-eslint/naming-convention
							Authorization: 'Bearer ' + token,
						},
					});
					return next.handle(modifiedReq).pipe(
						filter((event: HttpEvent<any>) => event.type !== 0),
						catchError((errors: HttpErrorResponse) => {
							const { status, url, message, error } = errors;
							let errorMsg = '';

							switch (status) {
								case 401:
									this.router.navigate([`/logout`]);
									break;
								case 504:
								case 503:
									errorMsg = `Please try again!`;
									break;
								case 404:
									errorMsg = `Event Not Found!`;
									break;
								default:
									break;
							}
							if (!errorMsg) {
								if (error.error) {
									errorMsg = error.error;
								} else if (error) {
									errorMsg = error;
								}
							}

							if (errorMsg) {
								this.authService.addToastMsg(errorMsg);
							}

							return throwError(errorMsg);
						}),
					);
				}),
			);
		} else if (
			this.router.url.search('/my_checklist') === 0 ||
			this.router.url.search('/form') === 0
		) {
			return from(firebase.auth()?.currentUser?.getIdToken()).pipe(
				switchMap(token => {
					const modifiedReq = httpRequest.clone({
						setHeaders: {
							// eslint-disable-next-line @typescript-eslint/naming-convention
							Authorization: 'Bearer ' + token,
						},
					});
					return next.handle(modifiedReq).pipe(
						filter((event: HttpEvent<any>) => event.type !== 0),
						catchError((errors: HttpErrorResponse) => {
							const { status, url, message, error } = errors;
							let errorMsg = '';

							switch (status) {
								case 401:
									this.router.navigate([`/logout`]);
									break;
								case 504:
								case 503:
									errorMsg = `Please try again!`;
									break;
								case 404:
									errorMsg = `Event Not Found!`;
									break;
								default:
									break;
							}
							if (!errorMsg) {
								if (error.error) {
									errorMsg = error.error;
								} else if (error) {
									errorMsg = error;
								}
							}

							if (errorMsg) {
								this.authService.addToastMsg(errorMsg);
							}

							return throwError(errorMsg);
						}),
					);
				}),
			);
		} else if (this.router.url.search('/app') !== 0 && this.router.url.search('/grow') !== 0) {
			const modifiedReq = httpRequest.clone({
				setHeaders: {
					Authorization: 'Bearer ' + environment.genericToken,
				},
			});
			return next.handle(modifiedReq).pipe(
				filter((event: HttpEvent<any>) => event.type !== 0),
				tap((event: any) => {
					if (modifiedReq.method !== 'GET') {
						if (event.url?.includes('profile/view')) {
							return;
						}
						if (event.url?.includes('events/feedback')) {
							return;
						}
						if (httpRequest.context.get(BYPASS_LOG) === true) {
						} else if (event.body.message) {
							this.authService.addToastMsg(event.body.message);
						}
					}
				}),
				catchError((errors: HttpErrorResponse) => {
					const { status, url, message, error } = errors;
					let errorMsg = '';
					switch (status) {
						case 401:
							break;
						case 400:
						case 404:
							errorMsg = `Error Code: ${status},  Message: ${message}`;
							break;
						case 405:
							errorMsg = `${url}, Error Code: ${status},  Message: ${message}`;
							break;
						default:
							break;
					}
					if (errors.statusText === 'Unknown Error') {
						errorMsg = `${url}, Unknown Error`;
					}
					if (errorMsg) {
						this.authService.addToastMsg(errorMsg);
					}
					return throwError(errorMsg);
				}),
			);
		} else {
			return next.handle(httpRequest);
		}
	}
}
