import {
	Directive,
	EventEmitter,
	HostListener,
	Input,
	Output,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SelectionBoxComponent } from './selection-box/selection-box.component';

@Directive({
	selector: '[appSelection]',
})
export class SelectionDirective {
	@Input('name') name = '';
	@Input('value') value = [];
	@Input('dataOnly') dataOnly = false;
	@Input('placeholder') placeholder = '';
	@Input('type') type: 'all' | 'id' | 'value' = 'id';
	@Input('data') data: any;
	@Input('courseUI') courseUI: boolean = false;

	isMobile = false;
	@HostListener('click') onClick() {
		this.checkScreenSize();
		this.openSelection();
	}

	@Output() learnClick = new EventEmitter();

	constructor(public modalController: ModalController) {}

	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		this.checkScreenSize();
	}

	checkScreenSize() {
		this.isMobile = window.innerWidth <= 767;
	}

	async openSelection() {
		const modal = await this.modalController.create({
			component: SelectionBoxComponent,
			cssClass: 'selection',
			componentProps: {
				name: this.name,
				value: this.value,
				placeholder: this.placeholder,
				type: this.type,
				data: this.data,
				dataOnly: this.dataOnly,
				courseUI: this.courseUI,
			},
		});
		//prnv;
		if (this.isMobile) {
			modal.style.cssText = `--max-width: ${window.innerWidth}px;--min-width: 300px;z-index: 100000;`;
		} else {
			modal.style.cssText = `--max-width: 544px;--min-width: 544px;z-index: 100000;`;

			// modal.style.cssText = `--max-width: 876px;--min-width: 876px;z-index: 100000;`;
		}

		await modal.present();

		const { data } = await modal.onDidDismiss();

		if (!!data && data.data) {
			this.learnClick.emit(data.data);
		}
	}
}
