export enum roles {
	fellow = '1',
	openDoorClimate = '16',
	hiringManager = '12',
	mentor = '2',
	communityAmbassadors = '11',
	climateExpert='17',
	grow='18'

}
