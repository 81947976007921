import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingModalComponent } from './rating-modal.component';
import { NgAddToCalendarModule } from '@trademe/ng-add-to-calendar';
import { IonicModule } from '@ionic/angular';
@NgModule({
	declarations: [RatingModalComponent],
	imports: [CommonModule, NgAddToCalendarModule, IonicModule],
	exports: [RatingModalComponent],
})
export class RatingModalModule {}
