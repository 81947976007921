import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, merge, of } from 'rxjs';
import { concatMap, filter, map, mergeMap, tap } from 'rxjs/operators';
import { AuthService, NgrxService, PublicPageService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../profile/services/profile.service';
@Injectable({
	providedIn: 'root',
})
export class AppService {
	isB2B$ = this.authService.isB2B$;
	loadAppInstaller$ = this.ngrxService
		.loadData('user', 'applicationInstall')
		.pipe(filter(({ isSkeleton }: any) => !isSkeleton))
		.pipe(map(({ data }: any) => data));

	constructor(
		private ngrxService: NgrxService,
		private http: HttpClient,
		public authService: AuthService,
		private publicPageService: PublicPageService,
		private profileService: ProfileService,
	) {
		this.ngrxService.init('user', 'active-menu');
	}

	updateMenu(str) {
		this.ngrxService.loadInitData('user', 'active-menu', str);
	}

	getCurrentMenu() {
		return this.ngrxService
			.loadData('user', 'active-menu')
			.pipe(filter(({ data }: any) => data))
			.pipe(map(({ data }: any) => data));
	}

	loadAppInstallerInit() {
		return this.ngrxService.init('user', 'applicationInstall');
	}

	loadAppInstallerApi() {
		if (!this.publicPageService.isAppPage) {
			this.ngrxService.loadInitData('user', 'applicationInstall', {
				message: 'create successfully',
				exists: true,
			});
			return of();
		} else {
			return this.ngrxService.loadInitAPI('user', 'applicationInstall').pipe(
				concatMap(() =>
					this.http.get(environment.url.cohort + '/check/app/user'),
				),
				tap(data =>
					this.ngrxService.loadInitData('user', 'applicationInstall', data),
				),
			);
		}
	}

	loadAppInstallerData() {
		return combineLatest([this.loadAppInstaller$, this.isB2B$]).pipe(
			map(([data1, data2]) => {
				if (data2) {
					return { exists: true, message: 'create successfully' };
				} else {
					return data1;
				}
			}),
		);
	}

	b2bLogoInit() {
		this.ngrxService.init('b2b', 'logo');
	}

	b2bLogoApi(shortCode) {
		return this.ngrxService.loadInitAPI('b2b', 'logo').pipe(
			concatMap(() =>
				this.http.get<any>(
					`${environment.url.hubble}/b2b/company/shortcode/${shortCode || 0}`,
				),
			),
			tap(data => this.ngrxService.loadInitData('b2b', 'logo', data?.data)),
		);
	}

	loadB2bLogo() {
		return this.ngrxService.loadData('b2b', 'logo');
	}

	isFellow() {
		if (!this.publicPageService.isAppPage) {
			return of({ isFellow: false });
		} else {
			return this.profileService.loadProfileData().pipe(
				filter((data: any) => data.data),
				map((user: any) => {
					const isFellow = !!user.data.roles.find(
						role =>
							role.id === 1 || role.id === 18 || role.id === 3 || role.id === 4,
					);
					return { isFellow };
				}),
			);
		}
	}
}
