import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { roles } from 'src/app/enum/role';
import { environment } from 'src/environments/environment';

@Injectable()
export class SelectionBoxService {
	constructor(private http: HttpClient) {}

	loadTagGroups() {
		return this.http.get<any>(`${environment.url.profile}/tagGroups`).pipe(
			map(data => {
				return data.map((d: any) => {
					if (d.name === 'Job Title') {
						return {
							...d,
							values: d.values.map(record => ({
								id: record.name,
								name: record.name,
							})),
						};
					} else if (d.name === 'Locations') {
						d.values.sort();
						return {
							...d,
							values: d.values.map(record => ({
								id: record,
								name: record,
							})),
						};
					} else if (d.name === 'Industry') {
						return {
							...d,
							values: d.values.map(record => ({
								id: record.name,
								name: record.name,
							})),
						};
					} else if (d.name === 'User Roles') {
						return {
							...d,
							values: [
								{
									id: roles.hiringManager,
									name: 'Hiring on Terra.do',
								},
								{
									id: roles.communityAmbassadors,
									name: 'Community Ambassadors',
								},
							],
						};
					} else if (d.name === 'Course') {
						return {
							...d,
							values: d.values
								.filter(a => {
									if (
										[
											'Terra Grow',
											'test-course for Learning Training and Testing',
										].indexOf(a.name) > -1
									) {
										return false;
									} else {
										return true;
									}
								})
								.sort((a, b) => {
									let fa = a.name.toLowerCase(),
										fb = b.name.toLowerCase();

									if (fa < fb) {
										return -1;
									}
									if (fa > fb) {
										return 1;
									}
									return 0;
								})
								.map(dv => {
									return {
										...dv,
										cohorts: dv.cohorts.sort((a, b) => {
											let fa = a.alias.toLowerCase(),
												fb = b.alias.toLowerCase();

											if (fa < fb) {
												return -1;
											}
											if (fa > fb) {
												return 1;
											}
											return 0;
										}),
									};
								}),
						};
					} else {
						return d;
					}
				});
			}),
		);
	}
}
