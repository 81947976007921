import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AlertBoxComponent } from './alert-box/alert-box.component';
import { ConfirmBoxDirective } from './confirm-box.directive';

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule],
	declarations: [ConfirmBoxDirective, AlertBoxComponent],
	exports: [ConfirmBoxDirective],
})
export class ConfirmBoxDirectiveModule {}
