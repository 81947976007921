/* eslint-disable @ngrx/no-dispatch-in-effects */
/* eslint-disable @ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, filter, map, tap } from 'rxjs/operators';
import { InterfaceAPIStatus } from 'src/app/model/loader';
import { SelectionBoxService } from '../service/selection-box.service';
import * as SelectionBoxActions from './selection-box.actions';
import { selectApiStatus } from './selection-box.selectors';

@Injectable()
export class SelectionBoxEffects {
	loadSelectionBox$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SelectionBoxActions.loadSelectionBox),
			concatLatestFrom(() => this.store.select(selectApiStatus)),
			filter(([, apiStatus]) => apiStatus === InterfaceAPIStatus.none),
			tap(() =>
				this.store.dispatch(
					SelectionBoxActions.loadSelectionBoxApiStatus({
						status: InterfaceAPIStatus.start,
					}),
				),
			),
			concatMap(() => this.selectionBoxService.loadTagGroups()),
			map(data => SelectionBoxActions.loadSelectionBoxSuccess({ data })),
		),
	);

	constructor(
		private actions$: Actions,
		private selectionBoxService: SelectionBoxService,
		private store: Store,
	) {}
}
