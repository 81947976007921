import { NgModule } from '@angular/core';
import { ReadMoreComponent } from './read-more.component';



@NgModule({
  declarations: [ReadMoreComponent],
  imports: [
  ],
  exports: [ReadMoreComponent]
})
export class ReadMoreModule { }
