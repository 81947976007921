import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { PieChartComponent } from './pie-chart.component';
import { IonicModule } from '@ionic/angular';

import { TooltipDirectiveModule } from 'src/app/directives';

@NgModule({
	declarations: [PieChartComponent],
	imports: [CommonModule, NgChartsModule, IonicModule, TooltipDirectiveModule],
	exports: [PieChartComponent],
})
export class PieChartModule {}
