import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { RudderStack } from '../rudderstack';
import { AddToCalendarComponent } from './add-to-calendar/add-to-calendar.component';

@Injectable({
	providedIn: 'root',
})
export class AddToCalendarService {
	constructor(public popoverController: PopoverController, private rudderStack:RudderStack) {}

	async addToCalendar(ev, event) {
			
		this.rudderStack.log({
			type: 'Add to calendar',
			eventId: event.id,
		});


		const popover = await this.popoverController.create({
			component: AddToCalendarComponent,
			cssClass: 'add-to-calender',
			event: ev,
			componentProps: {
				eventDetails: event,
			},
			translucent: true,
		});
		return await popover.present();
	}
}
