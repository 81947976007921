import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageChangeMenuDirective } from './image-change-menu.directive';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ChangeMenuModalModule } from './change-menu-modal/change-menu-modal.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ChangeMenuModalModule
  ], 
  declarations: [ImageChangeMenuDirective],
  exports:[ImageChangeMenuDirective]
})
export class ImageChangeDirectiveMenuModule { }
