/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
/* eslint-disable @typescript-eslint/naming-convention */
import { DOCUMENT } from '@angular/common';
import {
	HttpClient,
	HttpContext,
	HttpEvent,
	HttpEventType,
	HttpHeaders,
	HttpRequest,
} from '@angular/common/http';
import {
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import firebase from 'firebase/compat/app';
import { catchError, last, map, tap } from 'rxjs/operators';
import { LearningService } from 'src/app/pages/app/learning/service/learning.service';
import { RudderStack } from 'src/app/services';
import { environment } from 'src/environments/environment';

import { BYPASS_LOG } from '../../pages/app/interceptor/interceptor.service';
import { AuthService } from '../../services/auth/auth.service';
import { AssignmentService } from './services/assignment.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'app-assignment-editor',
	templateUrl: './assignment-editor.component.html',
	styleUrls: ['./assignment-editor.component.scss'],
})
export class AssignmentEditorComponent implements OnInit {
	@Input() public isCommentActive = false;
	@Input() public submissionId: any = '';
	@Input() public assignmentId = '';
	public cohortId: number;
	@Input() public readOnly: boolean;
	@Output() public reloadData = new EventEmitter<any>();
	@Output() public lastUpdated = new EventEmitter<any>();

	public localComments = {};
	@ViewChild('editor') public editor: any;

	public currentUser: any;
	public files: any[] = [];
	private isAutoSaveInProgress: boolean = false;
	private contentChanged$: Subject<void> = new Subject<void>();

	public tinycomments_create = (req, done, fail) => {
		const { content, createdAt } = req;

		if (this.editor.editor.selection.getContent()) {
			this.http
				.post<any>(
					`${environment.url.cohort}/assignment/submission/comment`,
					{
						content,
						email: this.currentUser.email,
						name: this.currentUser.displayName,
						assignmentId: this.assignmentId,
						assignmentSubmissionId: this.submissionId,
					},
					{ context: new HttpContext().set(BYPASS_LOG, true) },
				)
				.toPromise()
				.then(req2 => {
					this.showMsg(req2);

					const conversationUid = req2.id;
					done({
						conversationUid,
					});
				})
				.catch(e => {
					fail();
				});
		} else {
			this.showMsg({
				error: 'error',
				message: 'Please select some text to add a comment',
			});
			fail();
		}
	};

	public tinycomments_reply = async (req, done, fail) => {
		const { conversationUid, content, createdAt } = req;

		const parent = this.localComments[conversationUid][0];

		await this.http
			.post<any>(
				`${environment.url.cohort}/assignment/submission/comment`,
				{
					content,
					email: this.currentUser.email,
					name: this.currentUser.displayName,
					assignmentId: this.assignmentId,
					assignmentSubmissionId: this.submissionId,
					parentCommentId: Number(conversationUid),
					replyTo:
						parent.email !== this.currentUser.email ? parent.email : null,
					replyToName:
						parent.authorName !== this.currentUser.displayName
							? parent.authorName
							: null,
				},
				{ context: new HttpContext().set(BYPASS_LOG, true) },
			)
			.toPromise()
			.then(req2 => {
				this.showMsg(req2);

				const commentUid = req2.id;
				done({ commentUid });
			})
			.catch(e => {
				fail(e);
			});
	};

	public tinycomments_edit_comment = (req, done, fail) => {
		const { conversationUid, commentUid, content, modifiedAt } = req;

		this.http
			.put<any>(
				`${environment.url.cohort}/assignment/submission/comment/${commentUid}`,
				{ content, modifiedAt },
				{ context: new HttpContext().set(BYPASS_LOG, true) },
			)
			.toPromise()
			.then(response => {
				this.showMsg(response);

				if (response.count !== 0) {
					done({ canEdit: true });
				} else {
					done({ canEdit: false });
				}
			})
			.catch(e => {
				fail(e);
			});
	};

	public tinycomments_delete = (req, done, fail) => {
		const conversationUid = req.conversationUid;
		this.http
			.delete<any>(
				`${environment.url.cohort}/assignment/submission/comment/${conversationUid}`,
				{ context: new HttpContext().set(BYPASS_LOG, true) },
			)
			.toPromise()
			.then(response => {
				this.showMsg(response);
				if (response.count !== 0) {
					done({ canDelete: true });
				} else {
					done({ canDelete: false });
				}
			});
	};

	public tinycomments_delete_all = (_req, done, fail) => {
		this.http
			.delete<any>(
				`${environment.url.cohort}/assignment/submission/${this.submissionId}/comments`,
				{ context: new HttpContext().set(BYPASS_LOG, true) },
			)
			.toPromise()
			.then(response => {
				this.showMsg(response);
				if (response.count !== 0) {
					done({ canDelete: true });
				} else {
					done({ canDelete: false });
				}
			});
	};

	public tinycomments_delete_comment = (req, done, fail) => {
		const { conversationUid, commentUid } = req;
		this.http
			.delete<any>(
				`${environment.url.cohort}/assignment/submission/comment/${commentUid}`,
				{ context: new HttpContext().set(BYPASS_LOG, true) },
			)
			.toPromise()
			.then(response => {
				if (response.count !== 0) {
					this.showMsg(response);
					done({ canDelete: true });
				} else {
					done({ canDelete: false });
				}
			});
	};

	public tinycomments_lookup = ({ conversationUid }, done, fail) => {
		const lookup = async () => {
			const comments: any = await this.http
				.get(
					`${environment.url.cohort}/assignment/submission/comment/${conversationUid}`,
					{ context: new HttpContext().set(BYPASS_LOG, true) },
				)
				.toPromise();

			const match = comments.data[0];

			const subReplay = [];

			match.replies.filter(r => {
				if (Object.keys(r).length !== 0) {
					subReplay.push({
						...r,
						uid: r.id,
						content: r.content,
						authorName: r.name,
						author: r.name,
						authorAvatar: r.authorAvatar
							? r.authorAvatar
							: '/assets/default-img/profile.jpg',
						createdAt: new Date(r.updatedAt),
						modifiedAt: new Date(r.updatedAt),
					});
				}
			});
			return {
				conversation: {
					uid: conversationUid,
					comments: [
						{
							...match,
							uid: match.id,
							content: match.content,
							authorName: match.name,
							authorAvatar: match.authorAvatar
								? match.authorAvatar
								: '/assets/default-img/profile.jpg',
							author: match.name,
							createdAt: new Date(match.updatedAt),
							modifiedAt: new Date(match.updatedAt),
						},
						...subReplay,
					],
				},
			};
		};
		lookup()
			.then(data => {
				this.localComments[conversationUid] = data.conversation.comments;
				done(data);
			})
			.catch(err => {
				fail(err);
			});
	};

	public example_image_upload_handler = async (blobInfo, progress) => {
		const formData = new FormData();
		formData.append('file', blobInfo.blob(), blobInfo.filename());
		const token = await firebase.auth()?.currentUser?.getIdToken();

		const media: any = await this.http
			.post(`${environment.url.profile}/upload/assignments`, formData, {
				context: new HttpContext().set(BYPASS_LOG, true),
				headers: new HttpHeaders({
					Authorization: 'Bearer ' + token,
				}),
			})
			.toPromise();

		return media.url;
	};

	public init: any = false;

	public content;

	constructor(
		private assignmentService: AssignmentService,
		private http: HttpClient,
		private learningService: LearningService,
		private rudderStack: RudderStack,
		private authService: AuthService,
		@Inject(DOCUMENT) private document: Document,
	) {}

	public ngOnInit() {
		this.assignmentService.loadProfile().subscribe(data => {
			this.currentUser = {
				email: data.email,
				displayName: `${data.firstName} ${data.lastName}`,
			};
		});
		this.contentChanged$.pipe(debounceTime(3000)).subscribe(() => {
			this.lastUpdated.emit();
			this.onUpdate();
			this.isAutoSaveInProgress = true;
		});

		const extendObj: any = {
			tinycomments_create: this.tinycomments_create,
			tinycomments_reply: this.tinycomments_reply,
			tinycomments_edit_comment: this.tinycomments_edit_comment,
			tinycomments_delete: this.tinycomments_delete,
			tinycomments_delete_all: this.tinycomments_delete_all,
			tinycomments_delete_comment: this.tinycomments_delete_comment,
			tinycomments_lookup: this.tinycomments_lookup,
			images_upload_handler: this.example_image_upload_handler,
		};

		if (this.readOnly) {
			extendObj.init_instance_callback = editor => {
				editor.getBody().setAttribute('contenteditable', false);
				editor.on('CommentChange', evt => {
					this.assignmentService
						.saveHtml(this.submissionId, {
							content: editor.getBody().innerHTML,
							fileUrls: this.files.map(obj => obj.fileUrl),
						})
						.subscribe(() => {});
				});

				editor.getBody().addEventListener(
					'click',
					event => {
						if (event.target.tagName === 'A') {
							if (event.target.href.search('http') === -1) {
								window.open(`https://${event.target.href}`, '_blank');
							} else {
								window.open(event.target.href, '_blank');
							}
							event.preventDefault();
						}
					},
					true,
				);

				editor.getBody().addEventListener(
					'focusout',
					event => {
						console.log(event);
					},
					true,
				);

				editor.on('ObjectSelected', e => {
					console.log(`The ${e} command was fired.`);
				});

				editor.on('BeforeObjectSelected', e => {
					console.log(`The ${e} command was fired.`);
				});

				editor.getBody().onkeydown = function (evt) {
					if (evt.which === 13 || evt.which === 8 || evt.which === 46) {
						alert('You modified the base doc. Reloading...');
						evt.preventDefault();
						evt.stopPropagation();
						location.reload();
						return false;
					}
				};

				// editor.getBody().addEventListener(
				// 	'keydown',
				// 	event => {
				// 		if (event.keyCode === 13) {
				// 			debugger;
				// 			event.preventDefault();
				// 			event.stopPropagation();
				// 			return false;
				// 		}
				// 	},
				// 	false,
				// );
			};
			extendObj.tinycomments_can_resolve = (req, done, fail) => {
				done({
					canResolve: false,
				});
			};
		}
		this.init = {
			height: 700,
			plugins: this.readOnly ? 'tinycomments' : 'image link table lists',
			toolbar: this.readOnly
				? 'showcomments'
				: 'blocks | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat preview  | insertfile image media pageembed  link  | a11ycheck ltr rtl',
			placeholder: 'Please enter minimum 10 characters.',
			sidebar_show: 'showcomments',
			menubar: '',
			...extendObj,
		};

		this.assignmentService.loadHtml(this.submissionId).subscribe(data => {
			this.content = data.content;
			this.files = data.assignmentUploads;
			this.assignmentId = data.assignmentId;
			this.cohortId = data.cohortId;
			localStorage.setItem('assignmentId', this.assignmentId);
		});
	}
	autoSave(content: any) {
		this.contentChanged$.next();
	}
	public onUpdate() {
		this.rudderStack.log({
			type: 'assignment_save_draft',
			cohortId: this.cohortId,
			submissionId: this.submissionId,
		});

		this.assignmentService
			.saveHtml(this.submissionId, {
				content: this.content,
				fileUrls: this.files.map(obj => obj.fileUrl),
			})
			.subscribe((req: any) => {
				if (!this.isAutoSaveInProgress) {
					this.authService.addToastMsg(req.message);
				}
				this.isAutoSaveInProgress = false;
			});
	}

	public showMsg(req) {
		if (req.error || req.count === 0) {
			this.editor.editor.notificationManager.open({
				text: req.message,
				type: 'error',
				timeout: 3000,
			});
		} else {
			this.editor.editor.notificationManager.open({
				text: req.message,
				type: 'success',
				timeout: 3000,
			});
		}
	}

	public onSubmit() {
		console.log(this.files);
		this.rudderStack.log({
			type: 'assignment_submitted',
			cohortId: this.cohortId,
			submissionId: this.submissionId,
		});

		if (this.content.length < 10) {
			this.authService.addToastMsg('Please enter minimum 10 characters.');
			return false;
		}
		this.assignmentService
			.saveHtml(this.submissionId, {
				content: this.content,
				fileUrls: this.files.map(obj => obj.fileUrl),
			})
			.subscribe((data: any) => {
				if (data.message) {
					this.assignmentService
						.saveAssignment(
							this.currentUser.email,
							this.submissionId,
							this.files,
						)
						.subscribe(response => {
							if (response) {
								this.learningService.editState(
									'assignment',
									this.cohortId,
									this.assignmentId,
									'id',
									'assignmentStatus',
									'Completed',
								);
								this.reloadData.emit();
							}
						});
				}
			});
	}

	public async attachFileInput(event) {
		this.rudderStack.log({
			type: 'assignment_upload_file',
			cohortId: this.cohortId,
			submissionId: this.submissionId,
		});

		const selectedFile = event.target.files[0] as File;

		if (!!selectedFile) {
			if (selectedFile.size > 10485760) {
				this.authService.addToastMsg(
					'Oops! The size limit for documents is 10 MB. Reduce the file size and try again.',
				);
				return false;
			}

			const formData: FormData = new FormData();
			formData.append('file', selectedFile);
			formData.append(
				'tags',
				this.rudderStack.currentProfileId +
					'_' +
					this.cohortId +
					'_' +
					this.submissionId +
					'_' +
					this.assignmentId,
			);
			formData.append('upload_preset', 'tugadh68');

			const token = await firebase.auth()?.currentUser?.getIdToken();

			this.http
				.post<any>(`${environment.url.profile}/upload/assignment`, formData, {
					reportProgress: true,
					headers: new HttpHeaders({
						Authorization: 'Bearer ' + token,
					}),
					observe: 'events',
				})
				.pipe(
					catchError(errors => {
						const { status, message, error } = errors;

						const errorMsg = `Error Code: ${status},  Message: ${message}`;

						this.editor.editor.notificationManager.open({
							text:
								'File format not supported. Please try a different file type.',
							type: 'success',
							timeout: 10000,
						});

						throw error;
					}),
				)
				.subscribe((event: any) => {
					if (HttpEventType.UploadProgress === event.type) {
						this.editor.editor.notificationManager.open({
							text:
								'Uploading Process ' +
								Math.round((event.loaded * 100) / event.total),
							type: 'success',
							timeout: 1000,
						});
					} else if (HttpEventType.DownloadProgress === event.type) {
						this.editor.editor.notificationManager.open({
							text: 'Uploading Done',
							type: 'success',
							timeout: 1000,
						});
					} else if (HttpEventType.Response === event.type) {
						const url = event.body.url;

						this.files.push({ fileUrl: url });
						this.assignmentService
							.saveHtml(this.submissionId, {
								content: this.content,
								fileUrls: this.files.map(obj => obj.fileUrl),
							})
							.subscribe((req: any) => {
								this.authService.addToastMsg(req.message);
							});
					}
				});
		}
	}
	public removeItem(item: string) {
		const index = this.files.indexOf(item);
		if (index !== -1) {
			this.files.splice(index, 1);
		}
		this.assignmentService
			.saveHtml(this.submissionId, {
				content: this.content,
				fileUrls: this.files.map(obj => obj.fileUrl),
			})
			.subscribe((req: any) => {
				this.authService.addToastMsg(req.message);
			});
	}
	public openNewTab(url: any) {
		window.open(url, '_blank');
	}
	public getTrimmedUrl(fullUrl: any) {
		const startIndex = fullUrl.indexOf('.com/') + 5;
		return fullUrl.substring(startIndex);
	}
}
