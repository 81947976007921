import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AuthService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-change-menu-modal',
	templateUrl: './change-menu-modal.component.html',
	styleUrls: ['./change-menu-modal.component.scss'],
})
export class ChangeMenuModalComponent implements OnInit {
	@Input() profileData;

	constructor(
		private http: HttpClient,
		public popoverController: PopoverController,
		private authService: AuthService,
	) {}

	ngOnInit() {
		// block
	}
	public async closeModal(url) {
		await this.popoverController.dismiss({
			url,
		});
	}
	handleFileInput(event) {
		const selectedFile = event.target.files[0] as File;
		let extension = '';
		if (!!selectedFile) {
			if (selectedFile.size > 2097152) {
				this.authService.addToastMsg('File size should be less than 2 MB');
				return false;
			}

			extension = selectedFile.name.split('.')[
				selectedFile.name.split('.').length - 1
			];
			const formData: FormData = new FormData();
			formData.append('file', selectedFile);
			formData.append('upload_preset', environment.profileUploadKey);

			this.http
				.post<any>(
					`https://api.cloudinary.com/v1_1/terra-do/image/upload`,
					formData,
				)
				.subscribe(({ url }) => {
					this.closeModal(url);
				});
		}
	}
	removeProfileImage() {
		this.closeModal(null);
	}
}
