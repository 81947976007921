import { Component, Input, OnInit } from '@angular/core';
import { RudderStack } from 'src/app/services';

@Component({
	selector: 'app-app-installer',
	templateUrl: './app-installer.component.html',
	styleUrls: ['./app-installer.component.scss'],
})
export class AppInstallerComponent implements OnInit {
	@Input() cardText =
		'Please download the Terra.do app to access all our learning, careers and community features.';
	@Input() isMobile = false;

	constructor(private rudderStack: RudderStack) {}

	ngOnInit() {
		//empty
	}

	openLink(link) {
		this.rudderStack.log({ type: 'click_install_app' });
		window.open(link, '_blank');
	}
}
