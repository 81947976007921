import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { SelectOptionComponent } from './select-option/select-option.component';

@Component({
	selector: 'app-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges {
	@ViewChild('selectionOpen') selectionOpen: ElementRef;

	@Input() newDesign: boolean = false;

	@Input() items = [];
	@Input() bindLabel: string;
	@Input() bindValue: string;
	@Input() searchable: boolean;
	@Input() placeholder: any;
	@Input() value: string;
	@Input() width: number;
	@Output() learnClick = new EventEmitter<any>();

	isCanOpenBox = true;

	selectedItem: any;

	constructor(public popoverController: PopoverController) {}

	ngOnInit(): void {
		this.reloadInput();
	}

	ngOnChanges(changes: SimpleChanges) {
		try {
			if (!changes.value.currentValue) {
				this.selectedItem = null;
			}
		} catch (e) {}
	}
	reloadInput() {
		if (this.value) {
			this.selectedItem = this.items?.find(
				obj => obj[this.bindValue] === this.value[this.bindValue],
			);

			if (!this.selectedItem || Object.keys(this.selectedItem).length === 0) {
				this.selectedItem = this.value;
			}
		}
	}

	async open(event) {
		if (this.isCanOpenBox) {
			this.isCanOpenBox = false;
			this.reloadInput();
			const modal = await this.popoverController.create({
				component: SelectOptionComponent,
				cssClass: 'learn-class',
				event,
				alignment: 'start',
				componentProps: {
					items: this.items,
					placeholder: this.placeholder,
					bindLabel: this.bindLabel,
					bindValue: this.bindValue,
					defaultValue: this.selectedItem,
				},
			});

			if (this.newDesign) {
				modal.style.cssText = `--max-width: ${this.width}px;--min-width: ${this.width}px;--offset-x: 0px; --offset-y: -68px;`;
			} else {
				modal.style.cssText = `--max-width: ${this.width}px;--min-width: ${this.width}px;--offset-x: 0px; --offset-y: -52px;`;
			}

			await modal.present();

			const { data } = await modal.onDidDismiss();

			if (!!data) {
				this.selectedItem = data;

				this.learnClick.emit(this.selectedItem);
			} else if (data === false || data === '') {
				this.selectedItem = '';
				this.learnClick.emit(this.selectedItem);
			}

			this.isCanOpenBox = true;
		}
	}
}
