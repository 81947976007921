import {
	Directive,
	EventEmitter,
	HostListener,
	Input,
	Output,
} from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ChangeMenuModalComponent } from './change-menu-modal/change-menu-modal.component';

@Directive({
	selector: '[appImageChangeMenu]',
})
export class ImageChangeMenuDirective {
	@Input() profileData: any = '';
	@Output() success = new EventEmitter<any>();

	@HostListener('click', ['$event']) onclick(event: Event) {
		this.presentImageUploadModal(event);
	}
	async presentImageUploadModal(event) {
		const modal = await this.popoverController.create({
			component: ChangeMenuModalComponent,
			cssClass: 'image-upload-modal-class',
			event,
			componentProps: {
				profileData: this.profileData,
			},
		});
		modal.onDidDismiss().then(url => {
			const data = url;
			if (data.data) {
				this.success.emit(data.data.url);
			}
		});
		if (this.profileData.multimedia) {
			modal.style.cssText = `--max-height: 57px;--min-height: 160px;`;
		} else {
			modal.style.cssText = `--max-width: 114px;--min-width: 114px;`;
		}
		modal.style.cssText = `--max-width: 160px;--min-width: 160px;`;
		return await modal.present();
	}
	constructor(public popoverController: PopoverController) {}
}
