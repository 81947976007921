import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatMap, tap } from 'rxjs/operators';
import { NgrxService, RudderStack } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ReferralService {
	constructor(
		private ngrxService: NgrxService,
		private http: HttpClient,
		private rudderStack: RudderStack,
	) {}

	loadReferralCountInit() {
		this.ngrxService.init('referral', 'wallet');
	}

	loadReferralApi() {
		return this.ngrxService.loadInitAPI('referral', 'wallet').pipe(
			concatMap(() =>
				this.http.get(
					environment.url.catalog +
						'/scheme/profile/' +
						this.rudderStack.currentProfileId,
				),
			),
			tap((data: any) =>
				this.ngrxService.loadInitData('referral', 'wallet', data),
			),
		);
	}

	loadReferralDataSet(data) {
		this.ngrxService.loadInitData('referral', 'wallet', data);
	}

	loadReferralData() {
		return this.ngrxService.loadData('referral', 'wallet');
	}

	loadCountAPI() {
		return this.http.get(environment.url.payment + 'refer/fellow/count');
	}
}
