import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {
	ImagePreloadDirectiveModule,
	TooltipDirectiveModule,
} from 'src/app/directives/index';
import { ConfirmationPopupComponentModule } from '../confirmation-popup/confirmation-popup.component.module';
import { MainHeaderComponent } from './main-header.component';
import { NotificationDropdownComponentModule } from './notification-dropdown/notification-dropdown.module';
import { AppInstallerMobileModule } from 'src/app/pages/events/components/app-installer-mobile/app-installer-mobile.module';
import { ReferralModule } from '../referral/referral.module';

@NgModule({
	declarations: [MainHeaderComponent],
	exports: [MainHeaderComponent, ImagePreloadDirectiveModule],
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		ImagePreloadDirectiveModule,
		NotificationDropdownComponentModule,
		ReactiveFormsModule,
		TooltipDirectiveModule,
		ConfirmationPopupComponentModule,
		AppInstallerMobileModule,
		ReferralModule,
	],
})
export class MainHeaderComponentModule {}
