import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-alert-box',
	templateUrl: './alert-box.component.html',
	styleUrls: ['./alert-box.component.scss'],
})
export class AlertBoxComponent implements OnInit {
	@Input() title: string;
	@Input() subTitle: string;

	constructor(private modalController: ModalController) {}

	ngOnInit() {}

	withdraw() {
		this.modalController.dismiss({ isDelete: true });
	}

	cancel() {
		this.modalController.dismiss();
	}
}
