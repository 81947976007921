import { createFeatureSelector, createSelector } from '@ngrx/store';
import { apiRecordData } from 'src/app/model/loader';
import * as fromSidebar from './sidebar.reducer';

export const selectSidebarState = createFeatureSelector<fromSidebar.State>(
	fromSidebar.sidebarFeatureKey,
);
export const selectApiStatus = createSelector(
	selectSidebarState,
	state => state.apiStatus,
);
export const selectSidebar = createSelector(selectSidebarState, apiRecordData);

export const selectSidebarMenus = createSelector(
	selectSidebarState,
	state =>
		state.data.filter(({ cohortId }) => cohortId === state.activeCohort)[0],
);

export const selectSidebarMenuExists = createSelector(
	selectSidebarState,
	state =>
		state.activeCohort &&
		state.data.filter(({ cohortId }) => cohortId === state.activeCohort)
			.length !== 0,
);
