import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ImagePreloadDirectiveModule } from 'src/app/directives';
import { AddMemberModalComponent } from './add-member-modal.component';
import { searchMembersPipe } from './search-members.pipe';
import { selectedMembersPipe } from './selected-members.pipe';

@NgModule({
	declarations: [
		AddMemberModalComponent,
		selectedMembersPipe,
		searchMembersPipe,
	],
	imports: [
		CommonModule,
		ImagePreloadDirectiveModule,
		IonicModule,
		FormsModule,
	],
	exports: [AddMemberModalComponent],
})
export class AddMemberModalModule {}
