import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { concatMap, first, map, tap } from 'rxjs/operators';
import { NgrxService, RudderStack } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ProfileSearchService {
	serviceName = 'profileSearch';
	randomId = {
		'profileSearch-open-door': 0,
		'profileSearch-all': 0,
		'profileSearch-fellow': 0,
		'profileSearch-mentor': 0,
	};

	constructor(
		private http: HttpClient,
		private store: Store<any>,
		private ngrxService: NgrxService,
		private rudderStack: RudderStack,
	) {}

	loadSearchProfileApiOpenAI(filter: any = {}) {
		this.randomId[this.serviceName] = String(Math.random());
		console.log(filter);
		let payload: any = {
			profileId: this.rudderStack.currentProfileId,
			isSave: false,
			// isSave: true, //  Boolean flag indicating if the profile should be saved (defaults to false)
			// roleIds: [36], // Optional: List of role IDs ["rolesIds"]
			// interestsIds: [36], // Optional: List of interest IDs ["interestsIds"]
			// expertiseIds: [36], // Optional: List of expertise IDs ["skillsIds"]
			// helpWithIds: [36], // Optional: List of help-with IDs ["helpWithIds"]
			// cohortIds: [36], // Optional: List of cohort IDs ["cohortIds"]
			// courseIds: [36], // Optional: List of course IDs ["courseIds"]
			// jobFunction: [''], // Optional: Job function (string) ["jobFunction"]
			// country: [''], // Optional: country (string) ["country"]
		};

		if ('search' in filter) {
			payload = {
				...payload,
				searchText: filter.search[0],
			};
		}

		if ('isSave' in filter) {
			payload = {
				...payload,
				isSave: true,
			};
		}

		if ('roles.id AND' in filter) {
			payload = {
				...payload,
				roleIds: filter['roles.id AND'].map(id => Number(id)),
			};
		}

		if ('courses.cohortId AND' in filter) {
			if ('courses.cohortId' in filter) {
				payload = {
					...payload,
					cohortIds: [
						...filter['courses.cohortId'].map(id => Number(id)),
						...filter['courses.cohortId AND'].map(id => Number(id)),
					],
				};
			} else {
				payload = {
					...payload,
					cohortIds: filter['courses.cohortId AND'].map(id => Number(id)),
				};
			}
		}

		console.log(payload);

		return this.ngrxService.loadInitAPI(this.serviceName, 'search').pipe(
			concatMap(() =>
				this.http.post<any>(
					`${environment.url.profile}/pineCone/profile/data/20`,
					payload,
				),
			),
			map((data: any) => {
				return data.map(re => {
					return {
						...re,
						address: re.address || re.country || re.state || re.city,
						multimedia48: re.multimedia?.replace(
							'terra-do/image/upload',
							'terra-do/image/upload/w_48,h_48,c_limit',
						),
						multimedia240: re.multimedia?.replace(
							'terra-do/image/upload',
							'terra-do/image/upload/w_240,h_240,c_limit',
						),
					};
				});
			}),
			tap((data: any) => {
				if (data.length < 20) {
					this.updateData(data, data.length);
				} else {
					this.randomId[this.serviceName] = String(Math.random());

					let payload: any = {
						id: this.randomId[this.serviceName],
						name: 'Search profiles v2',
						profileId: this.rudderStack.currentProfileId,
						searchQuery: {
							...filter,
						},
						searchOpt: {},
						boolQuery: {},
					};

					if ('search' in payload.searchQuery) {
						payload.saveSearch = {
							profileId: this.rudderStack.currentProfileId,
							searchText: payload.searchQuery.search[0],
						};
					}

					if ('sortBy' in payload.searchQuery) {
						delete payload.searchQuery.sortBy;
						payload.sortBy = filter.sortBy;
					}

					if ('profileId' in payload.searchQuery) {
						delete payload.searchQuery.profileId;
						payload.profileId = filter.profileId;
					}

					if ('courses.cohortId AND' in payload.searchQuery) {
						if ('courses.cohortId' in payload.searchQuery) {
							delete payload.searchQuery['courses.cohortId AND'];
							payload.boolQuery['courses.cohortId'] =
								filter['courses.cohortId'];
							payload.searchQuery['courses.cohortId'] =
								filter['courses.cohortId AND'];
						} else {
							delete payload.searchQuery['courses.cohortId AND'];
							payload.searchQuery['courses.cohortId'] =
								filter['courses.cohortId AND'];
						}
					}

					if (data.length > 0) {
						payload.searchQuery['id NOT'] = data.map(d => String(d.id));
					}

					this.http
						.post<any>(`${environment.url.profile}/search/limit/0`, [payload])
						.subscribe(data1 => {
							this.updateData(data, data1[0]?.total + 20);
						});
				}
			}),
		);
	}

	loadSearchProfileApi(filter: any = {}) {
		this.randomId[this.serviceName] = String(Math.random());

		let payload: any = {
			id: this.randomId[this.serviceName],
			name: 'Search profiles v2',
			profileId: this.rudderStack.currentProfileId,
			searchQuery: {
				...filter,
			},
			searchOpt: {},
			boolQuery: {},
		};

		if ('search' in payload.searchQuery) {
			payload.saveSearch = {
				profileId: this.rudderStack.currentProfileId,
				searchText: payload.searchQuery.search[0],
			};
		}

		if ('sortBy' in payload.searchQuery) {
			delete payload.searchQuery.sortBy;
			payload.sortBy = filter.sortBy;
		}

		if ('profileId' in payload.searchQuery) {
			delete payload.searchQuery.profileId;
			payload.profileId = filter.profileId;
		}

		if ('courses.cohortId AND' in payload.searchQuery) {
			if ('courses.cohortId' in payload.searchQuery) {
				delete payload.searchQuery['courses.cohortId AND'];
				payload.boolQuery['courses.cohortId'] = filter['courses.cohortId'];
				payload.searchQuery['courses.cohortId'] =
					filter['courses.cohortId AND'];
			} else {
				delete payload.searchQuery['courses.cohortId AND'];
				payload.searchQuery['courses.cohortId'] =
					filter['courses.cohortId AND'];
			}
		}

		return this.ngrxService.loadInitAPI(this.serviceName, 'search').pipe(
			concatMap(() =>
				this.http.post<any>(`${environment.url.profile}/search/limit/20`, [
					payload,
				]),
			),
			tap((data: any) => {
				this.updateData(
					[...data[0]?.result].map(re => {
						return {
							...re,
							address: re.address || re.country || re.state || re.city,
							multimedia48: re.multimedia?.replace(
								'terra-do/image/upload',
								'terra-do/image/upload/w_48,h_48,c_limit',
							),
							multimedia240: re.multimedia?.replace(
								'terra-do/image/upload',
								'terra-do/image/upload/w_240,h_240,c_limit',
							),
						};
					}),
					data[0]?.total,
				);
			}),
		);
	}

	updateData(records, total) {
		return this.ngrxService.loadInitDataPagination(
			this.serviceName,
			'search',
			records,
			total,
		);
	}

	loadMoreSearchProfileApi(filter: any = {}, afters: any, notIn: any = []) {
		let after = afters;

		let payload: any = {
			id: this.randomId[this.serviceName],
			name: 'Search profiles v2',
			profileId: this.rudderStack.currentProfileId,
			searchQuery: { ...filter },
			searchOpt: {},
			boolQuery: {},
		};

		if (after && after.length > 0) {
			if (after[0] == -9223372036854776000) {
				// ES can not handle error
				after = ['-9223372036854775808', ...after.slice(1)];
			}

			payload = {
				...payload,
				after,
			};
		}

		if ('sortBy' in payload.searchQuery) {
			delete payload.searchQuery.sortBy;
			payload.sortBy = filter.sortBy;
		}

		if ('profileId' in payload.searchQuery) {
			delete payload.searchQuery.profileId;
			payload.profileId = filter.profileId;
		}

		if ('courses.cohortId AND' in payload.searchQuery) {
			if ('courses.cohortId' in payload.searchQuery) {
				delete payload.searchQuery['courses.cohortId AND'];
				payload.boolQuery['courses.cohortId'] = filter['courses.cohortId'];
				payload.searchQuery['courses.cohortId'] =
					filter['courses.cohortId AND'];
			} else {
				delete payload.searchQuery['courses.cohortId AND'];
				payload.searchQuery['courses.cohortId'] =
					filter['courses.cohortId AND'];
			}
		}

		if (notIn.length > 0) {
			payload.searchQuery['id NOT'] = notIn;
		}

		return this.ngrxService.loadMoreInitAPI(this.serviceName, 'search').pipe(
			concatMap(() =>
				this.http.post<any>(`${environment.url.profile}/search/limit/20`, [
					payload,
				]),
			),
			tap((data: any) => {
				const obj = [...data[0]?.result].map(re => {
					return {
						...re,
						address: re.address || re.country || re.state || re.city,
						multimedia48: re.multimedia?.replace(
							'terra-do/image/upload',
							'terra-do/image/upload/w_48,h_48,c_limit',
						),
						multimedia240: re.multimedia?.replace(
							'terra-do/image/upload',
							'terra-do/image/upload/w_240,h_240,c_limit',
						),
					};
				});
				if (data[0]?.result.length === 20) {
					this.ngrxService.loadMoreDataPagination(
						this.serviceName,
						'search',
						obj,
					);
				} else {
					this.loadSearchProfileData()
						.pipe(first())
						.subscribe(data => {
							const newObj = [...data.data, ...obj];
							this.updateData(newObj, newObj.length);
						});
				}
			}),
		);
	}

	loadSearchProfileInit() {
		this.ngrxService.init(this.serviceName, 'search');
	}

	loadSearchProfileData() {
		return this.ngrxService.loadData(this.serviceName, 'search');
	}

	loadFilterData() {
		return this.ngrxService.loadFilter(this.serviceName, 'search');
	}

	loadFilterProfileApi(filter: any = {}) {
		this.ngrxService.setFilter(this.serviceName, 'search', filter);
	}

	resetFilterProfileApi() {
		this.ngrxService.resetFilter(this.serviceName, 'search');
	}

	resetState() {
		this.ngrxService.resetState(this.serviceName, 'search');
	}

	deleteProfile(eventId) {
		this.ngrxService.deleteFromState(this.serviceName, 'search', eventId);
	}

	loadSearchExportProfileInit() {
		this.ngrxService.init(this.serviceName, 'export-search');
	}

	loadSearchExportProfileAPI() {
		return this.ngrxService.loadInitAPI(this.serviceName, 'export-search').pipe(
			concatMap(() =>
				this.http.get<any>(
					`${environment.url.profile}/list/testimonial/limit/2/offset/0`,
				),
			),
			tap(data =>
				this.ngrxService.loadInitData(this.serviceName, 'export-search', data),
			),
		);
	}

	loadSearchExportProfileData() {
		return this.ngrxService.loadData(this.serviceName, 'export-search');
	}
}
