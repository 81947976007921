import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TooltipDirectiveModule } from 'src/app/directives/index';
import { SocialMediaComponent } from './social-media.component';

@NgModule({
	declarations: [SocialMediaComponent],
	imports: [CommonModule, IonicModule, TooltipDirectiveModule],
	exports: [SocialMediaComponent],
})
export class SocialMediaComponentModule {}
