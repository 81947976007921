import { Pipe, PipeTransform } from '@angular/core';

interface memberModel {
	firstName: string;
	lastName: string;
}

@Pipe({
	name: 'searchMembers',
	pure: false,
})
export class searchMembersPipe implements PipeTransform {
	public transform(allHeroes: memberModel[], name?: string): any {
		if (!name) {
			return allHeroes;
		} else {
			return allHeroes.filter(
				({ firstName, lastName }) =>
					String(firstName + ' ' + lastName)
						.toLowerCase()
						.search(name.toLowerCase()) > -1,
			);
		}
	}
}
