import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { environment } from 'src/environments/environment';
import { AppModule } from './app/app.module';

Sentry.init({
	dsn:
		'https://60ad2543a6174831b3fb0664742f46cd@o916632.ingest.sentry.io/4504530735333376',
	integrations: [
		new BrowserTracing({
			tracePropagationTargets: ['localhost', 'https://yourserver.io/api'],
			routingInstrumentation: Sentry.routingInstrumentation,
		}),
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.log(err));
