import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class TextBookService {
	constructor() {}

	open(moduleId) {
		const rUrl = window.location.href.split('#')[0];

		if (
			location.href.search('my-schedule') > 0 ||
			location.href.search('library') > 0
		) {
			window.open(
				'https://textbook.terra.do/class/' +
					moduleId +
					'?r=' +
					rUrl +
					'#' +
					moduleId,
				'_blank',
			);
		} else {
			window.open('https://textbook.terra.do/class/' + moduleId, '_blank');
		}
	}
}
