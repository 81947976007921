import { createAction, props } from '@ngrx/store';
import { InterfaceAPIStatus } from 'src/app/model/loader';
import { SideMenus } from './sidebar.reducer';

export const loadSidebars = createAction('[Sidebar] Load Sidebars');

export const loadSidebarsSuccess = createAction(
	'[Sidebar] Load Sidebars Success',
	props<{ data: any }>(),
);

export const loadSidebarsApiStatus = createAction(
	'[Sidebar] Load Sidebars API Status',
	props<{ status: InterfaceAPIStatus }>(),
);

export const sidebarMenuChange = createAction(
	'[Sidebar] Sidebars Sub Menu Change',
	props<{ status: SideMenus }>(),
);

export const menuChange = createAction(
	'[Sidebar] Sidebar Menu Change',
	props<{ cohortId: number }>(),
);
