import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgAddToCalendarModule } from '@trademe/ng-add-to-calendar';
import {
	RolesComponentModule,
	SelectComponentModule,
	UploadComponentModule,
} from 'src/app/components/index';
import {
	ImageChangeDirectiveMenuModule,
	ImagePreloadDirectiveModule,
	TooltipDirectiveModule,
} from 'src/app/directives/index';

import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { ScullyLibModule } from '@scullyio/ng-lib';
import * as Sentry from '@sentry/angular';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SocialMediaComponentModule } from './components/social-media/social-media.component.module';
import { SelectionDirectiveModule } from './directives';
import { InterceptorService } from './pages/app/interceptor/interceptor.service';
import { metaReducers, reducers } from './reducers';
import {
	AddToCalendarModule,
	AddToCalendarService,
	AuthService,
	NgrxService,
	RudderStack,
} from './services/';
import { ProfileService } from './pages/app/profile/services/profile.service';
import { AngularFireAuthGuardModule } from './auth-guard/auth-guard.module';
import { AngularFireAuthGuardV2Module } from './auth-guard-v2/auth-guard-v2.module';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		HttpClientModule,
		provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		provideFirestore(() => getFirestore()),
		StoreModule.forRoot(reducers, { metaReducers }),
		StoreRouterConnectingModule.forRoot(),
		EffectsModule.forRoot([]),
		!environment.production
			? StoreDevtoolsModule.instrument({
					maxAge: 25,
					logOnly: environment.production,
			  })
			: [],
		ImagePreloadDirectiveModule,
		TooltipDirectiveModule,
		SelectComponentModule,
		RolesComponentModule,
		UploadComponentModule,
		SocialMediaComponentModule,
		SelectionDirectiveModule,
		ImageChangeDirectiveMenuModule,
		NgAddToCalendarModule,
		AddToCalendarModule,
		ScullyLibModule,
		NgxYoutubePlayerModule.forRoot(),
		AngularFireAuthGuardModule,
		AngularFireAuthGuardV2Module,
	],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
		{ provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
		NgrxService,
		AddToCalendarService,
		RudderStack,
		ProfileService,
		AuthService,
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
