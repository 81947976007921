import { Component, Input, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { AllAssignmentsService } from 'src/app/pages/app/learning/cohort/assignments/services/all-assignments.service';
import { RudderStack } from 'src/app/services';
import { environment } from 'src/environments/environment';

import { LearningService } from '../../../pages/app/learning/service/learning.service';
import { AddMemberModalComponent } from '../../add-member-modal/add-member-modal.component';
import { AssignmentService } from './services/assignment.service';
import { IonicModule } from '@ionic/angular';
@Component({
	selector: 'app-assignment',
	templateUrl: './assignment.component.html',
	styleUrls: ['./assignment.component.css'],
})
export class AssignmentComponent implements OnInit {
	@Input() public data: any;
	@Input() public isLock: boolean;
	@Input() public view: 'edit' | 'design' = 'design';

	selectedMembers = [];
	assignmentDetails$: Observable<any>;
	studentsList$;
	isMobile = false;
	currentUser$: Observable<any>;
	isTab = false;
	addMemberRemoveCohort = environment.addMemberRemoveCohort;

	constructor(
		private assignmentService: AssignmentService,
		private router: Router,
		private learningService: LearningService,
		public modalController: ModalController,
		private allAssignmentsService: AllAssignmentsService,
		private rudderStack: RudderStack,
	) {}

	public ngOnInit(): void {
		this.checkScreenSize();
		this.allAssignmentsService.loadOtherAssignmentsInit(this.data.cohortId);
		this.learningService.loadStudentsInit(this.data.cohortId);
		this.learningService.loadStudentsAPI(this.data.cohortId).subscribe();

		this.assignmentDetails$ = this.assignmentService.getAssignmentDetailData(
			this.data.cohortId,
			this.data.id,
		);

		this.assignmentService
			.getAssignmentDetailAPI(this.data.cohortId, this.data.id)
			.subscribe();

		this.studentsList$ = this.learningService
			.loadStudentsData(this.data.cohortId)
			.pipe(
				filter(({ isSkeleton }) => !isSkeleton),
				map(({ data }: any) => {
					const loggedUserGroupId = data.filter(
						({ email }) => email === firebase.auth().currentUser?.email,
					)[0].groupId;
					return data.filter(({ groupId }) => groupId === loggedUserGroupId);
				}),
			);
		this.currentUser$ = this.learningService
			.loadStudentsData(this.data.cohortId)
			.pipe(
				filter(({ isSkeleton }) => !isSkeleton),
				map(({ data }: any): any => {
					return data.filter(
						({ email }) => email === firebase.auth().currentUser?.email,
					);
				}),
			);
	}

	public readPrompt(url) {
		window.open(url, '_blank');

		this.rudderStack.log({
			type: 'assignment_read_prompt',
			cohortId: this.data.cohortId,
			assignmentId: this.data.id,
			name: this.data.name,
			url,
		});
	}

	public membersUpdate(members, { id, assignmentGroupId }) {
		this.rudderStack.log({
			type: 'assignment_update_members',
			cohortId: this.data.cohortId,
			assignmentId: id,
			assignmentGroupId,
			members: members.map(({ email }) => email),
		});

		this.assignmentService
			.assignmentMemberAdd(
				id,
				assignmentGroupId,
				members.map(({ email }) => email),
			)
			.subscribe(() => {
				this.reloadData();
			});
	}

	public leaveGroup({ id, assignmentGroupId, members }) {
		this.rudderStack.log({
			type: 'assignment_update_members',
			cohortId: this.data.cohortId,
			assignmentId: id,
			assignmentGroupId,
			members: members.map(({ email }) => email),
		});
		this.assignmentService
			.assignmentMemberAdd(
				id,
				assignmentGroupId,
				members
					.filter(({ email }) => email !== firebase.auth().currentUser?.email)
					.map(({ email }) => email),
			)
			.subscribe(() => {
				this.reloadData();
			});
	}

	public mapId(ids) {
		return ids.map(({ id }) => id);
	}

	public viewAssignment() {
		this.rudderStack.log({
			type: 'assignment_view_click',
			cohortId: this.data.cohortId,
			assignmentId: this.data.id,
			name: this.data.name,
		});

		this.router.navigate([
			`/app/learning/${this.data.cohortId}/assignments/assignment-id/${this.data.id}`,
		]);
	}

	public getInspired() {
		this.rudderStack.log({
			type: 'assignment_get_inspired',
			cohortId: this.data.cohortId,
			assignmentId: this.data.id,
			name: this.data.name,
		});
		this.allAssignmentsService.loadMoreOtherAssignmentsSetFilter(
			this.data.cohortId,
			{
				assignment: this.data.id,
				group: false,
				studentId: false,
			},
		);

		this.router.navigate([
			`/app/learning/${this.data.cohortId}/assignments/other`,
		]);
	}

	public reloadData() {
		this.assignmentService.getAssignmentDetailReset(
			this.data.cohortId,
			this.data.id,
		);
		this.assignmentService
			.getAssignmentDetailAPI(this.data.cohortId, this.data.id)
			.subscribe(() => {
				/**
				 * empty
				 */
			});
	}

	public async openAddMemberModal() {
		this.studentsList$.pipe(first()).subscribe(studentList => {
			this.assignmentDetails$.pipe(first()).subscribe(async assignment => {
				const modal = await this.modalController.create({
					component: AddMemberModalComponent,
					cssClass: 'add-group-member-modal-class',
					componentProps: {
						studentList,
						assignmentGroup: assignment.data[0],
						cohortId: this.data.cohortId,
					},
				});
				modal.onDidDismiss().then(flag => {
					const data = flag;
					if (data.data && data.data.flag === true) {
						this.reloadData();
						this.assignmentService
							.getAssignmentDetailAPI(this.data.cohortId, this.data.id)
							.subscribe();
					}
				});
				modal.style.cssText = `--max-width: 690px;--min-width: 690px;`;
				return await modal.present();
			});
		});
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		this.checkScreenSize();
	}

	checkScreenSize() {
		this.isMobile = window.innerWidth <= 767;
		this.isTab = window.innerWidth <= 992;
	}
}
