import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	HostListener,
} from '@angular/core';
import {
	ActivatedRoute,
	NavigationEnd,
	NavigationStart,
	Router,
} from '@angular/router';
import { Platform, PopoverController } from '@ionic/angular';
import { filter, map } from 'rxjs/operators';
import { ProfileService } from 'src/app/pages/app/profile/services/profile.service';
import { RudderStack } from 'src/app/services';
import { ProgressBarModalComponent } from '../progress-bar-modal/progress-bar-modal.component';

@Component({
	selector: 'app-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit, OnChanges {
	@Input() barAnalytics: any;
	@Input() overDueAssignments: any;
	@Input() text: any;
	@Input() percentage: any;
	@Input() certificate: any;
	cohortId;
	isonDemand: boolean = false
	router$ = this.activatedRoute.params
		.pipe(
			map(p => Number(p.cohortId)),
			filter(p => !!p),
		)
		.subscribe(cohortId => {
			this.cohortId = cohortId;
		});
	expand = false;
	bgGradient: string;

	isTab = false;
	isMobile = false;
	mobileText = '';

	constructor(
		private router: Router,
		private rudderStack: RudderStack,
		private activatedRoute: ActivatedRoute,
		private profileService: ProfileService,
		private popoverController: PopoverController,
		public platform: Platform,
	) {}

	ngOnInit() {
		this.checkScreenSize();
		this.isonDemand = this.router.url.includes('/course');
		this.initializeValuesAndCss();

		this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe(() => {
				this.dismissPopover();
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.initializeValuesAndCss();
	}

	initializeValuesAndCss() {
		const textArr = this.text.trim().split(' ');

		if (this.certificate) {
			this.mobileText = `${this.percentage}% done! ${
				textArr[textArr.length - 1]
			}`;
		} else {
			this.mobileText = `${this.percentage}% completed ${
				textArr[textArr.length - 1]
			}`;
		}
		// this.bgGradient = `linear-gradient(to right, #38ae8b 0%, #38ae8b ${this.percentage}, #f0fdfa ${this.percentage}, #f0fdfa 100%)`;

		if (this.percentage === 0) {
			this.bgGradient = `linear-gradient(to right, #38ae8b 0%, #38ae8b 3%, #f0fdfa 3%, #f0fdfa 100%)`;
		} else {
			this.bgGradient = `linear-gradient(to right, #38ae8b 0%, 
				#38ae8b ${this.percentage}%, #f0fdfa ${this.percentage}%, #f0fdfa 100%)`;
		}
	}

	goToAssignment(id, cohortId, data) {
		this.rudderStack.log({
			type: 'attention_item_click',
			cohortId: this.cohortId,
			eventType: data.type,
			name: data.name,
		});
		this.router.navigate([
			`/app/learning/${cohortId}/assignments/assignment-id/${id}`,
		]);
		//
	}

	readPrompt(id, cohortId, data) {
		window.open(data.link, '_blank');

		this.rudderStack.log({
			type: 'assignment_read_prompt',
			cohortId: this.cohortId,
			assignmentId: id,
			name: data.name,
			url: data.link,
		});
	}

	expandProgressBar() {
		this.expand = !this.expand;
		if (this.expand) {
			this.rudderStack.log({
				type: 'progresscard_click',
				cohortId: this.cohortId,
			});
		}
	}

	handleClick(event: Event) {
		if (!this.isonDemand) {
		  this.signInRequired();
		  event.stopPropagation();
		}
	  }
	checkScreenSize() {
		this.isMobile = window.innerWidth <= 767;
		this.isTab = window.innerWidth <= 992;
	}

	async signInRequired() {
		const modal = await this.popoverController.create({
			component: ProgressBarModalComponent,
			componentProps: {
				barAnalytics: this.barAnalytics,
				overDueAssignments: this.overDueAssignments,
				text: this.text,
				percentage: this.percentage,
				certificate: this.certificate,
			},
			cssClass: 'setModal',
		});

		modal.style.cssText = `--min-width:100%; --height:auto;`;

		return await modal.present();
	}

	async dismissPopover() {
		const popover = await this.popoverController.getTop();

		if (popover) {
			await popover.dismiss();
		}
	}
}
