import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import {
	ImagePreloadDirectiveModule,
	SelectionDirectiveModule,
	TooltipDirectiveModule,
} from 'src/app/directives/index';
import { AddMemberModalModule } from '../../add-member-modal/add-member-modal.module';
import { AssignmentComponent } from './assignment.component';
import { AssignmentService } from './services/assignment.service';
import { Readmore2Module } from '../../readmore2/readmore2.module';

@NgModule({
	declarations: [AssignmentComponent],
	imports: [
		CommonModule,
		ImagePreloadDirectiveModule,
		TooltipDirectiveModule,
		SelectionDirectiveModule,
		AddMemberModalModule,
		IonicModule,
		Readmore2Module,
	],
	exports: [AssignmentComponent],
	providers: [AssignmentService],
})
export class AssignmentComponentModule {}
