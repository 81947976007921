/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import isBefore from 'date-fns/isBefore';
import isWithinInterval from 'date-fns/isWithinInterval';
import { Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { ProfileService } from 'src/app/pages/app/profile/services/profile.service';
import { AuthService, RudderStack } from 'src/app/services';
import { menuChange, sidebarMenuChange } from './redux/sidebar.actions';
import { SideMenus } from './redux/sidebar.reducer';
import { selectSidebar, selectSidebarMenus } from './redux/sidebar.selectors';
import { SidebarService } from './service/sidebar.service';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
	@Input() routerURL: any;

	sideMenus: any = {
		highLights: 'highlights',
		mySchedule: 'my-schedule',
		assignments: 'assignments',
		courseInfo: 'course-info',
		resources: 'resources',
		myNotes: 'my-notes',
	};
	selected: any;

	sidebar$: Observable<any> = this.store.select(selectSidebar);

	sidebarMenu$: Observable<any> = this.store.select(selectSidebarMenus);

	profile$: Observable<any> = this.profileService.loadProfileData();

	toggle = false;

	item = SideMenus.highLights;

	locationPath: Array<string | any> = this.location.path().split('/');

	class = 'people-directory';

	isSidebarFellow$: Observable<any> = this.profile$
		.pipe(filter(({ data }: any) => data && Object.keys(data).length > 0))
		.pipe(map(({ data }: any) => data))
		.pipe(
			map(data => {
				return data && data.roles && data.roles.length > 0
					? data.roles.filter(({ name }) =>
							['Fellow', 'Terra Team', 'Instructor', 'Terra Grow'].includes(
								name,
							),
					  ).length > 0
					: false;
			}),
			tap(bool => {
				if (
					bool === false &&
					(this.sidebarService.activeClass === 'terra-fellows' ||
						this.sidebarService.activeClass === 'mentors')
				) {
					this.router.navigate([`/app/profile/people-directory`]);

					this.sidebarService.activeClass = 'people-directory';
				}
			}),
		);

	constructor(
		private store: Store<any>,
		private router: Router,
		private location: Location,
		public activatedRoute: ActivatedRoute,
		public modalController: ModalController,
		public popoverController: PopoverController,
		private profileService: ProfileService,
		private rudderStack: RudderStack,
		private authService: AuthService,
		public sidebarService: SidebarService,
	) {
		this.learning();
	}

	async ngOnInit() {}

	async learning() {
		const arr = this.location.path().split('/');
		const cohortId = Number(arr[3]);
		if (cohortId) {
			this.store.dispatch(menuChange({ cohortId }));
		}

		this.sidebar$
			.pipe(
				filter(menu => !menu.isSkeleton),
				first(),
			)
			.subscribe(menu => {
				if (menu.data) {
					if (menu.data[0]) {
						const arr: any = this.location.path().split('/');

						const currentTab = arr[4];

						switch (currentTab) {
							case 'my-schedule':
								this.store.dispatch(
									sidebarMenuChange({ status: SideMenus.mySchedule }),
								);
								this.item = SideMenus.mySchedule;
								break;
							case 'assignments':
								this.store.dispatch(
									sidebarMenuChange({ status: SideMenus.assignments }),
								);
								this.item = SideMenus.assignments;
								break;
							case 'course-info':
								this.store.dispatch(
									sidebarMenuChange({ status: SideMenus.courseInfo }),
								);
								this.item = SideMenus.courseInfo;
								break;
							case 'resources':
								this.store.dispatch(
									sidebarMenuChange({ status: SideMenus.resources }),
								);
								this.item = SideMenus.resources;
								break;
							case 'my-notes':
								this.store.dispatch(
									sidebarMenuChange({ status: SideMenus.myNotes }),
								);
								this.item = SideMenus.myNotes;
								break;
							case 'highLights':
								this.store.dispatch(
									sidebarMenuChange({ status: SideMenus.highLights }),
								);
								this.item = SideMenus.highLights;
								break;
							default:
								this.store.dispatch(
									sidebarMenuChange({ status: SideMenus.highLights }),
								);
								this.item = SideMenus.highLights;
								break;
						}
					}
				}
			});
	}

	selectedItem(item: SideMenus, cohortId: number) {
		this.rudderStack.log({
			type: 'sidemenu_click',
			cohortId: cohortId,
			menuButtonName: item,
		});
		if (item === SideMenus.mySchedule || !item) {
			this.router.navigate([`/app/learning/${cohortId}/my-schedule`]);
		} else if (item === SideMenus.assignments) {
			this.router.navigate([`/app/learning/${cohortId}/assignments`]);
		} else if (item === SideMenus.courseInfo) {
			this.router.navigate([`/app/learning/${cohortId}/course-info`]);
		} else if (item === SideMenus.resources) {
			this.router.navigate([`/app/learning/${cohortId}/resources`]);
		} else if (item === SideMenus.myNotes) {
			this.router.navigate([`/app/learning/${cohortId}/my-notes`]);
		} else if (item === SideMenus.highLights) {
			this.router.navigate([`/app/learning/${cohortId}/highlights`]);
		}

		this.item = item;
		this.store.dispatch(sidebarMenuChange({ status: item }));
	}

	toggleDiv() {
		this.toggle = !this.toggle;
	}

	setSelectedCohort(cohort: { cohortId: number }) {
		this.toggle = false;
		this.store.dispatch(menuChange({ cohortId: cohort.cohortId }));
		this.selectedItem(this.item, cohort.cohortId);
	}

	gotoProfile() {
		this.rudderStack.log({
			type: 'profile_view_own',
			extra: 'me',
		});
		this.router.navigate([`/app/profile/view/me`]);
	}

	switchMenu(view: string) {
		this.rudderStack.log({
			type: 'Click on left sections',
			currentSelection: this.sidebarService.activeClass,
			clickedSelection: view,
		});
		this.sidebarService.activeClass = view;
		this.router.navigate([`/app/profile/${view}`]);
	}

	requestExtension() {
		this.rudderStack.log({
			type: 'Request Extension',
			currentSelection: this.sidebarService.activeClass,
		});

		window.open('mailto:mentors@terra.do', '_blank');
	}

	learnMore() {
		this.rudderStack.log({
			type: 'Learn more',
			currentSelection: this.sidebarService.activeClass,
		});

		window.open(
			'https://terra-dot-do.notion.site/Terra-do-Mentorship-Program-Guidelines-for-Fellows-096f12539baa4242966c5bcc77117f8d',
			'_blank',
		);
	}

	switchUrl(url) {
		this.rudderStack.log({
			type: 'Learn how it works',
			currentSelection: url,
		});

		window.open(url, '_blank');
	}
}
