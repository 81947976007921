import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from './progress-bar.component';
import { PieChartModule } from '../pie-chart/pie-chart.module';
import { IonicModule } from '@ionic/angular';
import { ProgressBarModalComponent } from '../progress-bar-modal/progress-bar-modal.component';
import { ProgressBarModalModule } from '../progress-bar-modal/progress-bar-modal.module';

@NgModule({
	declarations: [ProgressBarComponent],
	imports: [CommonModule, PieChartModule, IonicModule, ProgressBarModalModule],
	exports: [ProgressBarComponent],
})
export class ProgressBarModule {}
