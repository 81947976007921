import { Component, Input, OnInit } from '@angular/core';
import { ChartOptions } from 'chart.js';

@Component({
	selector: 'app-pie-chart',
	templateUrl: './pie-chart.component.html',
	styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit {
	@Input() type;
	@Input() starting = 0;
	@Input() ending = 0;
	@Input() mobile = false;
	title = 'ng2-charts-demo';
	size = '120';
	// Pie
	public pieChartOptions: ChartOptions<'pie'> = {
		responsive: false,
		plugins: {
			legend: {
				display: false,
			},
		},
	};

	toolTipText: string;
	content: string;
	pieChartDatasets: {
		data: number[];
		backgroundColor: string[];
		hoverOffset: number;
	}[];
	constructor() {}

	ngOnInit() {
		this.content = this.starting + ' of ' + this.ending;
		if (this.content) {
			this.pieChartDatasets = [
				{
					data: [this.starting, this.ending - this.starting],
					backgroundColor: ['#FFCB2D', 'rgb(255 203 45 / 65%)'],
					hoverOffset: 4,
				},
			];
		}
		this.toolTipText = 'We count only the mandatory events here.';
	}
}
