/* eslint-disable @ngrx/no-dispatch-in-effects */
/* eslint-disable @ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, filter, map, tap } from 'rxjs/operators';
import { InterfaceAPIStatus } from 'src/app/model/loader';
import { SidebarService } from '../service/sidebar.service';
import * as SidebarActions from './sidebar.actions';
import { SideMenus } from './sidebar.reducer';
import { selectApiStatus } from './sidebar.selectors';
import { Location } from '@angular/common';

@Injectable()
export class SidebarEffects {
	loadSidebars$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SidebarActions.loadSidebars),
			concatLatestFrom(() => this.store.select(selectApiStatus)),
			filter(([, apiStatus]) => apiStatus === InterfaceAPIStatus.none),
			tap(() =>
				this.store.dispatch(
					SidebarActions.loadSidebarsApiStatus({
						status: InterfaceAPIStatus.start,
					}),
				),
			),
			concatMap(() => this.sidebarService.loadSideBar()),
			map(data =>
				data.map(record => ({
					title: record.course.name,
					description: record.course.description,
					cohortId: record.cohortId,
					courseId: record.courseId,
					properties: record.properties,
					isMySchedule: true,
					isAssignments: true,
					isCourseInfo: true,
					isResources: true,
					isMyNotes: true,
					isHighLights: true,
					defaultMenu: this.location.path().split('/')[4],
					startTime: record.startTime,
					studentId: record.studentId,
				})),
			),
			map(data => SidebarActions.loadSidebarsSuccess({ data })),
		),
	);
	constructor(
		private actions$: Actions,
		private sidebarService: SidebarService,
		private store: Store,
		private location: Location
	) {}
}
