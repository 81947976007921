import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-roles',
	templateUrl: './roles.component.html',
	styleUrls: ['./roles.component.scss'],
})
export class RolesComponent implements OnInit {
	roles: any = [];

	@Input() data: any = [];
	@Input() view: any;
	constructor() {}

	ngOnInit(): void {
		this.roles = this.rolesCondition(this.data);
	}

	rolesCondition(arr = []) {
		let tempArray = arr
			.filter((v, i, a) => a.map(({ name }) => name).indexOf(v.name) === i)
			.map(single => {
				let className: any = false;
				switch (single.name.toLocaleLowerCase()) {
					// case 'mentor':
					// 	className = 'tag-mentor';
					// 	break;
					case 'fellow':
						className = 'tag-fellow';
						break;
					case 'climate expert':
						className = 'tag-climate-expert';
						break;
					case 'creator':
					case 'course creator':
						className = 'tag-creator';
						break;
					case 'instructor':
						className = 'tag-instructor';
						break;
					// case 'opendoorclimate':
					// 	className = 'tag-opendoorclimate';
					// 	break;
					case 'special guest':
						className = 'tag-special-guest';
						break;
					case 'terra team':
						className = 'tag-terra-team';
						break;
					case 'community ambassador':
						className = 'tag-community-ambassador';
						break;
					case 'candidate':
					case 'ipeditor':
					case 'huddle':
						break;
				}
				return {
					...single,
					className,
				};
			})
			.filter(single => single.className);
		if (this.view === 'small') {
			if (tempArray?.length > 2) {
				let str: any = tempArray.length - 2;
				str = str.toString();
				tempArray = tempArray.slice(0, 2);
				tempArray.push({
					name: `+${str}`,
					id: 2,
					className: 'tag-creator',
				});
			}
		}
		return tempArray;
	}
}
