import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import firebase from 'firebase/compat/app';
import { CookieService } from 'ngx-cookie-service';
import { Observable, UnaryFunction, of, pipe } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export type AuthPipeGenerator = (
	next: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
) => AuthPipe;
export type AuthPipe = UnaryFunction<
	Observable<firebase.User | null>,
	Observable<boolean | string | any[]>
>;

export const loggedIn: AuthPipe = map(user => !!user);

@Injectable({
	providedIn: 'any',
})
export class AngularFireAuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private auth: AngularFireAuth,
		private cookieService: CookieService,
	) {}

	canActivate = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
		const authPipeFactory =
			(next.data.authGuardPipe as AuthPipeGenerator) ||
			(() => {
				return loggedIn;
			});
		return this.auth.user.pipe(
			take(1),
			switchMap(data => {
				return new Promise(resolve => {
					const earth_token = this.cookieService.get('earth_token');
					const welcome_user = this.cookieService.get('welcome_user');

					if (earth_token && !welcome_user) {
						const url =
							environment.url.onboarding +
							'authorize' +
							'/?r=' +
							window.location.href;
						window.location.href = url;
					} else if (!data) {
						if (earth_token) {
							this.auth
								.signInWithCustomToken(earth_token)
								.then(userObj => {
									resolve(userObj.user);
								})
								.catch(e => {
									if (
										e.code === 'auth/custom-token-mismatch' ||
										e.code === 'auth/invalid-custom-token'
									) {
										const url =
											environment.url.onboarding +
											'authorize' +
											'/?r=' +
											window.location.href;
										window.location.href = url;
									}
								});
						} else {
							resolve(data);
						}
					} else {
						if (!environment.welcomeRedirect) {
							resolve(data);
						} else {
							if (data.uid !== welcome_user) {
								this.auth
									.signOut()
									.then(() => {
										resolve(null);
									})
									.catch((_a: any) => {
										const url =
											environment.url.onboarding +
											'authorize' +
											'/?r=' +
											window.location.href;
										window.location.href = url;
									});
							} else {
								resolve(data);
							}
						}
					}
				});
			}),
			authPipeFactory(next, state),
			map(can => {
				if (typeof can === 'boolean') {
					return can;
				} else if (Array.isArray(can)) {
					if (can[0] === '/auth/login') {
						localStorage.setItem('redirectTo', window.location.href);
					}

					if (window.location.hash) {
						return this.router.createUrlTree(can, {
							fragment: window.location.hash.replace('#', ''),
						});
					} else if (location.search) {
						return this.router.createUrlTree(can, {
							queryParams: location.search
								.slice(1)
								.split('&')
								.map(p => p.split('='))
								.reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
						});
					} else {
						return this.router.createUrlTree(can);
					}
				} else {
					if (can === '/auth/login') {
						const url =
							environment.url.onboarding +
							'on_boarding_done' +
							'/?r=' +
							window.location.href;
						window.location.href = url;

						return false;
					} else {
						// TODO(EdricChan03): Add tests
						return this.router.parseUrl(can);
					}
				}
			}),
		);
	};
}

export const canActivate = (pipe: AuthPipeGenerator) => ({
	canActivate: [AngularFireAuthGuard],
	data: { authGuardPipe: pipe },
});

export const isNotAnonymous: AuthPipe = map(
	user => !!user && !user.isAnonymous,
);
export const idTokenResult = switchMap((user: firebase.User | null) =>
	user ? user.getIdTokenResult() : of(null),
);
export const emailVerified: AuthPipe = map(
	user => !!user && user.emailVerified,
);
export const customClaims = pipe(
	idTokenResult,
	map(idTokenResult => (idTokenResult ? idTokenResult.claims : [])),
);
export const hasCustomClaim: (claim: string) => AuthPipe =
	// eslint-disable-next-line no-prototype-builtins
	claim =>
		pipe(
			customClaims,
			map(claims => claims.hasOwnProperty(claim)),
		);
export const redirectUnauthorizedTo: (
	redirect: string | any[],
) => AuthPipe = redirect =>
	pipe(
		loggedIn,
		map(loggedIn => loggedIn || redirect),
	);
export const redirectLoggedInTo: (
	redirect: string | any[],
) => AuthPipe = redirect =>
	pipe(
		loggedIn,
		map(loggedIn => (loggedIn && redirect) || true),
	);
