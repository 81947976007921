import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import BrowserInfo from '@smartbear/browser-info';
import firebase from 'firebase/compat/app';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class RudderStack {
	public sessionId = '';
	public email: any = '';
	currentProfileId = 0;
	currentCompanyId = 0;
	cohortId = 0;
	isGrow = false;
	public b2b = 'b2c';

	public os: any = {};

	skipRudderStack = true;

	constructor(private activatedRoute: ActivatedRoute) {
		this.init();
		BrowserInfo.detect();

		this.os = {
			name: BrowserInfo.name,
			release: BrowserInfo.release,
			version: BrowserInfo.version,
			os: BrowserInfo.os,
			agent: navigator.userAgent,
		};
	}

	public init() {
		this.sessionId = this.getUUID();
		this.email = firebase.auth().currentUser?.email;

		this.activatedRoute.params
			.pipe(map(p => Number(p.cohortId)))
			.subscribe(cohort => (this.cohortId = cohort));
	}

	public identify(email, messageJSON, cb = () => {}) {
		this.init();

		window['rudderanalytics'].ready(() => {
			this.skipRudderStack = false;
		});

		window['rudderanalytics'].identify(email, messageJSON, () => {
			// block
			console.log('identify call', email, messageJSON);

			if (!this.skipRudderStack) {
				cb();
			}
		});
		if (this.skipRudderStack) {
			cb();
		}
	}

	public reset(cb = () => {}) {
		window['rudderanalytics'].reset(true);
	}

	public log(messageJSON, cb = () => {}) {
		messageJSON['app'] = 'Learn-V2';
		messageJSON['email'] = this.email;
		messageJSON['sessionId'] = this.sessionId;
		messageJSON['clientTimestamp'] = new Date().getTime();
		messageJSON['page'] = location.pathname;
		if (this.currentProfileId) {
			messageJSON['profileIdInt'] = this.currentProfileId;
			messageJSON['profileId'] = String(this.currentProfileId);
		}

		if (this.cohortId) {
			messageJSON['cohortId'] = this.cohortId;
		}

		if (this.b2b) {
			messageJSON['organization'] = this.b2b;
		}

		messageJSON['t_os'] = this.os;
		messageJSON['web_version'] = 'web_2.0';

		window['rudderanalytics'].track(messageJSON.type, messageJSON, () => {
			// block

			if (!this.skipRudderStack) {
				cb();
			}
		});
		if (this.skipRudderStack) {
			cb();
		}
	}

	public getUUID() {
		const length = 16;
		let result = '';
		const characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}
}
