import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileService } from 'src/app/pages/app/profile/services/profile.service';
import { AuthService, RudderStack } from 'src/app/services';
import { ConfirmationPopupComponent } from '../../confirmation-popup/confirmation-popup.component';
import { PasswordComponent } from '../password/password.component';
import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { PublicPageService } from '../../../services/public-page/public-page.service';

@Component({
	selector: 'app-logout-tab',
	templateUrl: './logout-tab.component.html',
	styleUrls: ['./logout-tab.component.scss'],
})
export class LogoutTabComponent implements OnInit {
	profile$: Observable<any> = this.profileService.loadProfileData();
	isNotB2B$: Observable<boolean> = this.authService.isB2B$.pipe(map(d => !d));
	userName: any;
	constructor(
		private profileService: ProfileService,
		public authService: AuthService,
		public popoverController: PopoverController,
		private router: Router,
		private rudderStack: RudderStack,
		private modalController: ModalController,
		private db: AngularFirestore,
		private publicPageService: PublicPageService,
	) {
		// test
	}

	public ngOnInit() {
		// test
		this.profile$.subscribe(profile => {
			this.userName = profile.data.email.split('@')[0];
			//  let email = `${profile.data.email}@${userName[0]}.terra.do`
			// console.log(this.userName,"username")
		});
		// this.isNotB2B$.subscribe(data=>{console.log(data,"checking")})
	}

	public logout() {
		this.popoverController.dismiss();

		this.router.navigate([`/logout`]);
	}

	gotoProfile(profileId) {
		this.popoverController.dismiss();
		this.rudderStack.log({
			type: 'profile_view_own',
			profileId,
			extra: 'me',
		});
		// this.router.navigate([`/app/profile/view/me`]);
		this.menuUpdate();
	}

	async showModal() {
		this.rudderStack.log({
			type: 'change_password_tap',
		});

		this.popoverController.dismiss();
		const modal = await this.modalController.create({
			component: PasswordComponent,
			cssClass: 'password-modal-class',
		});
		modal.style.cssText = `--height:auto`;
		return await modal.present();
	}

	async myOrder() {
		this.rudderStack.log({
			type: 'my_order_click',
		});

		this.popoverController.dismiss();

		this.router.navigate([`/app/orders`]);
	}

	menuUpdate() {
		const currentUser = firebase.auth().currentUser;
		if (currentUser) {
			this.db
				.doc('users/' + currentUser.uid)
				.valueChanges()
				.subscribe((data: any) => {
					if (data.USER_QUESTION_AND_ANSWER === '1') {
						this.router.navigate([`/app/profile/view/me`]);
					} else {
						this.presentWelcomeRedirect();
					}
				});
		}
	}

	public async presentWelcomeRedirect() {
		const modal = await this.popoverController.create({
			component: ConfirmationPopupComponent,
			cssClass: 'logout-modal-class',
			componentProps: {
				heading:
					'Please tell us a bit about yourself to access all features and services offered by Terra.do.',
				subHeading:
					'Answer a few questions about your background and interests (~5 mins)',
				imageUrl: 'assets/onboarding-popup.png',
			},
		});

		if (window.innerWidth < 480) {
			modal.style.cssText = `--max-width: 540px;--min-width: calc(100% - 25px);`;
		} else {
			modal.style.cssText = `--max-width: 540px;--min-width: 480px;`;
		}
		await modal.present();

		const { data } = await modal.onDidDismiss();
		if (data) {
			// localStorage.setItem('redirectTo', `/app/profile/view/me`);

			this.publicPageService.loggedWithOnboarding(
				location.origin + '/app/profile/view/me',
			);

			// window.open(environment.url.onboarding, '_self');
		}
	}
}
