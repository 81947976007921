import {
	Directive,
	EventEmitter,
	HostListener,
	Input,
	Output,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlertBoxComponent } from './alert-box/alert-box.component';

@Directive({
	selector: '[appConfirmBox]',
})
export class ConfirmBoxDirective {
	@Input() title: string;
	@Input() subTitle: string;

	@Output() learnDelete = new EventEmitter<any>();

	constructor(public modalController: ModalController) {}

	@HostListener('click', ['$event.target'])
	async openImageModal() {
		const modal = await this.modalController.create({
			component: AlertBoxComponent,
			cssClass: 'confirm-box',
			backdropDismiss: true,
			componentProps: {
				title: this.title,
				subTitle: this.subTitle,
			},
		});

		modal.onDidDismiss();

		await modal.present();

		const { data } = await modal.onDidDismiss();

		if (data?.isDelete) {
			this.learnDelete.emit(true);
		}
	}
}
