import { NgModule } from '@angular/core';
import {
	AngularFireAuthGuard,
	redirectLoggedInTo,
	redirectUnauthorizedTo,
} from './auth-guard/auth-guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuardV2 } from './auth-guard-v2/auth-guard-v2';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo('/auth/login');

const redirectUnauthorizedToLoginApp = () => {
	if (window.location.href.indexOf('?') > -1) {
		localStorage.setItem('redirectTo', window.location.href);
		return redirectUnauthorizedTo('/auth/login');
	} else {
		return redirectUnauthorizedTo([
			window.location.pathname.replace('/app', ''),
		]);
	}
};

const redirectLoggedInToItems = () => redirectLoggedInTo('/app');

function getCookie(cname) {
	let name = cname + '=';
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

const redirectLoggedInToItemsApp = () => {
	if (window.location.pathname === '/' && !getCookie('welcome_user')) {
		return redirectUnauthorizedTo('/auth/login');
	}

	return redirectLoggedInTo(['/app' + window.location.pathname]);
};

const routes: Routes = [
	{
		path: 'grow',
		loadChildren: () =>
			import('./grow/grow.module').then(m => m.GrowPageModule),
	},
	{
		path: '',
		loadChildren: () =>
			import('./pages/app/app.module').then(m => m.AppPageModule),
		canActivate: [AngularFireAuthGuardV2],
	},

	{
		path: 'join-the-community',
		redirectTo: 'auth/login',
		pathMatch: 'full',
	},
	{
		path: 'sign_up_consent',
		redirectTo: 'auth/login',
		pathMatch: 'full',
	},
	{
		path: 'auth',
		loadChildren: () =>
			import('./pages/auth/auth.module').then(m => m.AuthPageModule),
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectLoggedInToItems },
	},
	{
		path: 'app',
		loadChildren: () =>
			import('./pages/app/app.module').then(m => m.AppPageModule),
		// Skip Logic to indivisual page for public page
		canActivate: [AngularFireAuthGuardV2],
	},
	{
		path: 'join/course-event/:id',
		loadChildren: () =>
			import('./pages/join/join.module').then(m => m.JoinPageModule),
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
	},
	{
		path: 'join/public-event/:id',
		loadChildren: () =>
			import('./pages/join/join.module').then(m => m.JoinPageModule),
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
	},
	{
		path: 'join/view-recording/:id',
		loadChildren: () =>
			import('./pages/join/join.module').then(m => m.JoinPageModule),
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
	},
	{
		path: 'join/job-fair/:id',
		loadChildren: () =>
			import('./pages/join/join.module').then(m => m.JoinPageModule),
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
	},
	{
		path: 'join/hubbleEvent/:id',
		loadChildren: () =>
			import('./pages/join/join.module').then(m => m.JoinPageModule),
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
	},
	{
		path: 'logout',
		loadChildren: () =>
			import('./pages/logout/logout.module').then(m => m.LogoutPageModule),
	},
	{
		path: 'my_checklist',
		loadChildren: () =>
			import('./pages/checklist/checklist.module').then(
				m => m.ChecklistPageModule,
			),
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
	},
	{
		path: 'form',
		loadChildren: () =>
			import('./pages/form/form.module').then(m => m.FormPageModule),
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
	},
	{
		path: 'form/:id',
		loadChildren: () =>
			import('./pages/form/form.module').then(m => m.FormPageModule),
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
	},
	{
		path: '**',
		redirectTo: 'auth/login',
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
