import { HttpClient, HttpContext } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import isAfter from 'date-fns/isAfter';
import isWithinInterval from 'date-fns/isWithinInterval';
import subMinutes from 'date-fns/subMinutes';
import { BYPASS_LOG } from 'src/app/pages/app/interceptor/interceptor.service';
import { LearningService } from 'src/app/pages/app/learning/service/learning.service';
import { AddToCalendarService, RudderStack } from 'src/app/services/index';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-event',
	templateUrl: './event.component.html',
	styleUrls: ['./event.component.css'],
})
export class EventComponent implements OnInit {
	@Input() data: any;
	@Input() isLock: boolean;
	@Input() status: string;

	isPrev;
	isDone;
	isCurrent;
	isCompleted: any;
	URL: any;
	updatedDescription: any;
	userEmail: any;

	constructor(
		private sanitizer: DomSanitizer,
		public addToCalendarService: AddToCalendarService,
		private rudderStack: RudderStack,
		private http: HttpClient,
		private learningService: LearningService,
	) {}

	ngOnInit(): void {
		this.userEmail = this.rudderStack.email;
		this.isCompleted = !this.data?.viewed;
		this.isPrev = isAfter(new Date(this.data.startDateTime), new Date());

		this.isCurrent = isWithinInterval(new Date(), {
			start: subMinutes(new Date(this.data.startDateTime), 15),
			end: new Date(this.data.endDateTime),
		});

		if (this.isYoutubeUrl(this.data?.recordingLink)) {
			const id = this.getId(this.data?.recordingLink);

			this.URL = id
				? this.sanitizer.bypassSecurityTrustResourceUrl(
						`https://www.youtube.com/embed/${id}`,
				  )
				: null;
		}
		if (!(this.isPrev || this.isCurrent)) {
			this.isDone = isAfter(new Date(), new Date(this.data.endDateTime));
		}

		this.updatedDescription = this.data.description;

		let index = this.data.description?.indexOf('Live:');
		if (index !== -1) {
			this.updatedDescription = this.data.description.slice(0, index);
		}
		index = this.data.description?.indexOf('Recording:');
		if (index !== -1) {
			this.updatedDescription = this.data.description.slice(0, index);
		}

		// this.updatedDescription = this.updatedDescription.slice(
		// 	0,
		// 	this.updatedDescription.indexOf('\n'),
		// );
		this.updatedDescription = this.updatedDescription.trim('\n');

		this.updatedDescription = this.updatedDescription.replace(/\n/g, '<br/>');
	}
	getCalInvite(data: any) {
		const payload = {
			calendarEventId: data.eventId,
			profileId: this.rudderStack.currentProfileId,
		};
		return this.http
			.post(environment.url.cohort + '/calendar/add/guest', payload, {
				context: new HttpContext().set(BYPASS_LOG, true),
			})
			.subscribe(() => {
				this.learningService
					.loadClassAndEventsData(data.cohortId)
					.subscribe((datas: any) => {
						if (datas) {
							const modifiedData = datas.data.map((a: any) => {
								if (a.id === data.id) {
									return { ...a, guestAdded: true };
								}
								return a;
							});
							this.learningService.loadEvents(data.cohortId, modifiedData);
						}
					});
			});
	}

	redirectTo(type, data) {
		if (type === 'course-event') {
			this.rudderStack.log({
				type: 'event_joinevent_click',
				cohortId: data.cohortId,
				eventId: data.eventId,
				name: data.name,
			});
		} else {
			this.rudderStack.log({
				type: 'event_recording_click',
				cohortId: data.cohortId,
				eventId: data.eventId,
				name: data.name,
			});
		}
		window.open('/join/' + type + '/' + data.eventId, '_blank');
	}

	transform(url) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}

	getId(url) {
		const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
		const match = url.match(regExp);

		return match && match[2].length === 11 ? match[2] : null;
	}
	isYoutubeUrl(url) {
		return url?.includes('youtube');
	}
}
