import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	HostListener,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import format from 'date-fns/format';
import { filter, map } from 'rxjs/operators';
import { RatingModalComponent } from '../../rating-modal/rating-modal.component';
import { TextBookService } from 'src/app/services';

@Component({
	selector: 'app-module-card',
	templateUrl: './module-card.component.html',
	styleUrls: ['./module-card.component.scss'],
})
export class ModuleCardComponent implements OnInit, OnChanges {
	@Input() data: any;
	@Input() isLock: any;
	@Input() type: 'class' | 'events' | 'assignments' | 'announcement' = 'class';

	unLockStr = '';
	expandSubStr = '';
	optionCard = false;
	status: 'Locked' | 'Unlocked' | 'In progress' | 'Completed';
	isMobile = false;
	icon: string;
	iconToast: string;
	announcementType = 'cal';
	cohortId: any;
	toolTipText: string;
	cardToolTipText: string;
	constructor(
		private popoverController: PopoverController,
		private store: Store,
		private activatedRoute: ActivatedRoute,
		private textBookService: TextBookService,
	) {}

	ngOnChanges(changes: SimpleChanges) {
		const data = changes.data.currentValue;

		if (this.isLock) {
			this.unLockStr =
				'Unlocks on ' + format(new Date(data.startDateTime), 'dd MMM');

			this.status = 'Locked';
		} else {
			if (data.rate === 0) {
				this.status = 'Unlocked';
				this.cardToolTipText = 'Go to module';
			} else if (data.rate <= 99) {
				this.status = 'In progress';
				const rate = Math.round(parseFloat(`${data.rate}`) * 100) / 100;
				this.cardToolTipText = `Go to module ${rate}% completed`;
			} else if (data.rate <= 100) {
				this.status = 'Completed';
				this.cardToolTipText = 'Completed 🎉';
			}
		}
		// Unlocked on 13 May 2022 • 10:30 PM IST
		// this.expandSubStr =
		// 	'Unlocks on ' +
		// 	format(new Date(data.startDateTime), 'dd MMM yyyy • hh:mm a');
		this.reloadIcon();
	}

	ngOnInit(): void {
		this.checkScreenSize();
		if (this.data.authors?.length > 0) {
			const author1 = this.data.authors[0];
			let date = new Date(this.data.lastSeen);
			if (this.data.authors.length === 1) {
				this.toolTipText = `📝 Created by  ${author1.firstName} ${author1.lastName}}`;
			} else {
				const author2 = this.data.authors[1];
				this.toolTipText = `📝 Created by  ${author1.firstName} ${author1.lastName} and ${author2.firstName} ${author2.lastName}`;
			}
		}
		this.unLockStr = `${this.data.duration} mins`;
		this.activatedRoute.params
			.pipe(
				map(p => Number(p.cohortId)),
				filter(p => !!p),
			)
			.subscribe(cohort => (this.cohortId = cohort));
	}

	reloadIcon() {
		if (this.status === 'Locked') {
			this.icon = 'lock-closed-outline';
			this.iconToast = 'Locked 🔒';
		} else {
			if (this.type === 'class') {
				this.icon = 'document-outline';
			} else if (this.type === 'events') {
				this.icon = 'calendar-outline';
			} else if (this.type === 'assignments') {
				this.icon = 'pencil-outline';
			}

			if (this.status === 'Unlocked') {
				this.iconToast = 'Unlocked 🔑';
			} else if (this.status === 'In progress') {
				this.iconToast = 'In progress ⏳';
			} else if (this.status === 'Completed') {
				this.iconToast = 'Completed 🎉';
			}
		}
		if (this.type === 'announcement') {
			this.icon = 'time-outline';
		}
	}

	announcementAction(str?) {
		// copy link or add to calendar
		if (this.announcementType === 'copy') {
			navigator.clipboard.writeText(str);
		} else if (this.announcementType === 'cal') {
			this.openRatingModal();
		}
	}

	async openRatingModal() {
		const modal = await this.popoverController.create({
			component: RatingModalComponent,
			cssClass: 'addCalendar-modal',
			componentProps: { type: 'cal' },
		});
		return await modal.present();
	}
	expand(event) {
		if (this.status !== 'Locked') {
			if (this.data?.moduleId) {
				this.textBookService.open(this.data?.moduleId);
			}
		}
	}
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		this.checkScreenSize();
	}

	checkScreenSize() {
		this.isMobile = window.innerWidth <= 767; // Adjust the breakpoint as per your needs
	}
	onClick(event: Event): void {
		event.stopPropagation();
	}
}
