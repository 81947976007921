import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ProfileService } from 'src/app/pages/app/profile/services/profile.service';

@Component({
	selector: 'app-notification-dropdown',
	templateUrl: './notification-dropdown.component.html',
	styleUrls: ['./notification-dropdown.component.scss'],
})
export class NotificationDropdownComponent implements OnInit {
	public data: any;
	public settingsView: boolean;
	profile$: Observable<any> = this.profileService.loadProfileData();

	constructor(
		public modalController: ModalController,
		private profileService: ProfileService,
	) {
		// test
	}

	public ngOnInit() {
		this.settingsView = false;
		this.data = [
			{
				type: 'message',
				notification:
					' Lorem ipsum dolor sit amet consectetur adipisicing elit . !',
				read: 'true',
			},
			{
				type: 'email',
				notification:
					' Lorem ipsum dolor sit amet consectetur adipisicing elit . !',
				read: 'false',
			},
			{
				type: 'email',
				notification:
					' Lorem ipsum dolor sit amet consectetur adipisicing elit . !',
				read: 'false',
			},
			{
				type: 'email',
				notification:
					' Lorem ipsum dolor sit amet consectetur adipisicing elit . !',
				read: 'false',
			},
			{
				type: 'like',
				notification:
					' Lorem ipsum dolor sit amet consectetur adipisicing elit abhishu raina raina  . !',
				read: 'false',
			},
			{
				type: 'like',
				notification:
					' Lorem ipsum dolor sit amet consectetur adipisicing elit . !',
				read: 'false',
			},
			{
				type: 'like',
				notification:
					' Lorem ipsum dolor sit amet consectetur adipisicing elit . !',
				read: 'true',
			},
		];
	}
	public openNotificationSettings() {
		this.settingsView = true;
	}
	public backToNotifications() {
		this.settingsView = false;
	}
}
