import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import firebase from 'firebase/compat/app';
import { RudderStack } from 'src/app/services';

import { AuthService } from 'src/app/services';
import { AssignmentService } from '../cards/assignment/services/assignment.service';

@Component({
	selector: 'app-add-member-modal',
	templateUrl: './add-member-modal.component.html',
	styleUrls: ['./add-member-modal.component.scss'],
})
export class AddMemberModalComponent implements OnInit, OnDestroy {
	@Input() public studentList: any;
	@Input() public assignmentGroup;
	@Input() public cohortId;

	public searchedList;
	public selectedList;
	public currentUser;
	public inputFocus = false;
	public searchValue = '';
	constructor(
		public modalController: ModalController,
		private assignmentService: AssignmentService,
		private rudderStack: RudderStack,
		private authService: AuthService,
	) {}

	public ngOnInit() {
		this.currentUser = firebase.auth().currentUser.providerData[0];
		this.selectedList = [];
		const membersList = this.assignmentGroup.members;
		this.studentList = this.studentList.map(st => ({
			...st,
			selected: st.email === this.currentUser.email ? true : false,
		}));
		membersList.map(member => {
			this.studentList = this.studentList.map(student => {
				if (member.email === student.email) {
					student.selected = true;
				}

				return { ...student };
			});
		});
		this.searchedList = [...this.studentList];
	}
	public async closeModal(flag) {
		await this.modalController.dismiss({
			flag: flag,
		});
	}

	public selectMember(member) {
		if (member.email === this.currentUser.email) {
			return;
		}
		member.selected = !member.selected;
		if (member.selected === true) {
			this.selectedList.push(member);
		} else {
			this.selectedList.filter(mb => {
				if (mb.selected === true) {
					return mb;
				}
			});
		}
	}

	public membersUpdate() {
		const members = this.searchedList
			.filter(member => {
				if (member.selected === true) {
					return member.email;
				}
			})
			.map(member => {
				return member.email;
			});
		const memberIds = this.searchedList
			.filter(member => {
				if (member.selected === true) {
					return member.id;
				}
			})
			.map(member => {
				return member.id;
			});
		this.rudderStack.log({
			type: 'assignment_add_members',
			cohortId: this.cohortId,
			assignmentId: this.assignmentGroup.assignmentGroupId,
			memberIds: memberIds,
		});
		this.assignmentService
			.assignmentMemberAdd(
				this.assignmentGroup.id,
				this.assignmentGroup.assignmentGroupId,
				members,
			)
			.subscribe(a => {
				if (!a.error) {
					this.closeModal(true);
				}
			});
	}
	public closeSearch() {
		this.inputFocus = false;
	}
	public ngOnDestroy(): void {
		this.searchedList = [];
		this.selectedList = [];
	}
}
