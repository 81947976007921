import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReferralService } from './service/referral.service';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { ProfileService } from 'src/app/pages/app/profile/services/profile.service';
import { AuthService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { RudderStack } from 'src/app/services';

@Component({
	selector: 'app-referral',
	templateUrl: './referral.component.html',
	styleUrls: ['./referral.component.scss', './referral.component_copy.scss'],
})
export class ReferralComponent implements OnInit {
	refLink = environment.url.rUrl + 'referral/';
	@Input() isFullView = true;

	@Output() reloadData = new EventEmitter<any>();

	referral$: Observable<any> = this.referralService.loadReferralData().pipe(
		map((data: any) => {
			if (data.data && data.data.length > 0) {
				return data.data[0];
			} else {
				return {
					schemeName: 'None',
					schemeId: 1,
					discCode: '',
					balance: 0,
					count: 0,
					signUp: true,
				};
			}
		}),
		map((data: any) => ({
			...data,
			refCode: this.refLink + data.discCode,
			isWallet: data.balance === 0,
		})),
	);
	isFellow: boolean;

	constructor(
		private referralService: ReferralService,
		private profileService: ProfileService,
		private authService: AuthService,
		private rudderStack: RudderStack,
	) {}

	ngOnInit() {
		this.referralService.loadReferralCountInit();

		this.profileService
			.loadProfileData()
			.pipe(
				filter(({ isSkeleton }) => !isSkeleton),
				map(({ data }: any) => data),
				first(),
			)
			.subscribe(profileData => {
				this.isFellow =
					profileData.roles &&
					profileData.roles.length > 0 &&
					profileData.roles.filter(({ id }) => id === 1).length > 0;
			});
	}

	reloadDataSet(isFullView) {
		this.reloadData.emit(isFullView);
	}

	moreInfo() {
		this.rudderStack.log({
			type: 'Referral Widget Click',
			action: 'More Info',
		});
	}
	public copyToClipboardUpdate(s) {
		s = `https://` + s;
		this.rudderStack.log({
			type: 'Referral Widget Click',
			action: 'Copy',
			text: s,
		});

		this.copyText(s);
		this.authService.addToastMsg('Referral URL copied');
	}

	public copyToClipboardShare(s) {
		this.rudderStack.log({
			type: 'Referral Widget Click',
			action: 'Copy Share Text',
			text: s,
		});

		this.copyText(s);

		this.authService.addToastMsg('Share text copied to clipboard as well.');
	}

	shareVia(type, referral) {
		const referralCode = `https://${referral.refCode}`;

		let text_linked_facebook: string;
		let text_whatsApp: string;
		let text_twit: string;

		if (!this.isFellow) {
			text_linked_facebook =
				"Exciting professional news! 🌿 I've joined Terra.do, a pioneering climate upskilling platform on a mission to get 100 million people to work directly on climate this decade. 🚀 They have online learning courses, 40k+ climate jobs, free events, and a growing community of climate-committed individuals.🌱What’s not to like? 🙂" +
				'\n\n' +
				"As part of their incredible referral program, I'm thrilled to offer you a 10% discount on all their online learning and upskilling courses! 🌐" +
				'\n\n' +
				'👉 Use my referral link to sign up and embark on a journey of learning and action: ' +
				referralCode +
				'\n\n' +
				'#ClimateAction';

			text_whatsApp =
				'Hey! I’ve just joined an exciting climate upskilling platform, Terra.do. They have a mission to get 100 million people to work directly on climate this decade. 🚀So cool right?' +
				'\n\n' +
				'Also check out their awesome online learning courses, 40k+ climate jobs, free events and more… I even have a 10% discount to offer you! You can use it on any of their online learning and upskilling courses! 👉 Use my referral link to sign up ' +
				referralCode;

			text_twit =
				'Just joined @terradotdo, a pioneering climate upskilling platform. 🚀Join me by using the link to get 10% discount on all their online learning courses' +
				'\n\n' +
				referralCode +
				'\n\n' +
				'#ClimateAction';
		} else {
			text_linked_facebook =
				'Sharing some exciting news! 🚀🚀🚀' +
				'\n\n' +
				"I've recently completed an online course with Terra.do. If you didn’t know already, they are a climate upskilling platform on a mission to get 100 million people to work directly on climate in this decade. I was able to kickstart my climate action journey through this course and want to help others get started in climate as well 🌱" +
				'\n\n' +
				"I'm thrilled to offer you a 20% discount ✨ on all their online learning and upskilling courses!" +
				'\n\n' +
				'Just use my referral link to sign up: ' +
				referralCode +
				'\n\n' +
				'Have questions for me? I’m happy to answer questions about my journey in climate so far, so please ask away in the comments.' +
				'\n\n' +
				'#ClimateAction ';
			text_whatsApp =
				'Hey, sharing some exciting news! 🌿 I recently completed a course with Terra.do. It is a climate upskilling platform. They’re on a mission to get 100 million people to work directly on climate this decade. 🚀' +
				'\n\n' +
				'They got me going on my climate action journey so, I’m thrilled to offer you a 20% discount on all their online learning and upskilling courses! You can use this link to sign up: ' +
				referralCode +
				'\n\n' +
				'Please feel free to ask any questions!';
			text_twit =
				'Recently finished an online course with @terradotdo. It was a step jump for me 🚀' +
				'\n\n' +
				'Want to join me in this climate mission? Use this link ' +
				referralCode +
				' - Get a 20% discount on all Terra.do learning courses.' +
				'\n\n' +
				'#ClimateAction';
		}

		switch (type) {
			case 'linkedin':
				this.rudderStack.log({
					type: 'Referral Widget Click',
					action: 'LinkedIn',
					referral,
					shareText: text_linked_facebook,
				});
				this.copyToClipboardShare(text_linked_facebook);
				window.open(
					`https://www.linkedin.com/feed/?shareActive=true&text=${encodeURIComponent(
						text_linked_facebook,
					)}`,
					'_blank',
				);
				break;
			case 'twitter_x':
				this.rudderStack.log({
					type: 'Referral Widget Click',
					action: 'Twitter',
					referral,
					shareText: text_twit,
				});
				this.copyToClipboardShare(text_twit);
				window.open(
					`https://twitter.com/intent/tweet?text=${encodeURIComponent(
						text_twit,
					)}`,
					'_blank',
				);
				break;
			case 'whatsapp':
				this.rudderStack.log({
					type: 'Referral Widget Click',
					action: 'Whatsapp',
					referral,
					shareText: text_whatsApp,
				});
				this.copyToClipboardShare(text_whatsApp);
				window.open(
					`https://api.whatsapp.com/send/?text=${encodeURIComponent(
						text_whatsApp,
					)}`,
					'_blank',
				);
				break;
			case 'facebook':
				this.rudderStack.log({
					type: 'Referral Widget Click',
					action: 'Facebook',
					referral,
					shareText: text_linked_facebook,
				});
				this.copyToClipboardShare(text_linked_facebook);
				window.open(
					`https://www.facebook.com/sharer/sharer.php?p[summary]=${encodeURIComponent(
						text_linked_facebook,
					)}&u=${referral.refCode}`,
					'_blank',
				);
				break;
		}
	}

	singUp() {
		const url =
			environment.url.onboarding +
			'on_boarding_done' +
			'/?r=' +
			window.location.href;

		window.location.href = url;
	}

	textArea: any;
	copy;

	isOS() {
		return navigator.userAgent.match(/ipad|iphone/i);
	}

	createTextArea(text) {
		this.textArea = document.createElement('textArea');
		this.textArea.value = text;
		document.body.appendChild(this.textArea);
	}

	selectText() {
		var range, selection;

		if (this.isOS()) {
			range = document.createRange();
			range.selectNodeContents(this.textArea);
			selection = window.getSelection();
			selection.removeAllRanges();
			selection.addRange(range);
			this.textArea.setSelectionRange(0, 999999);
		} else {
			this.textArea.select();
		}
	}

	copyToClipboard() {
		document.execCommand('copy');
		document.body.removeChild(this.textArea);
	}

	copyText(text) {
		this.createTextArea(text);
		this.selectText();
		this.copyToClipboard();
	}
}
