import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SelectOptionComponent } from './select-option/select-option.component';
import { SelectComponent } from './select.component';

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, ScrollingModule],
	declarations: [SelectComponent, SelectOptionComponent],
	exports: [SelectComponent],
})
export class SelectComponentModule {}
