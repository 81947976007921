import {
	Component,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { filter, map } from 'rxjs/operators';
import { RudderStack } from 'src/app/services';

@Component({
	selector: 'app-progress-bar-modal',
	templateUrl: './progress-bar-modal.component.html',
	styleUrls: ['./progress-bar-modal.component.scss'],
})
export class ProgressBarModalComponent implements OnInit {
	@Input() barAnalytics: any;
	@Input() overDueAssignments: any = [];
	@Input() text: any;
	@Input() percentage: any;
	@Input() certificate: any = false;

	expand = true;
	expandMobileFlag = true;
	bgGradient: string;
	isTab = false;
	isMobile = false;
	cohortId;
	mobileText = '';
	router$ = this.activatedRoute.params
		.pipe(
			map(p => Number(p.cohortId)),
			filter(p => !!p),
		)
		.subscribe(cohortId => {
			this.cohortId = cohortId;
		});

	// @HostListener('window:resize', ['$event'])
	// onResize(event: any) {
	// 	this.checkScreenSize();
	// }

	constructor(
		private activatedRoute: ActivatedRoute,
		private rudderStack: RudderStack,
		private router: Router,
		private popoverController: PopoverController,
	) {}

	ngOnInit() {
		this.expandMobileFlag = !this.certificate;

		this.checkScreenSize();
		if (this.percentage === 0) {
			this.bgGradient = `linear-gradient(to right, #38ae8b 0%, #38ae8b 3%, #f0fdfa 3%, #f0fdfa 100%)`;
		} else {
			this.bgGradient = `linear-gradient(to right, #38ae8b 0%,
				 #38ae8b ${this.percentage}%, #f0fdfa ${this.percentage}%, #f0fdfa 100%)`;
		}
		const textArr = this.text.trim().split(' ');

		if (this.certificate) {
			this.mobileText = `${this.percentage}% done! ${
				textArr[textArr.length - 1]
			}`;
		} else {
			this.mobileText = `${this.percentage}% completed ${
				textArr[textArr.length - 1]
			}`;
		}
	}

	goToAssignment(id, cohortId, data) {
		this.rudderStack.log({
			type: 'attention_item_click',
			cohortId: this.cohortId,
			eventType: data.type,
			name: data.name,
		});
		if (!this.isMobile) {
			this.router.navigate([
				`/app/learning/${cohortId}/assignments/assignment-id/${id}`,
			]);
			this.popoverController.dismiss();
		}

		//
	}

	readPrompt(id, cohortId, data) {
		window.open(data.link, '_blank');

		this.rudderStack.log({
			type: 'assignment_read_prompt',
			cohortId: this.cohortId,
			assignmentId: id,
			name: data.name,
			url: data.link,
		});
	}

	expandProgressBar() {
		this.expand = !this.expand;

		if (this.expand) {
			this.rudderStack.log({
				type: 'progresscard_click',
				cohortId: this.cohortId,
			});
		} else {
			this.popoverController.dismiss();
		}
	}

	expandMobile() {
		this.expandMobileFlag = !this.expandMobileFlag;

		if (this.expand) {
			this.rudderStack.log({
				type: 'progresscard_click',
				cohortId: this.cohortId,
			});
		}
	}

	checkScreenSize() {
		this.isMobile = window.innerWidth <= 767;
		this.isTab = window.innerWidth <= 1138;
	}
}
