import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ProgressBarModalComponent } from './progress-bar-modal.component';
import { PieChartModule } from '../pie-chart/pie-chart.module';
import { ImagePreloadDirectiveModule } from 'src/app/directives';

@NgModule({
	declarations: [ProgressBarModalComponent],
	imports: [
		CommonModule,
		IonicModule,
		PieChartModule,
		ImagePreloadDirectiveModule,
	],
	exports: [ProgressBarModalComponent],
})
export class ProgressBarModalModule {}
