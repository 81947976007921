import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TooltipDirectiveModule } from 'src/app/directives/index';
import { ReadMoreModule } from '../read-more/read-more.module';
import { AssignmentComponentModule } from './assignment/assignment.component.module';
import { CardsComponent } from './cards.component';
import { ClassesComponent } from './classes/classes.component';
import { ModuleCardModule } from './classes/module-card/module-card.module';
import { EventComponent } from './event/event.component';
import { RatingModalModule } from './rating-modal/rating-modal.module';

@NgModule({
	declarations: [CardsComponent, ClassesComponent, EventComponent],
	imports: [
		CommonModule,
		TooltipDirectiveModule,
		AssignmentComponentModule,
		RatingModalModule,
		ModuleCardModule,
		IonicModule,
		ReadMoreModule,
	],
	exports: [CardsComponent],
})
export class CardsComponentModule {}
