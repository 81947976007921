import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { environment } from 'src/environments/environment';

import { BYPASS_LOG } from '../../../pages/app/interceptor/interceptor.service';

@Injectable()
export class AssignmentService {
	constructor(private http: HttpClient) {}

	public loadProfile() {
		return this.http.get<any>(
			`${environment.url.profile}/email/${firebase.auth().currentUser?.email}`,
		);
	}

	public loadHtml(submissionId) {
		return this.http.get<any>(
			`${environment.url.cohort}/assignment/submission/${submissionId}/html`,
		);
	}

	public saveHtml(submissionId, content) {
		return this.http.post(
			`${environment.url.cohort}/assignment/submission/${submissionId}/html`,
			content,
			{
				context: new HttpContext().set(BYPASS_LOG, true),
			},
		);
	}

	public saveAssignment(email: string, submissionId: string, filesData: any) {
		return this.http.post(`${environment.url.cohort}/v2/submitAssignment`, {
			email,
			isPublic: true,
			submissionId,
			tags: [],
			url: location.origin + `/app/assignments/${submissionId}`,
			fileUrls: filesData.map(obj => obj.fileUrl),
		});
	}
}
