import {
	HttpContextToken,
	HttpErrorResponse,
	HttpEvent,
	HttpEventType,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { Observable, from, throwError } from 'rxjs';
import { catchError, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services';
import { environment } from 'src/environments/environment';

export const BYPASS_LOG = new HttpContextToken(() => false);

@Injectable()
export class InterceptorService implements HttpInterceptor {
	constructor(private router: Router, private authService: AuthService) {}

	APILogic(httpRequest, token, next): Observable<HttpEvent<any>> {
		const modifiedReq = httpRequest.clone({
			setHeaders: {
				// eslint-disable-next-line @typescript-eslint/naming-convention
				Authorization: 'Bearer ' + token,
			},
		});
		return next.handle(modifiedReq).pipe(
			filter((event: HttpEvent<any>) => event.type !== 0),
			tap((event: any) => {
				if (modifiedReq.method !== 'GET') {
					if (event.url?.includes('profile/view')) {
						return;
					}
					if (event.url?.includes('events/feedback')) {
						return;
					}
					if (httpRequest.context.get(BYPASS_LOG) === true) {
					} else if (event.body.message) {
						this.authService.addToastMsg(event.body.message);
					}
				}
			}),
			catchError((errors: HttpErrorResponse) => {
				const { status, url, message, error } = errors;
				let errorMsg = '';
				switch (status) {
					case 401:
						this.router.navigate([`/logout`]);
						break;
					case 404:
						errorMsg = `Error Code: ${status},  Message: ${message}`;
						break;
					case 405:
						errorMsg = `${url}, Error Code: ${status},  Message: ${message}`;
						break;
					case 504:
					case 503:
						errorMsg = `Please try again!`;
					default:
						break;
				}
				if (errors.statusText === 'Unknown Error') {
					errorMsg = `${url}, Unknown Error`;
				} else if (!errorMsg) {
					if (error.error) {
						errorMsg = error.error;
					} else if (error) {
						errorMsg = error;
					}
				}

				if (errorMsg) {
					this.authService.addToastMsg(errorMsg);
				}
				return throwError(errorMsg);
			}),
		);
	}

	public intercept(
		httpRequest: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		if (httpRequest.url?.includes('https://api.cloudinary.com/v1_1')) {
			return next.handle(httpRequest);
		} else if (httpRequest.url?.includes('upload/assignment')) {
			return next.handle(httpRequest);
		} else if (httpRequest.url?.includes('list.ly')) {
			return next.handle(httpRequest);
		} else {
			if (firebase.auth()?.currentUser) {
				return from(firebase.auth()?.currentUser?.getIdToken()).pipe(
					switchMap(token => {
						return this.APILogic(httpRequest, token, next);
					}),
				);
			} else {
				return this.APILogic(httpRequest, environment.genericToken, next);
			}
		}
	}
}
