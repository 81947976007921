import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-button-list',
	templateUrl: './button-list.component.html',
	styleUrls: ['./button-list.component.scss'],
})
export class ButtonListComponent implements OnInit {
	buttonList: { title: string; color: string }[];
	constructor() {
		// comment
	}

	ngOnInit() {
		this.buttonList = [
			{ title: 'View recording', color: 'yellow' },
			{ title: 'View resources', color: 'white' },
			{ title: 'Go to Class', color: 'yellow' },
		];
	}
}
