/* eslint-disable no-trailing-spaces */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable guard-for-in */
/* eslint-disable prefer-const */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { concatMap, tap } from 'rxjs/operators';
import { NgrxService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import format from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';
import isFuture from 'date-fns/isFuture';

@Injectable()
export class DashboardService {
	serviceName = 'dashboard';
	constructor(private http: HttpClient, private ngrxService: NgrxService) {}

	// Get Company Info functions
	loadCompanyInfoInit(shortCode: string) {
		this.ngrxService.init(this.serviceName, shortCode, 'CompanyInfo');
	}

	loadCompanyInfoAPI(shortCode: string) {
		return this.ngrxService
			.loadInitAPI(this.serviceName, shortCode, 'CompanyInfo')
			.pipe(
				concatMap(() =>
					this.http.get<any>(
						`${environment.url.hubble}/b2b/company/shortcode/${shortCode || 0}`,
					),
				),
				tap(({ data }) =>
					this.ngrxService.loadInitData(
						this.serviceName,
						shortCode,
						'CompanyInfo',
						{ ...data, settingJson: JSON.parse(data.settingJson) },
					),
				),
			);
	}

	// Get Company Info functions V2
	loadCompanyInfoInitV2(shortCode: string) {
		this.ngrxService.init(this.serviceName, shortCode, 'CompanyInfoV2');
	}

	companyInfoV2(shortCode) {
		return this.ngrxService
			.loadInitAPI(this.serviceName, shortCode, 'CompanyInfoV2')
			.pipe(
				concatMap(() =>
					this.http.get<any>(`${environment.url.cohort}/sprint/b2b`),
				),
				tap(({ company }) =>
					this.ngrxService.loadInitData(
						this.serviceName,
						shortCode,
						'CompanyInfoV2',
						{
							...company,
							settingJson: JSON.parse(company.settingJson),
						},
					),
				),
			);
	}

	loadCompanyInfoDataV2(shortCode: string) {
		return this.ngrxService.loadData(
			this.serviceName,
			shortCode,
			'CompanyInfoV2',
		);
	}

	loadCompanyInfoData(shortCode: string) {
		return this.ngrxService.loadData(
			this.serviceName,
			shortCode,
			'CompanyInfo',
		);
	}

	// Sprints functions
	loadSprintsInit(companyId: number) {
		this.ngrxService.init(this.serviceName, companyId, 'sprints');
	}

	loadSprintsAPI(companyId: number, courses) {
		return this.ngrxService
			.loadInitAPI(this.serviceName, companyId, 'sprints')
			.pipe(concatMap(() => this.resetSprints(companyId, courses)));
	}

	resetSprints(companyId: number, courses) {
		return this.http
			.post<any>(`${environment.url.cohort}/b2b/sprints`, {
				courses,
			})
			.pipe(
				tap((data: any) => {
					this.loadInitDataSprints(companyId, data);
				}),
			);
	}

	loadInitDataSprints(companyId, data) {
		let sprints = data.map((sp: any) => {
			let properties = this.convertArrayObject(sp.properties);

			return {
				...sp,
				discountPrice: Math.trunc(sp.coursePrice - (sp.coursePrice * 15) / 100),
				isStartingSoon:
					isFuture(new Date(sp.cohortStartTime)) &&
					differenceInDays(new Date(sp.cohortStartTime), new Date()) < 7,
				properties,
				people: sp.people.map(p => {
					return {
						...p,
						multimedia48: p.multimedia?.replace(
							'terra-do/image/upload',
							'terra-do/image/upload/w_48,h_48,c_limit',
						),
						multimedia40: p.multimedia?.replace(
							'terra-do/image/upload',
							'terra-do/image/upload/w_40,h_40,c_limit',
						),
					};
				}),
				courseMultimediaW240: sp.courseMultimedia?.replace(
					'terra-do/image/upload',
					'terra-do/image/upload/w_240',
				),
			};
		});

		this.ngrxService.loadInitData(
			this.serviceName,
			companyId,
			'sprints',
			sprints,
		);
	}

	loadSprintsData(companyId: number) {
		return this.ngrxService.loadData(this.serviceName, companyId, 'sprints');
	}

	signupSprintsAPI(b2bCompanyId: number, { courseId, cohortId }) {
		return this.http.post<any>(
			`${environment.url.hubble}/b2b/company/applied`,
			{
				studentEmail: firebase.auth().currentUser?.email,
				courseId,
				cohortId,
				b2bCompanyId,
			},
		);
	}

	convertArrayObject(inputObject) {
		let outputObject = {};
		for (const key in inputObject) {
			if (inputObject.hasOwnProperty(key)) {
				if (key !== 'displayTags') {
					outputObject[key] = inputObject[key][0];
				} else {
					outputObject[key] = inputObject[key];
				}
			}
		}
		return outputObject;
	}
}
