import { NgModule } from '@angular/core';
import { VERSION } from '@angular/fire';
import firebase from 'firebase/compat/app';
import { AngularFireAuthGuardV2 } from './auth-guard-v2';

@NgModule({
	providers: [AngularFireAuthGuardV2],
})
export class AngularFireAuthGuardV2Module {
	constructor() {
		firebase.registerVersion('angularfire', VERSION.full, 'auth-guard-compat');
	}
}
