import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { RolesComponent } from './roles.component';

@NgModule({
	declarations: [RolesComponent],
	imports: [CommonModule, IonicModule],
	exports: [RolesComponent],
})
export class RolesComponentModule {}
