import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
	ICalendarEvent,
	NgAddToCalendarService,
} from '@trademe/ng-add-to-calendar';
import { RudderStack } from '../../rudderstack';

@Component({
	selector: 'terra-add-to-calendar',
	templateUrl: './add-to-calendar.component.html',
	styleUrls: ['./add-to-calendar.component.css'],
})
export class AddToCalendarComponent implements OnInit {
	@Input() public eventDetails: any;

	constructor(
		private _addToCalendarService: NgAddToCalendarService,
		private _sanitizer: DomSanitizer,
		private rudderStack: RudderStack,
	) {}

	public ngOnInit() {
		// empty
	}

	public addEventGotoUrl(service: string) {
		const newEvent: ICalendarEvent = {
			// Event title
			title: this.eventDetails.summary,
			// Event start date
			start: new Date(this.eventDetails.startDateTime),
			// Event duration (IN MINUTES)
			duration: 120,
			// If an end time is set, this will take precedence over duration (optional)
			end: new Date(this.eventDetails.endDateTime),
			// Event Description (optional)
			description: this.eventDetails.description,
		};

		let getHrefFor: number = 0;
		switch (service) {
			case 'apple':
				getHrefFor = this._addToCalendarService.calendarType.iCalendar;
				break;
			case 'google':
				getHrefFor = this._addToCalendarService.calendarType.google;
				break;
			// case 'office365':
			// 	getHrefFor = this._addToCalendarService.calendarType.google;
			// 	break;
			case 'outlook':
				getHrefFor = this._addToCalendarService.calendarType.outlook;
				break;
			case 'outlookCom':
				getHrefFor = this._addToCalendarService.calendarType.outlookLive;
				break;
			case 'yahoo':
				getHrefFor = this._addToCalendarService.calendarType.yahoo;
				break;
			default:
				getHrefFor = 0;
				break;
		}
		this.rudderStack.log({
				type: 'event_add_to_calender',
				cohortId: this.eventDetails.cohortId,
				eventId: this.eventDetails.eventId,
				name: this.eventDetails.name,
		})
		const URL = this._addToCalendarService.getHrefFor(getHrefFor, newEvent);

		const sanitizeUrl: any = this._sanitizer.bypassSecurityTrustUrl(URL);

		window.open(sanitizeUrl.changingThisBreaksApplicationSecurity, '_blank');
	}
}
