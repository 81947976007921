import { NgModule } from '@angular/core';
import { ReferralComponent } from './referral.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
	declarations: [ReferralComponent],
	imports: [CommonModule,IonicModule],
	exports: [ReferralComponent],
})
export class ReferralModule {}
