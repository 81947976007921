import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/services';
import { RudderStack } from './services/rudderstack';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {
	constructor(
		public authService: AuthService,
		public router: Router,
		private rudderStack: RudderStack,
	) {
		this.router.events.forEach(item => {
			if (item instanceof NavigationEnd) {
				const bodyElement: any = document.body;

				try {
					bodyElement.classList = [];
				} catch (e) {}
				try {
					bodyElement.classList.add(item.url.replace(/\//g, '_'));
				} catch (e) {}

				const pageViewEvent: any = {};
				pageViewEvent.type = 'page_view';
				pageViewEvent.event = 200;
				pageViewEvent.pagePath = item.url;
				this.rudderStack.log(pageViewEvent);
			}
		});
	}
}
