import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { roles } from 'src/app/enum/role';
import { environment } from 'src/environments/environment';

@Injectable()
export class SelectionBoxService {
	constructor(private http: HttpClient) {}

	loadTagGroups() {
		return this.http.get<any>(`${environment.url.profile}/tagGroups`).pipe(
			map(data => {
				return data.map(d => {
					if (d.name === 'Job Title') {
						return {
							...d,
							values: d.values.map(record => ({
								id: record.name,
								name: record.name,
							})),
						};
					} else if (d.name === 'Locations') {
						return {
							...d,
							values: d.values.map(record => ({
								id: record,
								name: record,
							})),
						};
					} else if (d.name === 'Industry') {
						return {
							...d,
							values: d.values.map(record => ({
								id: record.name,
								name: record.name,
							})),
						};
					} else if (d.name === 'User Roles') {
						return {
							...d,
							values: [
								{
									id: roles.hiringManager,
									name: 'Hiring on Terra.do',
								},
								{
									id: roles.communityAmbassadors,
									name: 'Community Ambassadors',
								},
							],
						};
					} else {
						return d;
					}
				});
			}),
		);
	}

	loadCompanyList(search = ' ') {
		return this.http.get<any>(
			`${environment.url.company}/company/page/0/limit/50/search/${
				search || ' '
			}/v2`,
		);
	}
}
