import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'search',
})
export class SearchPipe implements PipeTransform {
	transform(value: any[], search: string): any {
		if (search) {
			const list = value.filter(
				({ name }) =>
					String(name).toLowerCase().search(search.toLowerCase()) > -1,
			);

			const match = value.filter(
				({ name }) => String(name).toLowerCase() === search.toLowerCase(),
			);

			if (match.length === 0) {
				return [...list, { id: 0, name: search }];
			} else {
				return list;
			}
		} else {
			return value;
		}
	}
}
