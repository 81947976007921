import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { RudderStack } from 'src/app/services';
import { TextBookService } from 'src/app/services';

@Component({
	selector: 'app-classes',
	templateUrl: './classes.component.html',
	styleUrls: ['./classes.component.css'],
})
export class ClassesComponent implements OnInit, OnChanges {
	@Input() data: any;
	@Input() isLock: boolean;
	classes: any;

	constructor(
		private rudderStack: RudderStack,
		private textBookService: TextBookService,
	) {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges) {
		const data = changes.data.currentValue;

		if (data.modules.length > 0) {
			this.data.modules = data.modules.map(mo => ({
				...mo,
				startDateTime: data.startDateTime,
				endDateTime: data.endDateTime,
			}));

			if (data.modules.length === 1) {
				this.data.classId = this.data.modules[0].moduleId;
			}
		}
	}

	redirectTo(url) {
		this.rudderStack.log({
			type: 'class_gotoclass',
			cohortId: this.data.cohortId,
			eventId: this.data.classId,
		});
		if (url) {
			this.textBookService.open(url);
		}
	}
}
