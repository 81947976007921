import { Component, Input, OnChanges } from '@angular/core';
import { RudderStack } from 'src/app/services';
@Component({
	selector: 'app-readmore2',
	templateUrl: './readmore2.component.html',
	styleUrls: ['./readmore2.component.scss'],
})
export class Readmore2Component implements OnChanges {
	@Input() text: string;
	@Input() maxLength = 25;
	@Input() profile?: any;
	fullText = true;
	showMore = false;
	showLess = false;
	rmTextShort = '';
	rmTextFull = '';
	inputWords = [];
	constructor(private rudderStack: RudderStack) {
		// block
	}

	public readMore(ev, flag) {
		ev.stopPropagation();
		if (this.inputWords.length > this.maxLength) {
			if (flag) {
				this.rudderStack.log({
					type: 'Click on read more',
					clickProfileId: this.profile,
				});
				this.showMore = false;
				this.fullText = true;
				this.rmTextFull =
					this.text +
					` <b class="text-primary cursor color-Blacks-Default_Black_3">Read less</b>`;
				this.showLess = true;
			} else {
				this.showLess = false;
				this.showMore = true;
				this.fullText = false;
			}
		}
	}

	ngOnChanges() {
		this.rmTextShort = this.text;
		this.rmTextFull = this.text;
		try {
			this.inputWords = this.text?.split(' ') || [];
		} catch (error) {
			console.error('Error splitting the text:', error);
			this.inputWords = []; // Fallback to an empty array
		}

		if (this.inputWords.length > this.maxLength) {
			// 30
			this.fullText = false;
			this.showMore = true;
			this.rmTextShort =
				this.inputWords.slice(0, this.maxLength).join(' ') +
				'... ' +
				`<b class="text-primary cursor color-Blacks-Default_Black_3">Read more</b>`;
		} else {
			if (this.rmTextShort?.length > 300) {
				this.fullText = false;
				this.showMore = true;
				this.rmTextShort =
					this.rmTextShort.substr(0, 300) +
					'... ' +
					`<b class="text-primary cursor color-Blacks-Default_Black_3">Read more</b>`;
			} else {
				const lineBreaks = this.rmTextShort?.split(/\n/g);
				if (lineBreaks?.length > 4) {
					this.fullText = false;
					this.showMore = true;
					this.rmTextShort = lineBreaks.slice(0, 4).join('\n') + '...';
				}
			}
		}
	}
}
