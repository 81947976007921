import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LearningService } from 'src/app/pages/app/learning/service/learning.service';

import { TooltipDirectiveModule } from '../../directives/tooltip/tooltip.directive.module';
import { AssignmentEditorComponent } from './assignment-editor.component';
import { AssignmentService } from './services/assignment.service';
import { EditorModule } from './tiny-mce';

@NgModule({
	declarations: [AssignmentEditorComponent],
	imports: [
		CommonModule,
		EditorModule,
		TooltipDirectiveModule,
		FormsModule,
		IonicModule,
	],
	exports: [AssignmentEditorComponent],
	providers: [AssignmentService, LearningService],
})
export class AssignmentEditorComponentModule {}
