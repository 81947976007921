import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { SelectionBoxService } from './service/selection-box.service';

@Component({
	selector: 'app-selection-box',
	templateUrl: './selection-box.component.html',
	styleUrls: ['./selection-box.component.scss'],
})
export class SelectionBoxComponent implements OnInit {
	@Input() name = '';
	@Input() value: any = [];
	data = [];
	@Input('placeholder') placeholder = '';
	@Input('type') type: 'all' | 'id' | 'value' = 'id';

	search = '';

	searchActive = false;
	list: any = [];

	loader = true;

	constructor(
		public popoverController: PopoverController,
		private selectionBoxService: SelectionBoxService,
	) {}

	onChangeTime(ev: any = { value: '' }) {
		this.selectionBoxService.loadCompanyList(ev.value).subscribe(data => {
			this.loader = false;
			const matchIds = this.value.map(({ id }) => id);
			this.list = [
				...this.value,
				...data.data.filter(({ id }) => matchIds.indexOf(id) === -1),
			];
		});
	}

	ngOnInit(): void {
		if (this.value.length > 0) {
			this.value = this.value.map(res => ({
				...res,
				active: true,
				id: res.companyId,
			}));

			this.list = this.value;
			this.loader = false;
		}
		this.onChangeTime();
	}

	close() {
		this.popoverController.dismiss();
	}

	done() {
		if (this.type === 'id') {
			this.popoverController.dismiss({
				data: this.value.map(({ id }) => id),
			});
		} else if (this.type === 'all') {
			this.popoverController.dismiss({
				data: this.value,
			});
		}
	}
	closeActiveSearch() {
		this.searchActive = !this.searchActive;
		this.search = '';
	}

	addSelection(record) {
		record.active = !record.active;
		const existRecord = this.value.filter(({ id }) => id === record.id);
		if (existRecord.length > 0) {
			this.value = this.value.filter(({ id }) => id !== record.id);
		} else {
			this.value.push(record);
		}
	}
}
