import { Component, Input, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/pages/app/profile/services/profile.service';
import { RudderStack } from 'src/app/services';

@Component({
	selector: 'app-social-media',
	templateUrl: './social-media.component.html',
	styleUrls: ['./social-media.component.css'],
})
export class SocialMediaComponent implements OnInit {
	@Input() data: any;
	@Input() showbookmark;
	@Input() isModerator = false;
	profileId;
	constructor(private rudderStack: RudderStack,public profileService: ProfileService,) {
		// block
	}

	ngOnInit(): void {
		// block
	}


	saveProfile(loggingUserId, otherProfileId, action) {
		this.rudderStack.log({
			type: 'Click on save in profile card',
			profileId: loggingUserId,
			otherProfileId,
			isSaved: action,
		});		

		this.profileService
			.saveProfile(loggingUserId, otherProfileId)
			.subscribe(data => {
				this.profileService
					.loadOtherProfileByIdAPISkipLoader(otherProfileId)
					.subscribe();
			});
	}

	redirectUrl(ev, type, url, bool = true) {
		ev.stopPropagation();

		if (!!url && bool) {
			this.rudderStack.log({
				type: 'Click on social media handles in profile',
				socialmediaHandle: type,
				clickProfileId: this.data.id,
				url,
			});
			if (type === 'slack') {
				// this.rudderStack.log({
				// 	type: 'profile_view_slack_click',
				// 	profileId: this.profileId,
				// });
				if (url.search('slack.com') === -1) {
					window.open(
						`https://terra-doworkspace.slack.com/team/${url}`,
						'_blank',
					);
				} else {
					if (url.search('http') === -1) {
						window.open(`https://${url}`, '_blank');
					} else {
						window.open(url, '_blank');
					}
				}
			} else if (type == 'linkedIn' || type == 'linkedin') {
				// this.rudderStack.log({
				// 	type: 'profile_view_linkedIn_click',
				// 	profileId: this.profileId,
				// });
				if (url.search('linkedin.com') === -1) {
					window.open(`https://www.linkedin.com/${url}`, '_blank');
				} else {
					if (url.search('http') === -1) {
						window.open(`https://${url}`, '_blank');
					} else {
						window.open(url, '_blank');
					}
				}
			} else if (type === 'twitter') {
				// this.rudderStack.log({
				// 	type: 'profile_view_twitter_click',
				// 	profileId: this.profileId,
				// });
				if (url.search('twitter.com') === -1) {
					window.open(`https://twitter.com/${url}`, '_blank');
				} else {
					if (url.search('http') === -1) {
						window.open(`https://${url}`, '_blank');
					} else {
						window.open(url, '_blank');
					}
				}
			} else if (type === 'website') {
				// this.rudderStack.log({
				// 	type: 'profile_view_website_click',
				// 	profileId: this.profileId,
				// });
				if (url.search('http') === -1) {
					window.open(`https://${url}`, '_blank');
				} else {
					window.open(url, '_blank');
				}
			} else if (type === 'calendly') {
				// this.rudderStack.log({
				// 	type: 'profile_view_calendly_click',
				// 	profileId: this.profileId,
				// });
				if (url.search('http') === -1) {
					window.open(`https://${url}`, '_blank');
				} else {
					window.open(url, '_blank');
				}
			} else if (type === 'resume') {
				// this.rudderStack.log({
				// 	type: 'profile_view_resume_click',
				// 	profileId: this.profileId,
				// });
				if (url.search('http') === -1) {
					window.open(`https://${url}`, '_blank');
				} else {
					window.open(url, '_blank');
				}
			} else {
				window.open(url, '_blank');
			}
		}
	}
}
