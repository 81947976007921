import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ProfileService } from 'src/app/pages/app/profile/services/profile.service';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
	profile$: Observable<any> = this.profileService.loadProfileData();

	constructor(
		public authService: AuthService,
		private profileService: ProfileService,
		public modalController: ModalController,
	) {}

	ngOnInit() {}

	done() {
		this.modalController.dismiss();
	}
}
