import { createFeatureSelector, createSelector } from '@ngrx/store';
import { apiRecordData } from 'src/app/model/loader';
import * as fromSelectionBox from './selection-box.reducer';

export const selectSelectionBoxState = createFeatureSelector<
	fromSelectionBox.State
>(fromSelectionBox.selectionBoxFeatureKey);

export const selectApiStatus = createSelector(
	selectSelectionBoxState,
	state => state.apiStatus,
);

export const selectSelectionBox = createSelector(
	selectSelectionBoxState,
	apiRecordData,
);

export const selectSearchFiltersWithText = (title: string) =>
	createSelector(selectSelectionBoxState, state => {
		if (state.data.length === 0) {
			return [];
		} else {
			const matchArr = state.data
				.filter(({ name }) => name === title)
				.map(({ values }) => values);

			return matchArr[0];
		}
	});

export const selectAllSearchFilters = createSelector(
	selectSelectionBoxState,
	state => {
		if (state.data.length === 0) {
			return [];
		} else {
			const matchArr = state.data.map(({ values }) =>
				values.map(({ name }) => name),
			);

			return matchArr.flat(2).map(record => ({
				id: record,
				name: record,
			}));
		}
	},
);

export const selectMentorHelpWith = createSelector(
	selectSelectionBoxState,
	state => {
		if (state.data.length === 0) {
			return [];
		} else {
			let arr = state.data.filter(({ name }) => name === 'Mentorhelpwith');

			if (arr.length > 0) {
				return arr[0].values;
			} else {
				return [];
			}
		}
	},
);

export const selectHelpWith = createSelector(selectSelectionBoxState, state => {
	if (state.data.length === 0) {
		return [];
	} else {
		let arr = state.data.filter(({ name }) => name === 'Help With');

		if (arr.length > 0) {
			return arr[0].values;
		} else {
			return [];
		}
	}
});
