import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { of } from 'rxjs';
import { concatMap, first, map, tap } from 'rxjs/operators';
import { NgrxService, RudderStack, PublicPageService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { BYPASS_LOG } from '../../interceptor/interceptor.service';
import { ProfileSearchService } from './profile-search.service';

@Injectable()
export class ProfileService {
	profileServiceName = 'Profile';
	constructor(
		private http: HttpClient,
		private ngrxService: NgrxService,
		private rudderStack: RudderStack,
		private profileSearchService: ProfileSearchService,
		private publicPageService: PublicPageService,
	) {}

	loadProfileApiStatus(requiredCondition = true) {
		if (!this.publicPageService.isAppPage && requiredCondition) {
			return this.ngrxService
				.loadInitAPI(this.profileServiceName, 'profile')
				.pipe(
					concatMap(() => of({})),
					tap((data: any) => {
						this.rudderStack.currentProfileId = null;
					}),
					tap(data => {
						this.ngrxService.loadInitData(
							this.profileServiceName,
							'profile',
							data,
						);
					}),
				);
		} else {
			return this.ngrxService
				.loadInitAPI(this.profileServiceName, 'profile')
				.pipe(
					concatMap(() =>
						this.http.get<any>(
							`${environment.url.profile}/email/${
								firebase.auth().currentUser?.email
							}`,
						),
					),
					tap((data: any) => {
						this.rudderStack.identify(firebase.auth().currentUser?.email, data);
						this.rudderStack.currentProfileId = data.id;
						this.rudderStack.currentCompanyId = data.b2bCompanyId;
						this.rudderStack.isGrow =
							data.roles.filter(role => role.id === 18).length > 0;
					}),
					tap(data => {
						this.ngrxService.loadInitData(
							this.profileServiceName,
							'profile',
							data,
						);
					}),
				);
		}
	}

	loadProfileInit() {
		this.ngrxService.init(this.profileServiceName, 'profile');
	}

	loadProfileData(): any {
		return this.ngrxService.loadData(this.profileServiceName, 'profile');
	}

	loadProfileDataSkipLoader(profileId: number) {
		return of(true).pipe(
			concatMap(() => this.getProfile(profileId)),
			tap(data =>
				this.ngrxService.loadInitData(this.profileServiceName, 'profile', data),
			),
		);
	}

	editProfileBio(id: number, obj: any = {}) {
		return this.http.put<any>(`${environment.url.hubble}/profile`, obj);
	}

	editProfileOpenDoor(id: number, obj: any = {}) {
		return this.http.put<any>(
			`${environment.url.profile}/opendoor/climate/${id}`,
			obj,
			{ context: new HttpContext().set(BYPASS_LOG, true) },
		);
	}

	deleteUploadFile(path: number, url: string) {
		return this.http.delete<any>(
			`${environment.url.profile}/remove/${path}/${
				url.split('/')[url.split('/').length - 1]
			}`,
		);
	}

	saveProfile(profileId: number, saveProfileId: string) {
		return this.http
			.post<any>(`${environment.url.profile}/save`, {
				profileId,
				saveProfileId,
			})
			.pipe(
				tap(data => {
					if (data) {
						this.profileSearchService
							.loadSearchProfileData()
							.pipe(first())
							.subscribe(({ data, pageRecord }) => {
								this.profileSearchService.updateData(
									data.map(record => {
										if (record.id === saveProfileId) {
											if (record.savedBy.indexOf(profileId) !== -1) {
												return {
													...record,
													savedBy: record.savedBy.filter(
														id => id !== profileId,
													),
												};
											} else {
												return {
													...record,
													savedBy: [...record.savedBy, profileId],
												};
											}
										} else {
											return record;
										}
									}),
									pageRecord,
								);
							});
					}
				}),
			);
	}

	editProfileMedia(mediaId: number, media: any) {
		return this.http.put<any>(
			`${environment.url.profile}/media/${mediaId}`,
			media,
		);
	}

	addProfileMedia(media: any) {
		return this.http.post<any>(`${environment.url.profile}/media`, media);
	}

	deleteProfileMedia(mediaId: number) {
		return this.http.delete<any>(`${environment.url.profile}/media/${mediaId}`);
	}

	// loadProfileById(id: number) {
	// 	return this.http.get<any>(`${environment.url.profile}/id/${id}`);
	// }

	// Other Profile function
	serviceName = 'otherProfile';

	loadOtherProfileByIdInit(profileId: number) {
		this.ngrxService.init(this.serviceName, profileId);
	}

	loadOtherProfileByIdAPI(profileId: number) {
		return this.ngrxService.loadInitAPI(this.serviceName, profileId).pipe(
			concatMap(() => this.getProfile(profileId)),
			tap(data =>
				this.ngrxService.loadInitData(this.serviceName, profileId, data),
			),
		);
	}

	loadOtherProfileByIdAPISkipLoader(profileId: number) {
		return of(true).pipe(
			concatMap(() => this.getProfile(profileId)),
			tap(data =>
				this.ngrxService.loadInitData(this.serviceName, profileId, data),
			),
		);
	}

	loadOtherProfileOpenDoor(profileId: number) {
		return of(true).pipe(
			concatMap(() =>
				this.http
					.get<any>(
						`${environment.url.profile}/opendoor/climate/profile/${profileId}`,
					)
					.pipe(map(({ data }) => data)),
			),
			tap(data =>
				this.ngrxService.loadInitData(
					this.profileServiceName,
					'open-door',
					profileId,
					data,
				),
			),
		);
	}

	loadOtherProfileByIdData(profileId: number) {
		return this.ngrxService.loadData(this.serviceName, profileId);
	}

	pageNotFound(name: string, text: string) {
		return this.http.post<any>(`${environment.url.profile}/feedback`, {
			name,
			text,
		});
	}

	// load Search History
	searchHistoryString = 'search-history';
	loadSearchHistoryInit(id: number) {
		this.ngrxService.init(this.searchHistoryString, id);
	}

	loadSearchHistoryAPI(id: number) {
		return this.ngrxService.loadInitAPI(this.searchHistoryString, id).pipe(
			concatMap(() =>
				this.http.get<any>(`${environment.url.profile}/${id}/search/history/3`),
			),
			tap(data =>
				this.ngrxService.loadInitData(this.searchHistoryString, id, data),
			),
		);
	}

	loadSearchHistoryData(id: number) {
		return this.ngrxService.loadData(this.searchHistoryString, id);
	}

	getMetaTag(url: string) {
		return this.http.get<any>(`https://list.ly/api/v4/meta?url=` + url);
	}

	viewProfile(profileId: number, viewProfileId: number) {
		return this.http.post<any>(
			`${environment.url.profile}/view`,
			{
				profileId,
				viewProfileId,
			},
			{
				context: new HttpContext().set(BYPASS_LOG, true),
			},
		);
	}

	getProfile(profileId) {
		return this.http
			.get<any>(`${environment.url.profile}/id/${profileId}`)
			.pipe(
				map(people => ({
					...people,
					isSocial:
						people.linkedIn ||
						people.linkedin ||
						people.twitterHandle ||
						people.twitter ||
						people.website ||
						people.calendlyLink ||
						people.resume,
					certificateURL:
						people.courses && people.courses.length > 0
							? people.courses
									.filter(({ qrCode }) => qrCode)
									.map(course => ({
										...course,
										certificateURL: `${environment.url.cohort}/certificate/${course.qrCode}`,
									}))
							: [],
				})),
			);
	}
}
