export const environment = {
	production: true,
	firebaseConfig: {
		apiKey: 'AIzaSyAit0M9mMKuY3oVcXF-IrFFnvy3ZfliS3w',
		authDomain: 'auth-dev.terra.do',
		databaseURL:
			'https://hubble-159a2-default-rtdb.asia-southeast1.firebasedatabase.app',
		projectId: 'hubble-159a2',
		storageBucket: 'hubble-159a2.appspot.com',
		messagingSenderId: '302493094096',
		appId: '1:302493094096:web:afba897677aa13aa6d8ed5',
		measurementId: 'G-2JMXMHWMQ5',
	},
	welcomeRedirect: true,
	profileUploadKey: 'vo8iozpy',
	url: {
		profile: 'https://terra-api.terra.do/profile',
		hubble: 'https://terra-api.terra.do/hubble',
		cohort: 'https://terra-api.terra.do/cs',
		class: 'https://api.terra.do/stage/classdev',
		company: 'https://terra-api.terra.do/portfolio',
		payment: 'https://terra-api.terra.do/payment/',
		catalog: 'https://terra-api.terra.do/catalog',
		onboarding: 'https://welcome-dev.terra.do/',
		feedBaseUrl: 'https://hubble-new-dev.terra.do/post/api/',
		firebaseToken:
			'https://us-central1-hubble-159a2.cloudfunctions.net/minttoken',
		serverlessUrl:
			'https://yz7e8zx4v1.execute-api.us-east-1.amazonaws.com/test/',
		rUrl: 'r-dev.terra.do/',
		jURl: 'https://jb-t1.terra.do/climate-jobs/job-board/',
		textbook: 'https://devbook.terra.do/class/',
		applyUrl: 'https://apply-dev.terra.do/',
	},
	addMemberRemoveCohort: 30,
	genericToken: 'x-api-6407a71dc469c0eca7b34c1e93892fb3',
	earthToken: true,
};
