import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { ProfileService } from 'src/app/pages/app/profile/services/profile.service';
import { AppService } from 'src/app/pages/app/service/app.service';
import { PublicPageService } from 'src/app/services';
import { environment } from 'src/environments/environment';

import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { LogoutTabComponent } from './logout-tab/logout-tab.component';
import { NotificationDropdownComponent } from './notification-dropdown/notification-dropdown.component';

@Component({
	selector: 'app-main-header',
	templateUrl: './main-header.component.html',
	styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit {
	@Input() activeMenu = 'library';

	openMobileMenu = false;

	menuList = {
		Library: false,
		Community: false,
		Courses: false,
		Events: false,
		Jobs: false,
		news:true
	};

	@Input() memberType:
		| 'Visitor'
		| 'Fellow'
		| 'Non-Fellow'
		| 'Grow-User'
		| 'B2B' = 'Visitor';

	public b2bLogo$: Observable<any | null> = this.appService.loadB2bLogo();

	public profile$: Observable<any> = this.profileService.loadProfileData();

	public environment = environment;

	constructor(
		public modalController: ModalController,
		private profileService: ProfileService,
		public popoverController: PopoverController,
		private router: Router,
		private db: AngularFirestore,
		private appService: AppService,
		public publicPageService: PublicPageService,
		private loadingCtrl: LoadingController,
	) {
		// test
	}

	public ngOnInit() {
		if (this.memberType === 'Visitor') {
			this.menuList = {
				Library: false,
				Community: false,
				Courses: true,
				Events: true,
				Jobs: false,
				news:true
			};
		} else if (this.memberType === 'Non-Fellow') {
			this.menuList = {
				Library: false,
				Community: false,
				Courses: true,
				Events: true,
				Jobs: true,
				news:true
			};
		} else if (
			this.memberType === 'Grow-User' ||
			this.memberType === 'Fellow'
		) {
			this.menuList = {
				Library: true,
				Community: true,
				Courses: true,
				Events: true,
				Jobs: true,
				news:true
			};
		} else if (this.memberType === 'B2B') {
			this.menuList = {
				Library: true,
				Community: false,
				Courses: true,
				Events: true,
				Jobs: false,
				news:true
			};
		}
	}

	public async presentNotificationModal(event) {
		const modal = await this.popoverController.create({
			component: NotificationDropdownComponent,
			cssClass: 'notification-modal-class',
			event,
		});
		return await modal.present();
	}
	public async presentLogoutModal(event) {
		const modal = await this.popoverController.create({
			component: LogoutTabComponent,
			cssClass: 'logout-modal-class',
			event,
		});
		return await modal.present();
	}

	// menuUpdate(str: any) {
	// 	this.menuUpdateNew(str);
	// 	// this.router.navigate([`/app/${str}`]);
	// 	// this.class = str;
	// }

	public async onChangeMenu(str: any) {
		this.activeMenu = str;
		this.openMobileMenu = false;
		if (str === 'work') {
			window.location.href = environment.url.jURl;

			const loading = await this.loadingCtrl.create({
				backdropDismiss: true,
			});

			loading.present();
		} else {
			const currentRoute = window.location.pathname;
			if (currentRoute.includes('/app')) {
				const currentUser = firebase.auth().currentUser;
				if (currentUser) {
					this.db
						.doc('users/' + currentUser.uid)
						.valueChanges()
						.subscribe((data: any) => {
							if (data.USER_QUESTION_AND_ANSWER === '1') {
								this.router.navigate([`/app/${str}`]);
							} else {
								if (str === 'profile') {
									this.presentWelcomeRedirect(str);
								} else {
									this.router.navigate([`/app/${str}`]);
								}
							}
						});
				}
			} else {
				this.router.navigate([`/${str}`]);
			}
		}
	}

	public async presentWelcomeRedirect(str: any) {
		const modal = await this.popoverController.create({
			component: ConfirmationPopupComponent,
			cssClass: 'logout-modal-class',
			componentProps: {
				heading:
					'Please tell us a bit about yourself to access all features and services offered by Terra.do.',
				subHeading:
					'Answer a few questions about your background and interests (~5 mins)',
				imageUrl: 'assets/onboarding-popup.png',
			},
		});

		if (window.innerWidth < 480) {
			modal.style.cssText = `--max-width: 540px;--min-width: calc(100% - 25px);`;
		} else {
			modal.style.cssText = `--max-width: 540px;--min-width: 480px;`;
		}

		await modal.present();

		const { data } = await modal.onDidDismiss();
		if (data) {
			this.publicPageService.loggedWithOnboarding(
				location.origin + '/app/profile',
			);
		}
	}

	public signIn() {
		this.publicPageService.loginPageRedirectWithOnboarding();
	}

	public async loader(url) {
		window.open(url, '_self');

		const loading = await this.loadingCtrl.create({
			backdropDismiss: true,
		});

		loading.present();
	}

	signUpVia() {
		const url =
			environment.url.onboarding + 'authorize' + '/?r=' + window.location.href;
		window.location.href = url;
	}

	loginVia() {
		const url =
			environment.url.onboarding + 'authorize' + '/?r=' + window.location.href;
		window.location.href = url;
	}
}
