import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleCardComponent } from './module-card.component';
import { TooltipDirectiveModule } from 'src/app/directives';
import { IonicModule } from '@ionic/angular';

@NgModule({
	declarations: [ModuleCardComponent],
	imports: [CommonModule, TooltipDirectiveModule, IonicModule],
	exports: [ModuleCardComponent],
})
export class ModuleCardModule {}
