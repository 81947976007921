import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotStartedComponent } from './not-started.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
	declarations: [NotStartedComponent],
	imports: [CommonModule, IonicModule],
	exports: [NotStartedComponent],
})
export class NotStartedModule {}
