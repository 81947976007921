import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { filter, first } from 'rxjs/operators';
import * as SelectionBoxActions from './redux/selection-box.actions';
import { selectSearchFiltersWithText } from './redux/selection-box.selectors';

@Component({
	selector: 'app-selection-box',
	templateUrl: './selection-box.component.html',
	styleUrls: ['./selection-box.component.scss'],
})
export class SelectionBoxComponent implements OnInit {
	@Input() name = '';
	@Input() value = '';
	@Input() data = [];
	@Input('placeholder') placeholder = '';
	@Input('type') type: 'all' | 'id' | 'value' = 'id';
	@Input('dataOnly') dataOnly = false;
	@Input('courseUI') courseUI: boolean = false;

	search = '';

	searchActive = false;
	list: any = [];

	constructor(public modalController: ModalController, private store: Store) {
		if (!this.dataOnly) {
			this.store.dispatch(SelectionBoxActions.loadSelectionBox());
		}
	}

	ngOnInit(): void {
		if (!(this.data && this.data.length > 0)) {
			this.store
				.select(selectSearchFiltersWithText(this.name))
				.pipe(
					filter(data => data.length > 0),
					first(),
				)
				.subscribe(data => {
					this.isActiveList(data);
				});
		} else {
			this.isActiveList(this.data);
		}
		if (!this.courseUI) {
			this.list = [
				...this.list.filter(({ active }) => active === true),
				...this.list.filter(({ active }) => active === false),
			];
		}
	}

	isActiveList(data) {
		if (this.courseUI) {
			this.list = [...data].map(item => {
				let dataObj = {
					...item,
					active: this.value.includes(item.id),
					cohorts: item.cohorts.map(itemCohorts => {
						return {
							...itemCohorts,
							active: this.value.includes(itemCohorts.id),
						};
					}),
				};

				dataObj['active'] =
					dataObj.cohorts.filter(cohort => !cohort.active).length === 0;

				return dataObj;
			});
		} else {
			this.list = [...data].map(item => ({
				...item,
				active: this.value.includes(item.id),
			}));
		}
	}

	close() {
		this.modalController.dismiss();
	}

	done() {
		if (this.type === 'id') {
			if (this.courseUI) {
				let arr: any = [];
				this.list.filter(({ cohorts }) => {
					cohorts
						.filter(({ active }) => active)
						.filter(({ id }) => {
							arr.push(id);
						});
				});

				console.log(arr);

				this.modalController.dismiss({
					data: arr,
				});
			} else {
				this.modalController.dismiss({
					data: this.list.filter(({ active }) => active).map(({ id }) => id),
				});
			}
		} else if (this.type === 'all') {
			this.modalController.dismiss({
				data: this.list.filter(({ active }) => active),
			});
		}
	}
	closeActiveSearch() {
		this.searchActive = !this.searchActive;
		this.search = '';
	}

	trackBy(index, item) {
		return item.id;
	}

	changeList(idxx, idx) {
		let list = JSON.parse(JSON.stringify(this.list));

		list[idxx].cohorts[idx].active = !list[idxx].cohorts[idx].active;

		if (list[idxx].cohorts.length > 0) {
			list[idxx].active =
				list[idxx].cohorts.filter(cohort => !cohort.active).length === 0;
		}

		this.list = list;
	}

	courseListAll(ev, record) {
		if (record.cohorts.length > 0) {
			if (!record.active) {
				record.cohorts = record.cohorts.map(a => ({ ...a, active: true }));
			} else {
				record.cohorts = record.cohorts.map(a => ({ ...a, active: false }));
			}
		}

		record.active = !record.active;
	}

	courseList(ev, record) {
		ev.stopPropagation();

		record.listOpen = !record.listOpen;
	}
}
