/* eslint-disable no-trailing-spaces */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable guard-for-in */
/* eslint-disable prefer-const */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import format from 'date-fns/format';
import firebase from 'firebase/compat/app';
import { concatMap, first, map, tap } from 'rxjs/operators';
import { NgrxService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { loadSidebarsSuccess } from '../../../../components/sidebar/redux/sidebar.actions';
import { BYPASS_LOG } from '../../interceptor/interceptor.service';

export enum LearningOption {
	syllabus = 'syllabus',
	syllabusPeople = 'syllabus_people',
	resources = 'resources',
	enroll = 'enroll',
	notes = 'notes',
	modules = 'modules',
	events = 'events',
	assignment = 'assignment',
	announcement = 'announcement',
	students = 'students',
	highLights = 'highLights',
	process = 'process',
}

@Injectable()
export class LearningService {
	serviceName = 'learning';
	constructor(
		private http: HttpClient,
		private ngrxService: NgrxService,
		private store: Store,
	) {}

	cloudinaryImage(image) {
		try {
			if (image.indexOf('Events/') === 0) {
				return 'https://res.cloudinary.com/terra-do/image/upload/v1/' + image;
			} else {
				return image;
			}
		} catch (e) {
			return image;
		}
	}

	// Modules functions
	loadModulesInit(cohortId: number) {
		this.ngrxService.init(this.serviceName, cohortId, LearningOption.modules);
	}

	loadModulesAPI(cohortId: number) {
		return this.ngrxService
			.loadInitAPI(this.serviceName, cohortId, LearningOption.modules)
			.pipe(
				concatMap(() =>
					this.http.get<any>(
						`${environment.url.cohort}/${cohortId}/modules/v2`,
					),
				),
				tap(data => this.loadModulesAPIMap(cohortId, data)),
			);
	}

	loadModulesAPIMap(cohortId, data) {
		let newData = data.map(week => ({
			...week,
			classes: week.classes.map(tempClass => ({
				...tempClass,
				type: 'class',
				mo_class: tempClass.modules
					.map(({ moduleId }) => 'mu_' + moduleId)
					.join(' '),
			})),
		}));

		if (newData.length > 0) {
			const newObj = [...newData].sort((anxPrevious, anxNext) => {
				const a: any = new Date(anxPrevious.startDateTime);
				const b: any = new Date(anxNext.startDateTime);
				return a - b;
			});

			const first = newObj[0];
			const last = newObj[newObj.length - 1];

			newData = [
				{
					id: 0,
					name: `${first.name.split(' ')[0]} 00`,
					description: 'default 0',
					startDateTime: first.startDateTime - 2700000000,
					endDateTime: first.startDateTime - 60000,
					classes: [],
					scheduleList: [],
				},
				...newObj,
				{
					id: last.id + 1,
					name: `${last.name.split(' ')[0]} ${Number(data.length) + 1}`,
					description: 'default ' + last.id + 1,
					classes: [],
					startDateTime: last.endDateTime,
					endDateTime: last.endDateTime + 2700000000,
					scheduleList: [],
				},
			];
		}

		this.loadModules(cohortId, newData);
	}

	loadModules(cohortId: number, data) {
		this.ngrxService.loadInitData(
			this.serviceName,
			cohortId,
			LearningOption.modules,
			data,
		);
	}

	loadModulesData(cohortId: number) {
		return this.ngrxService.loadData(
			this.serviceName,
			cohortId,
			LearningOption.modules,
		);
	}

	// Class & Events functions & announcement

	loadAnnouncementInit(cohortId: number) {
		this.ngrxService.init(
			this.serviceName,
			cohortId,
			LearningOption.announcement,
		);
	}

	loadAnnouncementData(cohortId: number) {
		return this.ngrxService.loadData(
			this.serviceName,
			cohortId,
			LearningOption.announcement,
		);
	}

	loadClassAndEventsInit(cohortId: number) {
		this.ngrxService.init(this.serviceName, cohortId, LearningOption.events);
	}

	loadClassAndEventsAPI(cohortId: number) {
		return this.ngrxService
			.loadInitAPI(this.serviceName, cohortId, LearningOption.events)
			.pipe(
				concatMap(() =>
					this.http
						.get<any>(
							`${environment.url.cohort}/${cohortId}/student/events/all`,
						)
						.pipe(
							tap(data => {
								this.loadClassAndEventsAPIMap(cohortId, data);
							}),
						),
				),
			);
	}

	loadClassAndEventsAPIMap(cohortId: number, data) {
		let eventsData: any = [];
		let officeHoursData: any = [];

		if (data) {
			eventsData =
				data?.events?.webinar?.length > 0 ? data?.events?.webinar : [];

			if (data?.events['office-hours']?.length > 0) {
				officeHoursData = data.events['office-hours'];
			}
		}

		let events =
			eventsData.length > 0
				? eventsData.map((event: any) => ({
						...event,
						name: 'Event: ' + event.summary,
						multimedia: !event.image
							? event.image
							: this.cloudinaryImage(event.image),
				  }))
				: [];

		if (officeHoursData.length > 0) {
			officeHoursData.filter((event: any) => {
				events.push({
					...event,
					name: 'Event: ' + event.summary,
					type: 'webinar',
					multimedia: !event.image
						? event.image
						: this.cloudinaryImage(event.image),
				});
				return true;
			});
		}

		this.loadEvents(cohortId, events);
		let eventsData1 = [];
		if (data) {
			eventsData1 =
				data?.events?.announcement?.length > 0
					? data?.events?.announcement
					: [];
		}

		this.loadAnnouncement(
			cohortId,
			eventsData1.map((ev: any) => ({ ...ev, name: ev.summary })),
		);
	}

	loadEvents(cohortId, events) {
		this.ngrxService.loadInitData(
			this.serviceName,
			cohortId,
			LearningOption.events,
			events,
		);
	}

	loadAnnouncement(cohortId, eventsData) {
		this.ngrxService.loadInitData(
			this.serviceName,
			cohortId,
			LearningOption.announcement,
			eventsData.filter(({ dismissed }) => !dismissed),
		);
	}
	loadClassAndEventsData(cohortId: number) {
		return this.ngrxService.loadData(
			this.serviceName,
			cohortId,
			LearningOption.events,
		);
	}

	// Assignments functions

	loadAssignmentsInit(cohortId: number) {
		this.ngrxService.init(
			this.serviceName,
			cohortId,
			LearningOption.assignment,
		);
	}

	loadAssignmentsAPI(cohortId: number) {
		return this.ngrxService
			.loadInitAPI(this.serviceName, cohortId, LearningOption.assignment)
			.pipe(concatMap(() => this.loadAssignmentsReload(cohortId)));
	}

	loadAssignmentsReload(cohortId) {
		return this.http
			.get<any>(`${environment.url.cohort}/${cohortId}/student/assignments/v3`)
			.pipe(
				map(data => ({
					assignments: data.assignments.map(event => ({
						...event,
						name: event.title,
						type: 'assignments',
					})),
				})),
				map(({ assignments }) => assignments),
			)
			.pipe(tap(data => this.loadAssignments(cohortId, data)));
	}

	loadAssignments(cohortId: number, data) {
		this.ngrxService.loadInitData(
			this.serviceName,
			cohortId,
			LearningOption.assignment,
			data,
		);
	}
	loadAssignmentsData(cohortId: number) {
		return this.ngrxService.loadData(
			this.serviceName,
			cohortId,
			LearningOption.assignment,
		);
	}

	loadAssignmentsDataReset(cohortId: number) {
		return this.ngrxService.resetState(
			this.serviceName,
			cohortId,
			LearningOption.assignment,
		);
	}

	// Resources functions
	loadResourcesInit(cohortId: number) {
		this.ngrxService.init(this.serviceName, cohortId, LearningOption.resources);
	}

	loadResourcesAPI(cohortId: number) {
		return this.ngrxService
			.loadInitAPI(this.serviceName, cohortId, LearningOption.resources)
			.pipe(
				concatMap(() =>
					this.http.get<any>(`${environment.url.cohort}/${cohortId}/materials`),
				),
				tap(data =>
					this.ngrxService.loadInitData(
						this.serviceName,
						cohortId,
						LearningOption.resources,
						data,
					),
				),
			);
	}

	loadResourcesData(cohortId: number) {
		return this.ngrxService.loadData(
			this.serviceName,
			cohortId,
			LearningOption.resources,
		);
	}

	// Notes functions
	loadNotesInit(cohortId: number) {
		this.ngrxService.init(this.serviceName, cohortId, LearningOption.notes);
	}

	loadNotesAPI(cohortId: number) {
		return this.ngrxService
			.loadInitAPI(this.serviceName, cohortId, LearningOption.notes)
			.pipe(
				concatMap(() =>
					this.http
						.get<any>(
							`${environment.url.cohort}/${cohortId}/student/notesWithModule/v2`,
						)
						.pipe(
							map(notes => {
								return notes.map(note => {
									return {
										...note,
										groupNote: [...note.modules].map(mo => {
											const uniqueValue: any = [
												...new Set(
													mo.notes.map(({ slideIndex }) => slideIndex),
												),
											].sort();

											let newNotes: any = [];

											for (const no in uniqueValue) {
												const firstRight = mo.notes
													?.filter(
														({ slideIndex }) =>
															slideIndex === Number(uniqueValue[no]),
													)
													.map(mapNote => ({
														...mapNote,
														formatDate: format(
															new Date(mapNote.createdAt),
															`dd MMM yyyy 'at' hh:mm a`,
														),
													}));

												let multimedia = '';
												const exMultimedia = firstRight
													.filter(res => !!res.multimedia)
													.filter(res => res.multimedia !== ' ')
													.map(res => res.multimedia)[0];

												if (!!exMultimedia) {
													multimedia = exMultimedia;
												}

												if (
													!!multimedia &&
													multimedia.indexOf('youtube.com') > -1
												) {
													try {
														const match = multimedia
															.split('?')[1]
															.split('&')
															.filter(str => str.indexOf('v=') > -1)[0]
															.split('=')[1];
														multimedia = `https://img.youtube.com/vi/${match}/3.jpg`;
													} catch (e) {}
												}

												newNotes.push({
													...firstRight[0],
													href: `${environment.url.textbook}${
														mo.moduleId
													}?slideNumber=${uniqueValue[no] + 1}`,
													slides: firstRight,
													multimedia,
												});
											}

											return {
												...mo,
												notes: newNotes,
											};
										}),
									};
								});
							}),
						),
				),
				tap(data =>
					this.ngrxService.loadInitData(
						this.serviceName,
						cohortId,
						LearningOption.notes,
						data,
					),
				),
			);
	}

	loadNotesData(cohortId: number) {
		return this.ngrxService.loadData(
			this.serviceName,
			cohortId,
			LearningOption.notes,
		);
	}

	// Syllabus functions
	loadSyllabusInit(cohortId: number) {
		this.ngrxService.init(this.serviceName, cohortId, LearningOption.syllabus);
	}

	loadSyllabusAPI(cohortId: number) {
		return this.ngrxService
			.loadInitAPI(this.serviceName, cohortId, LearningOption.syllabus)
			.pipe(
				concatMap(() =>
					this.http
						.get<any>(`${environment.url.cohort}/${cohortId}/syllabus`)
						.pipe(
							map(data => ({
								data:
									data.longDescription.length > 0
										? [{ longDescription: data.longDescription }]
										: [],
							})),
							map(({ data }) => data),
						),
				),
				tap(data =>
					this.ngrxService.loadInitData(
						this.serviceName,
						cohortId,
						LearningOption.syllabus,
						data,
					),
				),
			);
	}

	loadSyllabusData(cohortId: number) {
		return this.ngrxService.loadData(
			this.serviceName,
			cohortId,
			LearningOption.syllabus,
		);
	}

	// Syllabus Instructor functions
	loadSyllabusInstructorInit(cohortId: number) {
		this.ngrxService.init(
			this.serviceName,
			cohortId,
			LearningOption.syllabusPeople,
		);
	}

	loadSyllabusInstructorAPI(cohortId: number) {
		return this.ngrxService
			.loadInitAPI(this.serviceName, cohortId, LearningOption.syllabusPeople)
			.pipe(
				concatMap(() =>
					this.http.get<any>(`${environment.url.cohort}/${cohortId}/people`),
				),
				map(data => {
					let newObjArray = [];

					data.filter(record => {
						let newObj = {};
						Object.keys(record).filter(keys => {
							newObj[keys] = record[keys].map(people => {
								try {
									if (people.multimedia) {
										return {
											...people,
											multimedia: this.cloudinaryImage(people.multimedia),
										};
									} else {
										return people;
									}
								} catch (e) {
									return people;
								}
							});
						});

						newObjArray.push(newObj);
					});

					return newObjArray;
				}),
				tap(data =>
					this.ngrxService.loadInitData(
						this.serviceName,
						cohortId,
						LearningOption.syllabusPeople,
						data,
					),
				),
			);
	}

	loadSyllabusInstructorData(cohortId: number) {
		return this.ngrxService.loadData(
			this.serviceName,
			cohortId,
			LearningOption.syllabusPeople,
		);
	}

	// Students functions
	loadStudentsInit(cohortId: number) {
		this.ngrxService.init(this.serviceName, cohortId, LearningOption.students);
	}

	loadStudentsAPI(cohortId: number) {
		return this.ngrxService
			.loadInitAPI(this.serviceName, cohortId, LearningOption.students)
			.pipe(
				concatMap(() =>
					this.http.get<any>(
						`${environment.url.cohort}/v3/${cohortId}/students`,
					),
				),
				tap(data =>
					this.ngrxService.loadInitData(
						this.serviceName,
						cohortId,
						LearningOption.students,
						data,
					),
				),
			);
	}

	loadStudentsData(cohortId: number): any {
		return this.ngrxService.loadData(
			this.serviceName,
			cohortId,
			LearningOption.students,
		);
	}

	deleteAnnouncementApi(id) {
		return this.http.post(`${environment.url.cohort}/dismiss/event/${id}`, {});
	}

	markRecordingViewed(eventId, type) {
		return this.http.get(`${environment.url.cohort}/join/${type}/${eventId}`);
	}

	editState(stateKey, cohortId, eventId, dataId, key, value) {
		this.ngrxService.editInState(
			this.serviceName,
			cohortId,
			stateKey,
			dataId,
			eventId,
			value,
			key,
		);
	}

	loadSideMenuData() {
		this.ngrxService.loadData('side');
	}

	rateCard(cohortId, eventId, eventType, rates, eventFeedback) {
		return this.http.post(`${environment.url.cohort}/events/feedback/`, {
			cohortId,
			eventId,
			eventType,
			rates,
			eventFeedback,
		});
	}

	loadCohortsInit() {
		this.ngrxService.init('apply', 'cohort');
	}
	loadCohortsApi() {
		return this.ngrxService.loadInitAPI('apply', 'cohort').pipe(
			concatMap(() =>
				this.http.get(environment.url.catalog + '/course/list/applyCohort'),
			),
			tap(data => this.ngrxService.loadInitData('apply', 'cohort', data)),
		);
	}
	loadCohortsData() {
		return this.ngrxService.loadData('apply', 'cohort');
	}

	takeFreeClass(courseId, mailType = 'free_class') {
		return this.http.post(
			environment.url.catalog + `/course/${courseId}/type/${mailType}`,
			{},
		);
	}

	// Cohort process
	loadCohortProcessInit(cohortId: number) {
		this.ngrxService.init(this.serviceName, cohortId, LearningOption.process);
	}

	loadCohortProcessAPI(cohortId: number) {
		return this.ngrxService
			.loadInitAPI(this.serviceName, cohortId, LearningOption.process)
			.pipe(
				concatMap(() =>
					this.http.get<any>(
						`${environment.url.cohort}/${cohortId}/cohort/process`,
					),
				),
				tap(data =>
					this.ngrxService.loadInitData(
						this.serviceName,
						cohortId,
						LearningOption.process,
						data,
					),
				),
			);
	}

	loadCohortProcessData(cohortId: number) {
		return this.ngrxService.loadData(
			this.serviceName,
			cohortId,
			LearningOption.process,
		);
	}

	// Course Completion

	loadCourseCompletionInit(cohortId) {
		this.ngrxService.init(this.serviceName, cohortId, 'course_completion');
	}

	loadCourseCompletionApi(cohortId, data) {
		this.ngrxService.loadInitData(
			this.serviceName,
			cohortId,
			'course_completion',
			data,
		);
	}

	loadCourseCompletionData(cohortId) {
		return this.ngrxService.loadData(
			this.serviceName,
			cohortId,
			'course_completion',
		);
	}

	// Enroll functions
	loadEnrollInit(cohortId: number) {
		this.ngrxService.init(this.serviceName, cohortId, LearningOption.enroll);
	}

	loadEnrollReset(cohortId: number) {
		this.ngrxService.resetState(
			this.serviceName,
			cohortId,
			LearningOption.enroll,
		);
	}

	loadEnrollAPI(cohortId: number) {
		return this.ngrxService
			.loadInitAPI(this.serviceName, cohortId, LearningOption.enroll)
			.pipe(
				concatMap(() => {
					return this.http.get<any>(
						`${environment.url.cohort}/${cohortId}/enroll`,
					);
				}),
				tap((data: any) => {
					const record = data.data;

					this.store.dispatch(
						loadSidebarsSuccess({
							data: [
								{
									title: record.course.name,
									description: record.course.description,
									cohortId: record.cohortId,
									courseId: record.courseId,
									properties: record.properties,
									isMySchedule: true,
									isAssignments: true,
									isCourseInfo: true,
									isResources: true,
									isMyNotes: true,
									isHighLights: true,
									startTime: record.startTime,
									studentId: record.studentId,
									isEnroll: record.isEnroll,
								},
							],
						}),
					);

					this.ngrxService.loadInitData(
						this.serviceName,
						cohortId,
						LearningOption.enroll,
						{
							...record,
							description: record.course.description,
						},
					);
					this.loadModulesAPIMap(cohortId, data.data.modulesData);

					this.loadClassAndEventsAPIMap(cohortId, data.data);

					this.loadAssignments(
						cohortId,
						data.data.assignments.map(event => ({
							...event,
							name: event.title,
							type: 'assignments',
						})),
					);
				}),
			);
	}

	loadEnrollData(cohortId: number) {
		return this.ngrxService.loadData(
			this.serviceName,
			cohortId,
			LearningOption.enroll,
		);
	}

	addEnrollWaitList(cohortId) {
		this.http
			.post<any>(
				`${environment.url.cohort}/${cohortId}/enroll/waitlist`,
				{
					cohortId,
				},
				{ context: new HttpContext().set(BYPASS_LOG, true) },
			)
			.subscribe(data => {});
	}

	addEnroll(cohortId, companyId, modalController) {
		this.http
			.post<any>(`${environment.url.cohort}/${cohortId}/enroll`, { cohortId })
			.subscribe(data => {
				this.ngrxService.resetState(
					this.serviceName,
					cohortId,
					LearningOption.enroll,
				);

				this.ngrxService
					.loadData('dashboard', companyId, 'sprints')
					.pipe(first())
					.subscribe(data => {
						if (data && data.data && data.data.length > 0) {
							const newData = data.data.map(db => {
								if (db.cohortId === Number(cohortId)) {
									return {
										...db,
										appliedStatus: 7,
									};
								} else {
									return db;
								}
							});

							this.ngrxService.loadInitData(
								'dashboard',
								companyId,
								'sprints',
								newData,
							);
						}
					});

				this.loadEnrollAPI(cohortId).subscribe(() => {
					modalController.dismiss();
				});
			});
	}
}
