import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AppInstallerMobileComponent } from './app-installer-mobile.component';



@NgModule({
  declarations: [AppInstallerMobileComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports:[AppInstallerMobileComponent]
})
export class AppInstallerMobileModule { }
