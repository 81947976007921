import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ImagePreloadDirectiveModule } from 'src/app/directives/index';

import { SidebarEffects } from './redux/sidebar.effects';
import * as fromSidebar from './redux/sidebar.reducer';
import { SidebarService } from './service/sidebar.service';
import { SidebarComponent } from './sidebar.component';

@NgModule({
	declarations: [SidebarComponent],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromSidebar.sidebarFeatureKey, fromSidebar.reducer),
		EffectsModule.forFeature([SidebarEffects]),
		ImagePreloadDirectiveModule,
		IonicModule,
	],
	exports: [SidebarComponent],
	providers: [SidebarService],
})
export class SidebarModule {}
